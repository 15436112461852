import axios from 'axios';

export class CouloirService {
    
    getAll() {
        
        return axios.get('http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/couloir')
                    .then(res => res.data);
    }
    create(newCouloir) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/couloir'
        return axios.post(url,newCouloir)
                    .then(res => res.data);
    }
    update(idCouloir ,newCouloir) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/couloir/'
        return axios.put(url+idCouloir, newCouloir)
                    .then(res => res.data);
    }
    delete(idCouloir) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/couloir/'
        return axios.patch(url+idCouloir)
                    .then(res => res.data)
    }
    get(idNiveau) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/couloir/?idNiveau='+idNiveau
        return axios.get(url)
                    .then(res => res.data)
    }

}