import axios from 'axios';
import { AdresseIP } from './AdresseIp';

export class InscriptionService {

    constructor(){
        this.adresse = new AdresseIP()
    }
    
    sendIne(ine) {
        const url = this.adresse.getAdresseIP() +"/service/api/hebergement-cenou/campus-auth/hebergement-etudiant/"
        return axios.get(url+ine)
                    .then(res => res.data);
    }
    create(newInscription) {
        const url = this.adresse.getAdresseIP() +"/service/api/hebergement-cenou/campus-auth/hebergement-etudiant/"
        return axios.post(url,newInscription)
                    .then(res => res.data);
    }

}