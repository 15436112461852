import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import Axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';

export class ExportDeliberation extends Component {
    
    constructor() {
        super()
        this.state = {
            listeAdmis : [],
            submited : false,
            loading : false
        }
        this.exportCSV = this.exportCSV.bind(this);
    }
    componentDidMount = () => {
        
        this.setState({loading : true})
            const url = 'http://192.168.43.14:8083/service/api/hebergement-cenou/campus-faso/export-deliberation'
            Axios.get(url).then(res => { 
                this.notify();
                this.setState({listeAdmis : res.data, loading : false})
                
            })
            .catch(error => {
                console.log(error);
                this.notify2()
                this.setState({ loading : false})
            })
        
    }
    exportCSV() {
        this.dt.exportCSV();
    }
    loadingSpinner = ( type, color) => (
        <ReactLoading type={type} color={color} height={200} width={50} />
    )
    notify = () => toast.success(' !', {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    notify2 = () => toast.error('Echec de chargement !', {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    
    render() {
        this.state.listeAdmis.map(admis => {
            admis.moyenne = Math.round(admis.moyenne * 100) / 100 
        })
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        return (
            <div>
                <ToastContainer
                            position="top-right"
                            autoClose={10000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                    <ToastContainer />
                    <div className="p-grid">
                        <div className="p-col-2">
                            <Button label="Exporter" icon="pi pi-external-link" className="p-button-success p-mr-2" onClick={this.exportCSV} />
                        </div>
                    </div>
                <div className="admis" style={{marginTop : 10}}>
                    <DataTable  ref={(el) => this.dt = el} value={this.state.listeAdmis} selectionMode="single" header={header} paginator={true} rows={10}
                                globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                        <Column field="ine" header="INE" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher ine"/>
                        <Column field="nomEtudiant" header="Nom" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher nom"/>
                        <Column field="prenomEtudiant" header="Prénom" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher prénom"/>
                        <Column field="telEtudiant" header="Téléphone" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher telephone"/>
                        <Column field="villeOrientation" header="Ville" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher ville"/>
                        <Column field="moyenne" header="Moyenne" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher Moyenne"/>
                    </DataTable>
                </div>
            </div>
        )
    }
}

export default ExportDeliberation
