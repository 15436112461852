import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { BatimentService } from '../../service/BatimentService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import { CiteService } from '../../service/CiteService';
import { NiveauService } from '../../service/NiveauService';
import { CouloirService } from '../../service/CouloirService';
import { CategorieChambreService } from '../../service/CategorieChambreService';
import { ChambreService } from '../../service/ChambreService';


export class Chambre extends Component {

    emptyChambre = {
        idChambre : 0,
        idCategorieChambre : 0,
        idCouloir : 0,
        idNiveau : 0,
        idBatiment  : 0,
        idCite : 0,
        codeChambre : "",
        libelleChambre : ""
    };
    constructor() {
        super();
        this.state = {
            listeCite : [],
            listeBatiment : [],
            listeNiveau : [],
            listeCouloir : [],
            listeCategorieChambre : [],
            listeChambre : [],
            chambreDialog: false,
            deleteChambreDialog: false,
            chambre: this.emptyChambre,
            submitted: false,
            redirect : false,
            loading : false,
            globalFilter: null
        }

        this.batimentService = new BatimentService();
        this.citeService = new CiteService();
        this.niveauService = new NiveauService();
        this.couloirService = new CouloirService();
        this.categorieChambreService = new CategorieChambreService();
        this.chambreService = new ChambreService();

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveChambre = this.saveChambre.bind(this);
        this.editChambre = this.editChambre.bind(this);
        this.confirmDeleteChambre = this.confirmDeleteChambre.bind(this);
        this.deleteChambre = this.deleteChambre.bind(this);
        this.onLibelleChambreChange = this.onLibelleChambreChange.bind(this);
        this.onCodeChambreChange = this.onCodeChambreChange.bind(this);
        this.onIdCiteChange = this.onIdCiteChange.bind(this);
        this.onIdBatimentChange = this.onIdBatimentChange.bind(this);
        this.onIdNiveauChange = this.onIdNiveauChange.bind(this);
        this.onIdCouloirChange = this.onIdCouloirChange.bind(this);
        this.onIdCategorieChambreChange = this.onIdCategorieChambreChange.bind(this);
        this.hideDeleteChambreDialog = this.hideDeleteChambreDialog.bind(this);
    }
    componentDidMount() {
        this.setState({loading : true})
        this.chambreService.getAll().then(data => 
            this.setState({listeChambre: data, loading : false})
            )
            .catch(error => {
                this.notify2()  
                console.log(error)
                this.setState({loading : false})
            })

        this.citeService.getAll().then(data => {
            //console.log(data)
            this.setState({listeCite: data})
        })
            .catch(error => {this.notify2() ; console.log(error)})
        
            /*this.categorieChambreService.getAll().then(data => {
                this.setState({listeCategorieChambre: data})
            })
                .catch(error => {this.notify2() ; console.log(error)})*/
        
    }
    
    openNew() {
        this.setState({
            chambre: this.emptyChambre,
            submitted: false,
            chambreDialog: true
        });
    }
    hideDialog() {
        this.setState({
            submitted: false,
            chambreDialog: false
        });
    }
    hideDeleteChambreDialog() {
        this.setState({ deleteChambreDialog: false });
    }
    saveChambre() {
        let state = { submitted: true };

        if (this.state.chambre.idCouloir && this.state.chambre.idCategorieChambre &&  this.state.chambre.libelleChambre.trim()) {
            //alert("yo !")
            const newChambre = {
                idChambre : parseInt(this.state.chambre.idChambre) ,
                idCouloir : parseInt(this.state.chambre.idCouloir) ,
                idCategorieChambre : parseInt(this.state.chambre.idCategorieChambre) ,
                libelleChambre : this.state.chambre.libelleChambre,
                codeChambre : this.state.chambre.codeChambre
            }
            console.log(newChambre)
            if (this.state.chambre.idChambre) {
                this.chambreService.update(parseInt(this.state.chambre.idChambre
                    ), newChambre)
                                .then(data => {
                                    this.notify();
                                    //console.log(data)
                                    this.setState({redirect : true})
                                })
                                .catch(error => {
                                    this.notify2()
                                    console.log(error);
                                })
            }
            else {
                this.chambreService.create(newChambre).then(data => {
                    this.notify();
                    this.setState({redirect : true})
                })
                .catch(error => {
                    this.notify2()
                    console.log(error);
                })
                
            }

            state = {
                ...state,
                chambreDialog: false,
                chambre: this.emptyChambre
            };
        }

        this.setState(state);
    }
    editChambre(chambre) {
        this.loadBatiment(chambre.idCite)
        this.loadNiveau(chambre.idBatiment)
        this.loadCouloir(chambre.idNiveau)
        this.loadCategorieChambre(chambre.idCite)
        this.setState({
            chambre,
            chambreDialog: true
        });
    }
    confirmDeleteChambre(chambre) {
        this.setState({
            chambre,
            deleteChambreDialog: true
        });
    }
    deleteChambre(idChambre) {
        alert(idChambre)
        this.chambreService.delete(parseInt(idChambre)).then(data => {
            this.notify();
            this.setState({
                deleteChambreDialog: false,
                chambre: this.emptyChambre,
                redirect : true
            });
        })
    }
    
    onLibelleChambreChange(e, libelleChambre) {
        const val = (e.target && e.target.value) || '';
        let chambre = this.state.chambre;
        chambre[`${libelleChambre}`] = val;

        this.setState({chambre });
    }
    onIdCiteChange(e, idCite) {
        const val = (e.target && e.target.value) || '';
        let chambre = this.state.chambre;
        chambre[`${idCite}`] = val;

        this.setState({ chambre });
    }
    onIdBatimentChange(e, idBatiment) {
        const val = (e.target && e.target.value) || '';
        let chambre = this.state.chambre;
        chambre[`${idBatiment}`] = val;

        this.setState({ chambre });
    }
    onIdNiveauChange(e, idNiveau) {
        const val = (e.target && e.target.value) || '';
        let chambre = this.state.chambre;
        chambre[`${idNiveau}`] = val;
        this.setState({ chambre });
    }
    onIdCouloirChange(e, idCouloir) {
        const val = (e.target && e.target.value) || '';
        let chambre = this.state.chambre;
        chambre[`${idCouloir}`] = val;
        this.setState({ chambre });
    }
    onIdCategorieChambreChange(e, idCategorieChambre) {
        const val = (e.target && e.target.value) || '';
        let chambre = this.state.chambre;
        chambre[`${idCategorieChambre}`] = val;
        this.setState({ chambre });
    }
    onCodeChambreChange(e, codeChambre) {
        const val = (e.target && e.target.value) || '';
        let chambre = this.state.chambre;
        chambre[`${codeChambre}`] = val;

        this.setState({ chambre });
    }
    loadBatiment = (idCite) => {
        this.batimentService.get(idCite).then(data => {
            this.setState({listeBatiment : data})
        })
    }
    loadNiveau = (idBatiment) => {
        this.niveauService.get(idBatiment).then(data => {
            //console.log(data)
            this.setState({listeNiveau : data})
        })
    }
    loadCouloir = (idNiveau) => {
        this.couloirService.get(idNiveau).then(data => {
            //console.log(data)
            this.setState({listeCouloir : data})
        })
    }
    loadCategorieChambre = (idCite) => {
        //console.log(idCite)
        this.citeService.getCategorieChambre(idCite).then(data => {
            console.log(data)
            this.setState({listeCategorieChambre : data.listeCategorieChambre})
        })
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editChambre(rowData)} style={{marginRight: '.5em'}}/>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteChambre(rowData)} />
            </React.Fragment>
        );
    }
    notify = () => toast.success('Opération réuçit!', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    notify2 = () => toast.error("Echec de l'opération !", {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
    loadingSpinner = ( type, color) => (
        <ReactLoading type={type} color={color} height={200} width={50} />
    )
    render() {

        if(this.state.redirect) {
            //window.location.reload(false);
        }
        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        const chambreDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveChambre} />
            </React.Fragment>
        );
        const deleteChambreDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteChambreDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteChambre(this.state.chambre.idChambre) } />
            </React.Fragment>
        );
        
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-2">
                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                        </div>
                        <div className="p-col-2">
                            <Button label="Rafraichir" icon="pi pi-refresh" className="" onClick={()=>window.location.reload(false)} />
                        </div>
                    </div>
    
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.loadingSpinner("spin","black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeChambre} selectionMode="single" header="Liste des chambres" paginator={true} rows={5}
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="libelleChambre" header="Chambre" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher chambre"/>
                            <Column field="libelleCategorieChambre" header="Catégorie" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher catégorie"/>
                            <Column field="libelleCouloir" header="Couloir" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher couloir"/>       
                            <Column field="libelleNiveau" header="Niveau" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher niveau"/>
                            <Column field="libelleBatiment" header="Bâtiment" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher bâtiment"/>
                            <Column field="nomCite" header="Cité" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher cité"/>
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        }
                    </div>
                </div>

                <Dialog visible={this.state.chambreDialog} style={{ width: '450px' }} header="Informations couloir" modal className="p-fluid" footer={chambreDialogFooter} onHide={this.hideDialog}>
                    <div className="p-field">
                        <label htmlFor="nomCite">Cité</label>
                        <Dropdown optionLabel="nomCite" optionValue="idCite" value={this.state.chambre.idCite} options={this.state.listeCite} onChange={(e) => { this.loadCategorieChambre(e.value); this.loadBatiment(e.value); this.onIdCiteChange(e, 'idCite') }}  placeholder="Selectionner une cité"/>
                    </div>
                    <div className="p-field">
                        <label htmlFor="libelleBatiment">Nom du Bâtiment</label>
                        <Dropdown optionLabel="libelleBatiment" optionValue="idBatiment" value={this.state.chambre.idBatiment} options={this.state.listeBatiment} onChange={(e) => {this.loadNiveau(e.value); this.onIdBatimentChange(e, 'idBatiment')}} autoFocus  placeholder="Selectionner un bâtiment"/>
                    </div>
                    <div className="p-field">
                        <label htmlFor="libelleNiveau">Niveau</label>
                        <Dropdown optionLabel="libelleNiveau" optionValue="idNiveau" value={this.state.chambre.idNiveau} options={this.state.listeNiveau} onChange={(e) => { this.loadCouloir(e.value); this.onIdNiveauChange(e, 'idNiveau')}} autoFocus placeholder="Selectionner un niveau"/>
                    </div>
                    <div className="p-field">
                        <label htmlFor="libelleCouloir">Couloir</label>
                        <Dropdown optionLabel="libelleCouloir" optionValue="idCouloir" value={this.state.chambre.idCouloir} options={this.state.listeCouloir} onChange={(e) => { this.onIdCouloirChange(e, 'idCouloir')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })}  placeholder="Selectionner un couloir"/>
                        {this.state.submitted && !this.state.chambre.idCouloir && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="libelleCouloir">Catégorie chambre</label>
                        <Dropdown optionLabel="libelleCategorieChambre" optionValue="idCategorieChambre" value={this.state.chambre.idCategorieChambre} options={this.state.listeCategorieChambre} onChange={(e) => { this.onIdCategorieChambreChange(e, 'idCategorieChambre')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })}  placeholder="Selectionner une catégorie chambre"/>
                        {this.state.submitted && !this.state.chambre.idCategorieChambre && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="libelleChambre">Chambre</label>
                        <InputText id="libelleChambre" value={this.state.chambre.libelleChambre}  onChange={(e) => this.onLibelleChambreChange(e, 'libelleChambre')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })}   />
                        {this.state.submitted && !this.state.chambre.libelleChambre && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="codeChambre">Code chambre</label>
                        <InputText id="codeChambre" value={this.state.chambre.codeChambre}  onChange={(e) => this.onCodeChambreChange(e, 'codeChambre')}   />
                    </div>
                </Dialog>
                <Dialog visible={this.state.deleteChambreDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteChambreDialogFooter} onHide={this.hideDeleteChambreDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.chambre && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
            </div> 
        )
    }
}

export default Chambre
