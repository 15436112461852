import React, { Component } from 'react'
import { InputText } from 'primereact/inputtext'
import {Button} from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { DossierService } from '../service/DossierService';



export class Depot extends Component {
    
        state = {
        idDossier : null,
        statutDossier : null,
        typeDossier : null,
        idEtudiant : null,
        idSessionCenou : null,
        recipisseDossier : null,
        idTypeCritere : null,
        idCritere : null,
        nomFichier : null,
        libelleTypeCritere : null,
        libelleCritere : null,
        listeDossierCritere : new Array(),
        listeDossierCritereEtudiant : [],
        redirect : false,
        verife : false,
        loading : false,
        file : null,
        obligatoireTypeCritere : false,
        obligatoireCritere : false,
        dataEtudiant : {},
        dossierEtudiant : [],
        verifeExtensions : [],
        verifeExtension : false,
        verifeTailles : [],
        verifeTaille : false
        }

        constructor(props){

            super(props);
            
            this.dossierService = new DossierService();
        }
    
    
    componentDidMount = () => {
        
        const data = localStorage.getItem('user')
        const user = JSON.parse(data)
        
        this.dossierService.getListeDossierCritere(user.username)
        .then(res => { 
            let idTypeCritere = null, choixCritere = false, choixFichier = false, listeCritere = [], justificatif
            
            res.data.listeDossierCritere.map((dossierCritere, i) => {
                idTypeCritere = dossierCritere.idTypeCritere
                choixCritere = dossierCritere.obligatoire
                justificatif = dossierCritere.justificatif
                listeCritere = dossierCritere.listeCritere
                
                this.state.listeDossierCritere.push({idTypeCritere : idTypeCritere, listeCritere : listeCritere,idCritere : null, choixCritere : choixCritere, choixFichier : choixFichier, justificatif : justificatif, obligatoireTypeCritere : 0, obligatoireCritere : 0, nomFichier : null, fichier : null})
            })
            this.setState({
                dataEtudiant : res.data,
                idSessionCenou : res.data.idSessionCenou,
                listeDossierCritereEtudiant : res.data.listeDossierCritere,
                verife : true
            })
            //console.log(res.data.idSessionCenou)
            if(res.data.idSessionCenou === null) this.notify5()
        })
        .catch(error => {
            this.notify4()
            console.log(error);
            
        })
        
    }
    
    onFileChange = (event, i) => { 
        let file = event.target.files[0]
        
        
        if(file){
            let extension = file.name.split('.').pop();
            let taille = file.size
            if(extension === "pdf"){

                if(taille <= 3000000 ) {
            
                    this.setState({nomFichier : file.name})
                    this.state.listeDossierCritere[i].nomFichier = file.name
                    const reader = new FileReader()
                    reader.onload = readerEvt => {
                        let binaryString = readerEvt.target.result
                        let fichier = btoa(binaryString)
                        this.setState({file : fichier})
                        this.state.listeDossierCritere[i].fichier = fichier
                    }
                    
                    reader.readAsBinaryString(file)
                    this.state.verifeExtensions[i] = false
                    this.state.verifeTailles[i] = false
                }
                else {
                    this.state.verifeTailles[i] = true
                }
            }
            else {
                this.state.verifeExtensions[i] = true
            }
             
        }
        
    }

    /*onCritereChoix = (dossierCritere, i) => {
        dossierCritere.listeCritere.map(critere => {
            if(critere.obligatoire){
                this.state.listeDossierCritere[i].obligatoireCritere = 1
            }
            else this.state.listeDossierCritere[i].obligatoireCritere = 2
        } )

    }*/
     
    DepotDossier = () => { 
    
        let verifeCritere = false, verifeFichier = false, verifeExt = false, verifeTaille = false
       
        this.state.listeDossierCritere.map((dossierCritere, i) => {
            if(this.state.verifeExtensions[i]) verifeExt = true
            if(this.state.verifeTailles[i]) verifeTaille = true
            if(dossierCritere.choixCritere && dossierCritere.choixFichier){
                if(!dossierCritere.idCritere) verifeCritere = true;
                if(!dossierCritere.fichier) verifeFichier = true;
            }
            else if(dossierCritere.choixCritere && !dossierCritere.choixFichier) {
                if(!dossierCritere.idCritere) verifeCritere = true;
            }
            else if(!dossierCritere.choixCritere && dossierCritere.choixFichier) {
                if(dossierCritere.idCritere) {
                    if(!dossierCritere.fichier) verifeFichier = true;
                }
            }
        }
    )
    //console.log(verifeExt)
    //console.log(verifeCritere)
    //console.log(verifeFichier)
     
        if(verifeCritere){
            this.notify2();
        }
        else if(verifeFichier){
            this.notify8();
        }
        else if(verifeExt){
            this.notify6();
        }
        else if(verifeTaille){
            this.notify7();
        }

        else {
        const newDepot = {
            idDossier : this.state.idDossier,
            statutDossier : this.state.statutDossier,
            idSessionCenou : this.state.idSessionCenou,
            typeDossier : this.state.typeDossier,
            listeDossierCritere : this.state.listeDossierCritere,
            idEtudiant : this.state.dataEtudiant.idEtudiant,
            ine : this.state.dataEtudiant.ine, 
            nomEtudiant : this.state.dataEtudiant.nomEtudiant,
            prenomEtudiant : this.state.dataEtudiant.prenomEtudiant,
            
            
       }
       
       console.log(newDepot)
       this.setState({loading : true})
        this.dossierService.depot(newDepot)
        .then(res => { 
            this.setState({redirect : true, loading : false})
            this.notify();
        })
        .catch(error => {
            console.log(error);
            this.notify3()
            this.setState({ loading : false})
        })
        }
      
    }

      loadingSpinner = ( type, color) => (
        <ReactLoading type={type} color={color} height={200} width={50} />
    )
      notify = () => toast.success('Dépôt de dossier effectué !', {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    notify2 = () => toast.error('Renseigner les champs obligatoire !', {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    notify3 = () => toast.error("Vous disposez dejà d'un dossier !", {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    notify4 = () => toast.error("Vous n’êtes pas autorisé à faire un dépôt car votre ville d'orientation ne dispose pas de cités universitaire pour le moment !", {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    notify5 = () => toast.error("La session de dépôt est fermée !", {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    notify6 = () => toast.error("Veuillez renseigner que des fichier pdf !", {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    notify7 = () => toast.error("Veuillez renseigner des fichiers avec une taille inférieur ou egal à 3Mo(3000Ko) !", {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    notify8 = () => toast.error("Veuillez Joindre des fichiers aux critères justifiable !", {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    
    render() {
        
        //console.log(this.state.listeDossierCritere)
        //console.log(this.state.dataEtudiant)
        //console.log(this.state.dataEtudiant.listeDossierCritere)
        //console.log(this.state.listeDossierCritere[0])
        /*this.state.listeDossierCritereEtudiant.map(dossierCritere => {
            this.state.listeDossierCritere.push({idCritere : null, nomFichier : null, fichier : null})
        })*/
        
        
        return (
            <div className="">
                {this.state.dataEtudiant.idSessionCenou ?
                
                    this.state.loading ? 
                        this.loadingSpinner("spin","black")
                        :
                        <>
                         <div className="p-grid">
                            <div className="p-lg-12 contenair " id="slideshows" style={{textAlign : ""}}>
                                <div id="slider" style={{backgroundColor : "#d1ecf1", padding : 5}}>
                                    <h4 style={{textAlign : "center"}}>Dépôt de dossier pour la cité universitaire</h4>
                                    <p style={{fontWeight : "bold", fontStyle : "italic", fontSize : 16, color : "red", textAlign : "center", marginTop : 10}}>
                                    Vous aurez plus de chance en apportant les justificatifs des critères que vous aurez choisi ! <br/>
                                    certains critères sont forcement justifiables en joignant des fichiers.<br/>
                                    NB : Veuillez renseigner uniquement que des fichiers PDF, la taille de chaque fichier ne doit pas depasser 3Mo, <br/> les noms des fichiers ne doivent pas contenir des caractères accentués
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="p-lg-12 p-grid">
                                {this.state.redirect ?
                                    <Link style={{marginLeft :"20px"}} to="/tableau-de-bord">Consulter votre tableau de bord</Link>
                                        :
                                        ''
                                }
                        </div>
                        <ToastContainer
                            position="top-right"
                            autoClose={10000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                    <ToastContainer />
                        
                            {this.state.verife ?
                            <>
                            <form className="p-fluid">
                                <div className=" card " >
                                    <div className="p-grid">
                                        <div className="p-lg-1"> 
                                            <label>INE</label>
                                            <InputText value={this.state.dataEtudiant.ine || ""} disabled type="text" />
                                        </div>
                                        <div className="p-lg-2" style={{marginTop : -3.5}}> 
                                            <label>Nom</label>
                                            <InputText value={this.state.dataEtudiant.nomEtudiant || "" }  disabled  type="text" />
                                        </div>
                                        <div className="p-lg-2" style={{marginTop : -3.5}} > 
                                            <label>Prénom</label>
                                            <InputText value={this.state.dataEtudiant.prenomEtudiant || ""}  disabled  type="text" />
                                        </div>
                                        <div className="p-lg-1"> 
                                            <label>Sexe</label>
                                            <InputText value={this.state.dataEtudiant.sexeEtudiant || ""}  disabled  type="text" />
                                        </div>
                                        <div className="p-lg-1"> 
                                            <label>Date de naiss.</label>
                                            <InputText value={this.state.dataEtudiant.dateNaissance || ""}  disabled  type="text" />
                                        </div>
                                        <div className="p-lg-2" style={{marginTop : -3.5}}> 
                                            <label>Lieu de naiss.</label>
                                            <InputText value={this.state.dataEtudiant.lieuNaissance || ""}  disabled  type="text" />
                                        </div>
                                
                                        <div className="p-lg-1"> 
                                            <label>Type pays</label>
                                            <InputText value={this.state.dataEtudiant.typePays || ""}  disabled  type="text" />
                                        </div>
                                        <div className="p-lg-1"> 
                                            <label>Serie BAC</label>
                                            <InputText value={this.state.dataEtudiant.serieBac || ""}  disabled  type="text" />
                                        </div>
                                        <div className="p-lg-1"> 
                                            <label>Moyenne BAC</label>
                                            <InputText value={this.state.dataEtudiant.moyenneBac}  disabled  type="text" />
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <form className="p-fluid">
                                <div className=" card " >
                                    { this.state.listeDossierCritereEtudiant.map((dossierCritere,i) => 
                                        
                                        
                                        <div key={i} className="p-grid">
                                            <div className="p-lg-4"></div>
                                            <div className="p-lg-2"> 
                                                <label>
                                                {
                                                    dossierCritere.obligatoire
                                                    ?
                                                    <span>{dossierCritere.libelleTypeCritere} <span style={{color : "red"}}>*</span> </span>
                                                    :

                                                    dossierCritere.libelleTypeCritere
                                                    
                                                }
                                            </label>

                                            <Dropdown optionLabel="libelleCritere" optionValue="idCritere" value={this.state.listeDossierCritere[i].idCritere}  options={dossierCritere.listeCritere} 
                                                onChange={(e)=>{
                                                    this.setState({idCritere :  e.value})
                                                    this.state.listeDossierCritere[i].idCritere  = parseInt(e.value)
                                                    this.state.listeDossierCritere[i].listeCritere.map(critere => { 
                                                        if(critere.idCritere === parseInt(e.value)){
                                                            //console.log(critere.obligatoire)
                                                            //console.log(critere.libelleCritere)
                                                            this.state.listeDossierCritere[i].choixFichier = critere.obligatoire
                                                        }
                                                     } )
                                                }} 
                                            />
                                        </div>
                                        {dossierCritere.justificatif ?
                                        <div className="p-lg-2"> 
                                            
                                                <label>Justificatif {dossierCritere.libelleTypeCritere}</label>
                                                <input type="file" 
                                                    onChange={(e)=>{
                                                     this.onFileChange(e,i)
                                                    }
                                                }
                                                />
                                                {this.state.verifeExtensions[i] && <br/> && <span style={{color : "red"}}>vous devriez renseigner un fichier pdf</span>}
                                                {this.state.verifeTailles[i] && <br/> && <span style={{color : "red"}}>la taille du fichier ne doit pas depasser 3Mo</span>}
                                        </div>
                                        :
                                        ""
                                        }
                                        
                                        </div>
                                        )}
                                        <div className="p-lg-12">
                                            <div className="p-grid"> 
                                                <div className="p-lg-4"> </div>
                                                <div className="p-lg-2 ">
                                                    <Button  type="button" onClick={this.DepotDossier} label="Deposer" icon="pi pi-check"/>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                
                            </form>
                            </>
                            :
                            ""
                            }
                            
                     </>
                        
                     
                    : 
                    <div className="p-lg-12 p-grid">
                        <div className="p-lg-12 contenair " id="slideshow" style={{textAlign : ""}}>
                            <div id="slider" style={{backgroundColor : "#d1ecf1", padding : 5}}>
                                <p style={{fontWeight : "bold", fontStyle : "italic", fontSize : 16, color : "red", textAlign : "center", marginTop : 10}}>
                                    Session d'inscription close
                                </p>
                            </div>
                        </div>
                    </div>
                    }
                    </div>		
        )
    }
}

export default Depot
