import React, { Component } from 'react'
import { PickList } from 'primereact/picklist';
import {Button} from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

export class AffectationCite extends Component {
    state = {
        source : [],
        target : [],
        idCite : 0,
        listeCite : []
    }
    componentDidMount = () => {
        const source = [
            {ine : "N01234520201", nomEtudiant : "BAKOAN", prenomEtudiant : "Jeans jacques"},
            {ine : "N01234520202", nomEtudiant : "BAKOAN", prenomEtudiant : "Jeannine jacqueline"}
        ]
        const listeCite = [
            {idCite : 1, nomCite : "Kossodo"},{idCite : 2, nomCite : "patte d'oie"},
        ]
        this.setState({source : source, listeCite : listeCite})
    }
    onChange = (e) => {
        this.setState({source: e.source, target: e.target})
    }
    affectation = () => {
        //console.log(this.state.target)
        //console.log(this.state.idCite)
    } 
    itemTemplate(item) {
        return (
            <div className="">
                <div className="">
                    <h6 className="p-mb-2">{item.ine+" "+item.nomEtudiant+" "+item.prenomEtudiant}</h6>
                </div>
            </div>
        );
    }
    render() {
        return (
    
            <div className="p-col-12">
                <div style={{textAlign : "center"}}>
                    <Dropdown style={{width : 230}} optionLabel="nomCite" optionValue="idCite" value={this.state.idCite} options={this.state.listeCite} onChange={(e) => {this.setState({idCite : e.value})}} autoFocus placeholder="Selectionner une cité"/>
                </div>
                
                <div className="" style={{marginLeft : 0, marginTop : 20}}>
                    <PickList source={this.state.source} target={this.state.target} itemTemplate={this.itemTemplate}
                        sourceHeader="Etudiants" targetHeader="Choisis"
                        sourceStyle={{ height: '450px', width : '450px' }} targetStyle={{ width : '450px' ,height: '450px' }}
                        onChange={this.onChange}>
                    </PickList>
                    <div className="affectation" style={{textAlign : "center", marginTop : 20}}>
                    <Button onClick={ this.affectation} label="Affecter" className=""  />
                </div>
                </div>
        </div>
        )
    }
}

export default AffectationCite
