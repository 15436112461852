import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { BatimentService } from '../../service/BatimentService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import { CiteService } from '../../service/CiteService';


export class Batiment extends Component {

    emptyBatiment = {
        idBatiment : 0,
        idCite : 0,
        codeBatiment : "",
        libelleBatiment : ""
    };
    constructor() {
        super();
        this.state = {
            
            listeCite : [],
            listeBatiment : [],
            batimentDialog: false,
            deleteBatimentDialog: false,
            batiment: this.emptyBatiment,
            submitted: false,
            redirect : false,
            loading : false,
            globalFilter: null
        }

        this.batimentService = new BatimentService();
        this.citeService = new CiteService();

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveBatiment = this.saveBatiment.bind(this);
        this.editBatiment = this.editBatiment.bind(this);
        this.confirmDeleteBatiment = this.confirmDeleteBatiment.bind(this);
        this.deleteBatiment = this.deleteBatiment.bind(this);
        this.onLibelleBatimentChange = this.onLibelleBatimentChange.bind(this);
        this.onIdCiteChange = this.onIdCiteChange.bind(this);
        this.onCodeBatimentChange = this.onCodeBatimentChange.bind(this);
        this.hideDeleteBatimentDialog = this.hideDeleteBatimentDialog.bind(this);
    }
    componentDidMount() {
        this.setState({loading : true})
        this.batimentService.getAll().then(data => 
            this.setState({listeBatiment: data, loading : false})
            )
            .catch(error => {
                this.notify2()  
                console.log(error)
                this.setState({loading : false})
            })

        this.citeService.getAll().then(data => {
            console.log(data)
            this.setState({listeCite: data})
        })
            .catch(error => {this.notify2() ; console.log(error)})
        
    }
    
    openNew() {
        this.setState({
            batiment: this.emptyBatiment,
            submitted: false,
            batimentDialog: true
        });
    }
    hideDialog() {
        this.setState({
            submitted: false,
            batimentDialog: false
        });
    }
    hideDeleteBatimentDialog() {
        this.setState({ deleteBatimentDialog: false });
    }
    saveBatiment() {
        let state = { submitted: true };

        if (this.state.batiment.idCite && this.state.batiment.libelleBatiment.trim() && this.state.batiment.codeBatiment.trim()) {

            const newBatiment = {
                idBatiment : parseInt(this.state.batiment.idBatiment) ,
                idCite : parseInt(this.state.batiment.idCite) ,
                libelleBatiment : this.state.batiment.libelleBatiment,
                codeBatiment : this.state.batiment.codeBatiment
            }
            console.log(newBatiment)
            if (this.state.batiment.idBatiment) {
                this.batimentService.update(parseInt(this.state.batiment.idBatiment
                    ), newBatiment)
                                .then(data => {
                                    this.notify();
                                    console.log(data)
                                    this.setState({redirect : true})
                                })
                                .catch(error => {
                                    this.notify2()
                                    console.log(error);
                                })
            }
            else {
                this.batimentService.create(newBatiment).then(data => {
                    this.notify();
                    this.setState({redirect : true})
                })
                .catch(error => {
                    this.notify2()
                    console.log(error);
                })
                
            }

            state = {
                ...state,
                batimentDialog: false,
                batiment: this.emptyBatiment
            };
        }

        this.setState(state);
    }
    editBatiment(batiment) {
        this.setState({
            batiment,
            batimentDialog: true
        });
    }
    confirmDeleteBatiment(batiment) {
        this.setState({
            batiment,
            deleteBatimentDialog: true
        });
    }
    deleteBatiment(idBatiment) {
        this.batimentService.delete(parseInt(idBatiment)).then(data => {
            this.notify();
            this.setState({
                deleteBatimentDialog: false,
                batiment: this.emptyBatiment,
                redirect : true
            });
        })
    }
    
    onLibelleBatimentChange(e, libelleBatiment) {
        const val = (e.target && e.target.value) || '';
        let batiment = this.state.batiment;
        batiment[`${libelleBatiment}`] = val;

        this.setState({batiment });
    }
    onIdCiteChange(e, idCite) {
        const val = (e.target && e.target.value) || '';
        let batiment = this.state.batiment;
        batiment[`${idCite}`] = val;

        this.setState({ batiment });
    }
    onCodeBatimentChange(e, codeBatiment) {
        const val = (e.target && e.target.value) || '';
        let batiment = this.state.batiment;
        batiment[`${codeBatiment}`] = val;

        this.setState({ batiment });
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editBatiment(rowData)} style={{marginRight: '.5em'}}/>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteBatiment(rowData)} />
            </React.Fragment>
        );
    }
    notify = () => toast.success('Opération réuçit!', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    notify2 = () => toast.error("Echec de l'opération !", {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
    loadingSpinner = ( type, color) => (
        <ReactLoading type={type} color={color} height={200} width={50} />
    )
    render() {

        if(this.state.redirect) {
            //window.location.reload(false);
        }
        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        const batimentDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveBatiment} />
            </React.Fragment>
        );
        const deleteBatimentDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteBatimentDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteBatiment(this.state.batiment.idBatiment) } />
            </React.Fragment>
        );
        
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-2">
                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                        </div>
                        <div className="p-col-2">
                            <Button label="Rafraichir" icon="pi pi-refresh" className="" onClick={()=>window.location.reload(false)} />
                        </div>
                    </div>
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.loadingSpinner("spin","black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeBatiment} selectionMode="single" header="Liste des batiments" paginator={true} rows={5}
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="libelleBatiment" header="Batiment" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher batiment"/>
                            <Column field="codeBatiment" header="code batiment" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher code batiment"/>
                            <Column field="nomCite" header="Cité" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher cité"/>
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        }
                    </div>
                </div>

                <Dialog visible={this.state.batimentDialog} style={{ width: '450px' }} header="Informations batiment" modal className="p-fluid" footer={batimentDialogFooter} onHide={this.hideDialog}>
                    <div className="p-field">
                        <label htmlFor="nomCite">Cité</label>
                        <Dropdown optionLabel="nomCite" optionValue="idCite" value={this.state.batiment.idCite} options={this.state.listeCite} onChange={(e) => {this.onIdCiteChange(e, 'idCite')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner une cité"/>
                        {this.state.submitted && !this.state.batiment.idCite && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="libelleBatiment">Nom du Bâtiment</label>
                        <InputText id="libelleBatiment" value={this.state.batiment.libelleBatiment}  onChange={(e) => this.onLibelleBatimentChange(e, 'libelleBatiment')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                        {this.state.submitted && !this.state.batiment.libelleBatiment && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="codeBatiment">Code bâtiment</label>
                        <InputText id="codeBatiment" value={this.state.batiment.codeBatiment}  onChange={(e) => this.onCodeBatimentChange(e, 'codeBatiment')}   />
                    </div>
                </Dialog>
                <Dialog visible={this.state.deleteBatimentDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteBatimentDialogFooter} onHide={this.hideDeleteBatimentDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.batiment && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
            </div> 
        )
    }
}

export default Batiment
