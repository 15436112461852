import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { UtilisateurService} from '../../service/UtilisateurService'
import { DirectionService} from '../../service/DirectionService'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';

export class Utilisateur extends Component {
    emptyUser = {
        id: 0,
        idDirection : 0,
        nom : "",
        prenom : "",
        userName : "",
        password : "",
        password2 : "",
        email : "",
        role : ""
    }
    constructor() {
        super();
        this.state = {
            listeUser : [],
            listeDirection : [],
            recherche : "",
            reinitialisationDialog : false,
            userDialog: false,
            deleteUserDialog: false,
            user: this.emptyUser,
            listeRole : [
                {idRole : 1, role : "ROLE_HEBERGEMENT_ADMIN"},
                {idRole : 2, role : "ROLE_HEBERGEMENT_ETUDIANT"},
                {idRole : 3, role : "ROLE_HEBERGEMENT_GESTIONNAIRE_CENOU"},
                {idRole : 4, role : "ROLE_HEBERGEMENT_GESTIONNAIRE_DR"}
            ],
            submitted: false,
            globalFilter: null,
            redirect : false,
            loading : false
        }

        this.utilisateurService = new UtilisateurService();
        this.directionService = new DirectionService();


        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveUser = this.saveUser.bind(this);
        this.editUser = this.editUser.bind(this);
        this.confirmDeleteUser = this.confirmDeleteUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.onNomChange = this.onNomChange.bind(this);
        this.onPrenomChange = this.onPrenomChange.bind(this);
        this.onUserNameChange = this.onUserNameChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onPassword2Change = this.onPassword2Change.bind(this);
        this.hideDeleteUserDialog = this.hideDeleteUserDialog.bind(this);
    }
    componentDidMount() {
        
        this.directionService.getAll().then(data => this.setState({listeDirection: data}));
        

    }

    rechercheUser = (libelle) => {
        this.utilisateurService.recherche(libelle).then(data => {
            //console.log(data)
            this.setState({listeUser : data})
        })
    }

    actionTemplate(rowData, column) {
        return <div>
            <Button type="button" icon="pi pi-pencil" className="p-button-warning" style={{marginRight: '.5em'}}/>
            <Button type="button" icon="pi pi-trash" className="p-button-danger" />
        </div>;
    }
    openNew() {
        this.setState({
            user: this.emptyUser,
            submitted: false,
            userDialog: true
        });
    }

    reinitialisation = (user) => {
        this.setState({
            user,
            reinitialisationDialog: true
        });
    }
    
    hideReinitialisationDialog = () => {
        this.setState({
            submitted: false,
            reinitialisationDialog: false
        });
    }
    hideDialog() {
        this.setState({
            submitted: false,
            userDialog: false
        });
    }
    hideDeleteUserDialog() {
        this.setState({ deleteUserDialog: false });
    }
    saveUser() {
        let state = { submitted: true };

        if (this.state.user.password.trim() === this.state.user.password2.trim() ) {
            if(this.state.user.nom.trim() && this.state.user.prenom.trim() && this.state.user.userName.trim() && this.state.user.password.trim() )
                {

            const newUser = {
                id : parseInt(this.state.user.id) ,
                idDirection : parseInt(this.state.user.idDirection),
                nom : this.state.user.nom,
                prenom : this.state.user.prenom,
                role : this.state.user.role,
                userName : this.state.user.userName,
                password : this.state.user.password,
            }
            console.log(newUser)
            if (this.state.user.id) {
                this.utilisateurService.update(parseInt(this.state.user.id), newUser)
                .then(data => {
                    this.notify();
                    this.setState({redirect : true})
                })
            }
            else {
                this.utilisateurService.create(newUser).then(data => {
                    this.notify();
                    this.setState({redirect : true})
                })
                
            }

            state = {
                ...state,
                userDialog: false,
                user: this.emptyUser
            };
            
            }
        }

        this.setState(state);
    }

    reinitialiseUser = () => {
        let state = { submitted: true };

        if(this.state.user.id && this.state.user.email.trim() )
            {

            /*const newUser = {
                id : parseInt(this.state.user.id) ,
                idDirection : parseInt(this.state.user.idDirection),
                nom : this.state.user.nom,
                prenom : this.state.user.prenom,
                role : this.state.user.role,
                userName : this.state.user.userName,
                password : this.state.user.password,
            }*/
            console.log(this.state.user)
            let idUser = this.state.user.id;
            let newUser = this.state.user;
            
            this.utilisateurService.reinitialise(idUser, newUser).then(data => {
                console.log(data)
                this.notify();
                this.setState({redirect : true})
            })
                


            state = {
                ...state,
                reinitialisationDialog: false,
                //user: this.emptyUser
            };
            
            }
        

        this.setState(state);
    }

    editUser(user) {
        this.setState({
            user,
            userDialog: true
        });
    }
    deleteUser(idUser) {
        this.utilisateurService.delete(parseInt(idUser))
        this.setState({
            deleteUserDialog: false,
            user: this.emptyUser
        });
        this.notify();
        this.setState({redirect : true})
    }
    confirmDeleteUser(user) {
        this.setState({
            user,
            deleteUserDialog: true
        });
    }
    onDirectionChange(e, idDirection) {
        const val = (e.target && e.target.value) || '';
        let user = this.state.user;
        user[`${idDirection}`] = parseInt(val) ;

        this.setState({user });
    }
    onRoleChange(e, role) {
        const val = (e.target && e.target.value) || '';
        let user = this.state.user;
        user[`${role}`] = val;

        this.setState({user });
    }
    onNomChange(e, nom) {
        const val = (e.target && e.target.value) || '';
        let user = this.state.user;
        user[`${nom}`] = val;

        this.setState({user });
    }
    onPrenomChange(e, prenom) {
        const val = (e.target && e.target.value) || '';
        let user = this.state.user;
        user[`${prenom}`] = val;

        this.setState({user });
    }
    onUserNameChange(e, userName) {
        const val = (e.target && e.target.value) || '';
        let user = this.state.user;
        user[`${userName}`] = val;

        this.setState({user });
    }
    onPasswordChange(e, password) {
        const val = (e.target && e.target.value) || '';
        let user = this.state.user;
        user[`${password}`] = val;

        this.setState({user });
    }
    onPassword2Change(e, password2) {
        const val = (e.target && e.target.value) || '';
        let user = this.state.user;
        user[`${password2}`] = val;

        this.setState({user });
    }
    onRechercheChange = (e, recherche) => {
        const val = (e.target && e.target.value) || '';
        //console.log(val)
        this.setState({recherche : val });
    }
    onUserMailChange = (e, email) => {
        const val = (e.target && e.target.value) || '';
        let user = this.state.user;
        user[`${email}`] = val;
        this.setState({user });
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button  type="button" icon="pi pi-undo" className="p-button-warning" onClick={() => this.reinitialisation(rowData)} style={{marginRight: '.5em'}}/>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editUser(rowData)} style={{marginRight: '.5em'}}/>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteUser(rowData)} />
            </React.Fragment>
        );
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                <Button label="Supprimer" icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} />
            </React.Fragment>
        )
    }
    loadingSpinner = ( type, color) => (
        <ReactLoading type={type} color={color} height={200} width={50} />
    )
    notify = () => toast.success('Opération réuçit!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    notify2 = () => toast.error("Echec de l'opération !", {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })
    render() {
        
        if(this.state.redirect){
            //window.location.reload(false);
        }
        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="p-grid p-col-12">
            <div className="table-header p-col-3">
                <span className="p-input-icon-left">
                    <InputText className="" type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
                
            </div>
            <div className="table-header p-col-6">
                <span className="p-input-icon-left">
                    <label className="p-col-12">Nombre d'Utilisateur(s) trouvé(s) : {this.state.listeUser.length}</label>
                </span>
                
            </div>
            </div>
            
        );
        const reinitialisationDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideReinitialisationDialog} />
                <Button label="Réinitialiser" icon="pi pi-undo" className="p-button-text" onClick={this.reinitialiseUser} />
            </React.Fragment>
        )
        const userDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveUser} />
            </React.Fragment>
        );
        const deleteUserDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteCritereDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteCritere(this.state.critere.idCritere) } />
            </React.Fragment>
        );
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-2">
                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                        </div>
                        <div className="p-col-2">
                            <Button label="Rafraichir" icon="pi pi-refresh" className="" onClick={()=>window.location.reload(false)} />
                        </div>
                    </div>
                    <div className="p-grid" style={{marginTop : 20}}>
                        <div className="p-col-3">
                            <div className="p-field">
                                <InputText id="nom" value={this.state.recherche || ''}  onChange={(e) => this.onRechercheChange(e, 'recherhce')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="INE ou userName" />
                                {this.state.submitted && !this.state.recherche && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>
                        </div>
                        <div className="p-col-2">
                            <Button icon="pi pi-search" className="p-button-success p-mr-2" onClick={(e) => {this.rechercheUser(this.state.recherche)}} />
                        </div>
                    </div>
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.loadingSpinner("spin","black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeUser} selectionMode="single" header="Liste des Utilisateurs" paginator={true} rows={5}
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="userName" header="Identifiant" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher identifiant"/>
                            <Column field="nom" header="Nom" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher nom"/>
                            <Column field="prenom" header="Prénom" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher prénom"/>
                            <Column field="email" header="E-mail" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher email"/>
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '11em'}}/>
                        </DataTable>
                        }
                    </div>
                </div>
                <Dialog visible={this.state.reinitialisationDialog} style={{ width: '500px', height:"400px" }} header="Informations réinitialisation" modal className="p-fluid" footer={reinitialisationDialogFooter} onHide={this.hideReinitialisationDialog}>
                
                    <div className="p-field">
                        <label htmlFor="nom">Nom</label>
                        <InputText id="nom" value={this.state.user.nom || ''}  onChange={(e) => this.onNomChange(e, 'nom')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} readOnly />
                        {this.state.submitted && !this.state.user.nom && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="note">Prénom</label>
                        <InputText id="note" value={this.state.user.prenom || ''}  onChange={(e) => this.onPrenomChange(e, 'prenom')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })}  readOnly />
                        {this.state.submitted && !this.state.user.prenom && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="note">Identifiant</label>
                        <InputText id="note" value={this.state.user.userName || ''}  onChange={(e) => this.onUserNameChange(e, 'userName')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} readOnly  />
                        {this.state.submitted && !this.state.user.userName && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="note">E-mail</label>
                        <InputText id="note" value={this.state.user.email || ''}  onChange={(e) => this.onUserMailChange(e, 'email')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })}   />
                        {this.state.submitted && !this.state.user.email && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                </Dialog>

                <Dialog visible={this.state.userDialog} style={{ width: '500px', height:"400px" }} header="Informations utilisateur" modal className="p-fluid" footer={userDialogFooter} onHide={this.hideDialog}>
                <div className="p-field">
                        <label htmlFor="nom">Direction</label>
                        <Dropdown optionLabel="libelleDirection" optionValue="idDirection" value={this.state.user.idDirection} options={this.state.listeDirection} onChange={(e) => {this.onDirectionChange(e, 'idDirection')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner une direction"/>
                        {this.state.submitted && !this.state.user.idDirection && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="nom">Rôles utilisateur</label>
                        <Dropdown optionLabel="role" optionValue="role" value={this.state.user.role} options={this.state.listeRole} onChange={(e) => {this.onRoleChange(e, 'role')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un rôle"/>
                        {this.state.submitted && !this.state.user.role && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="nom">Nom</label>
                        <InputText id="nom" value={this.state.user.nom || ''}  onChange={(e) => this.onNomChange(e, 'nom')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                        {this.state.submitted && !this.state.user.nom && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="note">Prénom</label>
                        <InputText id="note" value={this.state.user.prenom || ''}  onChange={(e) => this.onPrenomChange(e, 'prenom')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })}   />
                        {this.state.submitted && !this.state.user.prenom && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="note">Identifiant</label>
                        <InputText id="note" value={this.state.user.userName || ''}  onChange={(e) => this.onUserNameChange(e, 'userName')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })}   />
                        {this.state.submitted && !this.state.user.userName && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="pass">Mot de passe</label>
                        <InputText type="password" id="pass" value={this.state.user.password || ''}  onChange={(e) => this.onPasswordChange(e, 'password')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })}   />
                        {this.state.submitted && !this.state.user.password && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="pass2">Confirmé mot de passe</label>
                        <InputText type="password" id="pass2" value={this.state.user.password2 || ''}  onChange={(e) => this.onPassword2Change(e, 'password2')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })}   />
                        {this.state.submitted && !this.state.user.password2 && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                </Dialog>
                <Dialog visible={this.state.deleteUserDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteUserDialogFooter} onHide={this.hideDeleteUserDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.user && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
            </div> 
        )
    }
}

export default Utilisateur
