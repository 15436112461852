import React, { Component } from 'react'
import {Route} from 'react-router-dom';
import {PanelMenu} from 'primereact/panelmenu';
import Cite from './Cite';
import Direction from './Direction';
import TypeCritere from './TypeCritere';
import Critere from './Critere';
import Utilisateur from './Utilisateur';
import Session from './Session';
import Ville from './Ville';
import Batiment from './Batiment';
import Niveau from './Niveau';
import Couloir from './Couloir';
import CategorieChambre from './CategorieChambre';
import Chambre from './Chambre';
import Lit from './Lit';
import Quota from './Quota';
import Export from './Export';

export class MenuParam extends Component {
    constructor() {
        super();
        this.state = {
        
            panelMenuItems: [
                {
                    label:'Paramètres cité',
                    items:[
                       {
                          label:'Nouvelle cité', command:()=>{ window.location = "#/parametre/cite"}
                       },
                       {
                        label:'Nouveau bâtiment', command:()=>{ window.location = "#/parametre/batiment"}
                     },
                       {
                          separator:true
                       },
                       {
                          label:'Nouveau niveau', command:()=>{ window.location = "#/parametre/niveau"}
                       },
                       {
                        label:'Nouveau couloir', command:()=>{ window.location = "#/parametre/couloir"}
                     },
                     {
                        label:'Nouvelle catégorie chambre', command:()=>{ window.location = "#/parametre/categorie-chambre"}
                     },
                     {
                        label:'Nouvelle chambre', command:()=>{ window.location = "#/parametre/chambre"}
                     },
                     {
                        label:'Nouveau lit', command:()=>{ window.location = "#/parametre/lit"}
                     }
                     ,
                     {
                        label:'Nouveau quota', command:()=>{ window.location = "#/parametre/quota"}
                     },
                     {
                        label:'Export données cité', command:()=>{ window.location = "#/parametre/export"}
                     }
                    ]
                 },
                 {
                    label:'Paramètres critère',
                    items:[
                       {
                          label:'Nouveau type de critère', command:()=>{ window.location = "#/parametre/type-critere"}
                       },
                       {
                        label:'Nouveau critère', command:()=>{ window.location = "#/parametre/critere"}
                        }
                    ]
                 },
                 {
                    label:'Paramètres paiement',
                    items:[
                       {
                          label:'Nouveau type de paiement', command:()=>{ window.location = "#/parametre/typePaiement"}
                       },
                       {
                        label:'Nouveau opérateur', command:()=>{ window.location = "#/parametre/opérateur"}
                        }
                    ]
                 },
                 {
                    label:'Autres paramètres',
                    items:[
                       {
                          label:'Nouvelle Direction ', command:()=>{ window.location = "#/parametre/direction"}
                       },
                       {
                        label:'Nouvelle ville', command:()=>{ window.location = "#/parametre/ville"}
                        },
                        {
                            label:'Nouveau agent', command:()=>{ window.location = "#/parametre/agent"}
                        },
                        {
                           label:'Nouvelle session', command:()=>{ window.location = "#/parametre/session"}
                       },
                    ]
                 },
                 {
                    label:'Administration',
                    items:[
                       {
                          label:'Nouveau utilisateur', command:()=>{ window.location = "#/parametre/utilisateur"}
                       },
                       {
                        label:'Nouveau profile', command:()=>{ window.location = "#/parametre/profile"}
                        },
                        {
                            label:'Nouveau rôle', command:()=>{ window.location = "#/parametre/role"}
                        },
                    ]
                 },
            ]
        };
    }
    render() {
        return (
            <div className="p-grid p-fluid">
                <div className="p-col-12 p-lg-2">
                        <PanelMenu model={this.state.panelMenuItems} />
                </div>
                <div className="p-lg-10">
                    <Route path="/parametre/cite" exact component={Cite}/>
                    <Route path="/parametre/direction" exact component={Direction}/>
                    <Route path="/parametre/type-critere" exact component={TypeCritere}/>
                    <Route path="/parametre/critere" exact component={Critere}/>
                    <Route path="/parametre/utilisateur" exact component={Utilisateur}/>
                    <Route path="/parametre/session" exact component={Session}/>
                    <Route path="/parametre/ville" exact component={Ville}/>
                    <Route path="/parametre/batiment" exact component={Batiment}/>
                    <Route path="/parametre/niveau" exact component={Niveau}/>
                    <Route path="/parametre/couloir" exact component={Couloir}/>
                    <Route path="/parametre/categorie-chambre" exact component={CategorieChambre}/>
                    <Route path="/parametre/chambre" exact component={Chambre}/>
                    <Route path="/parametre/lit" exact component={Lit}/>
                    <Route path="/parametre/quota" exact component={Quota}/>
                    <Route path="/parametre/export" exact component={Export}/>
                </div>
            </div>
        )
    }
}

export default MenuParam
