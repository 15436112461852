import axios from 'axios';
import { AdresseIP } from './AdresseIp';

export class QuotaService {

    constructor(){
        this.adresse = new AdresseIP()
    }
    
    getAll() {
        
        return axios.get(this.adresse.getAdresseIP() +'/service/api/hebergement-cenou/campus-faso/quota')
                    .then(res => res.data);
    }
    create(newQuota) {
        const url = this.adresse.getAdresseIP() +'/service/api/hebergement-cenou/campus-faso/quota'
        return axios.post(url,newQuota)
                    .then(res => res.data);
    }
    update(idQuota ,newQuota) {
        const url = this.adresse.getAdresseIP() +'/service/api/hebergement-cenou/campus-faso/quota/'
        return axios.put(url+idQuota, newQuota)
                    .then(res => res.data);
    }
    delete(idQuota) {
        const url = this.adresse.getAdresseIP() +'/service/api/hebergement-cenou/campus-faso/quota/'
        return axios.patch(url+idQuota)
                    .then(res => res.data)
    }
    get(idCite) {
        const url = this.adresse.getAdresseIP() +'/service/api/hebergement-cenou/campus-faso/lit/?quota='+idCite
        return axios.get(url)
                    .then(res => res.data)
    }

}