import axios from 'axios';
import { AdresseIP } from './AdresseIp';

export class SessionService {

    constructor(){
        this.adresse = new AdresseIP()
    }

    getAll() {
        const url = this.adresse.getAdresseIP() +"/service/api/hebergement-cenou/campus-faso/session-cenou"
        return axios.get(url).then(res => res.data)
        
    }
    create(newSession) {
        const url = this.adresse.getAdresseIP() +"/service/api/hebergement-cenou/campus-faso/session-cenou"
        return axios.post(url,newSession)
                    .then(res => res.data);
    }
    update(idSession ,newSession) {
        const url = this.adresse.getAdresseIP() +"/service/api/hebergement-cenou/campus-faso/session-cenou/"
        return axios.put(url+idSession, newSession)
                    .then(res => res.data);
    }
    delete(idSession) {
        const url = this.adresse.getAdresseIP() +"/service/api/hebergement-cenou/campus-faso/session-cenou/"
        return axios.patch(url+idSession)
                    .then(res => res.data)
    }
    getSessionDirection(idDirection) {
        const url = this.adresse.getAdresseIP() +"/service/api/hebergement-cenou/campus-faso/session-cenou?idDirection="+idDirection
        return axios.get(url)
                    .then(res => res.data)
    }

}