import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { VilleService } from '../../service/VilleService';
import { DirectionService} from '../../service/DirectionService'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class Ville extends Component {
    emptyVille = {
        idVille : 0,
        idDirection : 0,
        nomVille : "",
    }
    constructor() {
        super();
        this.state = {
            listeEnumVille : [
                {idVille : 1, nomVille : "OUAGADOUGOU"},
                {idVille : 2, nomVille : "OUAGADOUGOU2"},
                {idVille : 3, nomVille : "BOBO_DIOULASSO"},
                {idVille : 4, nomVille : "DEDOUGOU"},
                {idVille : 5, nomVille : "KAYA"},
                {idVille : 6, nomVille : "ZINIARE"},
                {idVille : 7, nomVille : "OUAHIGOUYA"},
                {idVille : 8, nomVille : "FADA_NGOURMA"},
                {idVille : 9, nomVille : "TENKODOGO"},
                {idVille : 10, nomVille : "KOUDOUGOU"},
                {idVille : 11, nomVille : "DORI"},
                {idVille : 12, nomVille : "BANFORA"},
                {idVille : 13, nomVille : "MANGA"}
            ],
            listeVille:[],
            listeDirection : [],
            villeDialog: false,
            deleteVilleDialog: false,
            ville: this.emptyVille,
            submitted: false,
            globalFilter: null,
            redirect : false
        }

        this.villeService = new VilleService();
        this.directionService = new DirectionService()

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveVille = this.saveVille.bind(this);
        this.editVille = this.editVille.bind(this);
        this.confirmDeleteVille = this.confirmDeleteVille.bind(this);
        this.deleteVille = this.deleteVille.bind(this);
        this.onNomVilleChange = this.onNomVilleChange.bind(this);
        this.onIdDirectionChange = this.onIdDirectionChange.bind(this);
        this.hideDeleteVilleDialog = this.hideDeleteVilleDialog.bind(this);
    }
    componentDidMount() {
        this.villeService.getAll().then(data => this.setState({listeVille: data}));
        this.directionService.getAll().then(data => this.setState({listeDirection: data}));
        
    }

    actionTemplate(rowData, column) {
        return <div>
            <Button type="button" icon="pi pi-pencil" className="p-button-warning" style={{marginRight: '.5em'}}/>
            <Button type="button" icon="pi pi-trash" className="p-button-danger" />
        </div>;
    }
    openNew() {
        this.setState({
            ville: this.emptyVille,
            submitted: false,
            villeDialog: true
        });
    }
    hideDialog() {
        this.setState({
            submitted: false,
            villeDialog: false
        });
    }
    hideDeleteVilleDialog() {
        this.setState({ deleteVilleDialog: false });
    }
    saveVille() {
        let state = { submitted: true };

        if (this.state.ville.nomVille && this.state.ville.idDirection ) {

            const newVille = {
                idDirection : parseInt(this.state.ville.idDirection) ,
                nomVille : this.state.ville.nomVille
            }
            console.log(newVille)
            if (this.state.ville.idVille) {
                this.villeService.update(parseInt(this.state.critere.idVille), newVille)
                this.notify();
                this.setState({redirect : true})

            }
            else {
                this.villeService.create(newVille)
                this.notify();
                this.setState({redirect : true})
            }

            state = {
                ...state,
                villeDialog: false,
                ville: this.emptyVille
            };
        }

        this.setState(state);
    }
    editVille(ville) {
        this.setState({
            ville,
            villeDialog: true
        });
    }
    deleteVille(idVille) {
        this.villeService.delete(parseInt(idVille))
        this.setState({
            deleteVilleDialog: false,
            ville: this.emptyVille
        });
        this.notify();
        this.setState({redirect : true})
    }
    confirmDeleteVille(ville) {
        this.setState({
            ville,
            deleteVilleDialog: true
        });
    }
    onIdDirectionChange(e, idDirection) {
        const val = (e.target && e.target.value) || '';
        let ville = this.state.ville;
        ville[`${idDirection}`] = parseInt(val);

        this.setState({ville });
    }
    onNomVilleChange(e, nomVille) {
        const val = (e.target && e.target.value) || '';
        let ville = this.state.ville;
        ville[`${nomVille}`] = val;

        this.setState({ville });
    }
    
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editVille(rowData)} style={{marginRight: '.5em'}}/>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteVille(rowData)} />
            </React.Fragment>
        );
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                <Button label="Supprimer" icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} />
            </React.Fragment>
        )
    }
    notify = () => toast.success('Opération réuçit!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    render() {
        if(this.state.redirect){
            //window.location.reload(false);
        }
        //console.log(this.state.listeEnumVille)
        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        const villeDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveVille} />
            </React.Fragment>
        );
        const deleteVilleDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteVilleDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteVille(this.state.ville.idVille) } />
            </React.Fragment>
        );
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-2">
                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                        </div>
                        <div className="p-col-2">
                            <Button label="Rafraichir" icon="pi pi-refresh" className="" onClick={()=>window.location.reload(false)} />
                        </div>
                    </div>
                    
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeVille} selectionMode="single" header="Liste des villes" 
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="nomVille" header="Ville" sortable={true} filter={true} filterPlaceholder="rechercher ville"/>
                            <Column field="libelleDirection" header="Direction" sortable={true} filter={true} filterPlaceholder="rechercher direction"/>
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                    </div>
                </div>

                <Dialog visible={this.state.villeDialog} style={{ width: '500px', height:"500px" }} header="Informations ville" modal className="p-fluid" footer={villeDialogFooter} onHide={this.hideDialog}>
                    <div className="p-field">
                        <label htmlFor="idDirection">Direction</label>
                        <Dropdown optionLabel="libelleDirection" optionValue="idDirection" value={this.state.ville.idDirection} options={this.state.listeDirection} onChange={(e) => {this.onIdDirectionChange(e, 'idDirection')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un type critère"/>
                        {this.state.submitted && !this.state.ville.idDirection && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="nomVille">Nom ville</label>
                        <Dropdown optionLabel="nomVille" optionValue="nomVille" value={this.state.ville.nomVille} options={this.state.listeEnumVille} onChange={(e) => {this.onNomVilleChange(e, 'nomVille')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner une ville"/>
                        {this.state.submitted && !this.state.ville.nomVille && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                </Dialog>
                <Dialog visible={this.state.deleteVilleDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteVilleDialogFooter} onHide={this.hideDeleteVilleDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.ville && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
            </div> 
        )
    }
}

export default Ville
