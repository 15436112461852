import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import { LitService } from '../../service/LitService';


export class Export extends Component {

    
    constructor() {
        super();
        this.state = {
            listeLit : [],
            loading : false,
            globalFilter: null
        }

        this.exportCSV = this.exportCSV.bind(this);
        this.litService = new LitService();
        
    }
    componentDidMount() {
        this.setState({loading : true})
        this.litService.getAll().then(data => 
            this.setState({listeLit: data, loading : false})
            )
            .catch(error => {
                this.notify2()  
                console.log(error)
                this.setState({loading : false})
            })
        
    }
    exportCSV() {
        this.dt.exportCSV();
    }
    notify2 = () => toast.error("Echec de l'opération !", {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
    loadingSpinner = ( type, color) => (
        <ReactLoading type={type} color={color} height={200} width={50} />
    )
    render() {

        //console.log(this.state.listeLit)
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-2">
                            <Button label="Exporter" icon="pi pi-external-link" className="p-button-success p-mr-2" onClick={this.exportCSV} />
                        </div>
                    </div>
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.loadingSpinner("spin","black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeLit} selectionMode="single" header="Liste des données des cités" paginator={true} rows={10}
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="nomCite" header="Cité" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher cité"/>       
                            <Column field="libelleBatiment" header="Bâtiment" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher bâtiment"/>       
                            <Column field="libelleNiveau" header="Niveau" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher niveau"/>       
                            <Column field="libelleCouloir" header="Couloir" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher couloir"/>
                            <Column field="libelleChambre" header="Chambre" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher chambre"/>
                            <Column field="libelleCategorieChambre" header="Catégorie" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher catégorie"/>
                            <Column field="libelleLit" header="Lit" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher lit"/>
                        </DataTable>
                        }
                    </div>
                </div>
            </div> 
        )
    }
}

export default Export
