import React, {Component} from 'react';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.min.css';
import './App.css';
import Axios from 'axios'
import Menu from './components/Menu';


class App extends Component {
	
	constructor(props){
		super(props);
		this.state = {
			user : {},
			verife : false,
			prive : false
		}
		
	}
	componentDidMount = () => {
		const data = localStorage.getItem('user')
		const accessToken = localStorage.getItem('accessToken')
		const user = JSON.parse(data)
		const token = JSON.parse(accessToken)
		//console.log(user)
		Axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
		this.setState({user : user, verife : true})
		
	}
	render() {
		
		//console.log(this.state.user)
		return (
			<div className="App">
				{this.state.verife ?
				<Menu user={this.state.user}/>
				:
				""
				}
    		</div>
		);
	}
}

export default App;
