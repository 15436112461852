import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { VilleService } from '../../service/VilleService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import { CiteService } from '../../service/CiteService';


export class Cite extends Component {
    emptyCite = {
        idCite: 0,
        idVille : 0,
        nomCite: "",
        nbLit : 0,
        nomVille : "",
        speudoCite : "",
        adresseCite : ""
       
    };
    constructor() {
        super();
        this.state = {
            
            listeCite : [],
            listeVille : [],
            citeDialog: false,
            deleteCiteDialog: false,
            cite: this.emptyCite,
            submitted: false,
            redirect : false,
            loading : false,
            globalFilter: null
        }

        this.villeService = new VilleService();
        this.citeService = new CiteService();

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveCite = this.saveCite.bind(this);
        this.editCite = this.editCite.bind(this);
        this.confirmDeleteCite = this.confirmDeleteCite.bind(this);
        this.deleteCite = this.deleteCite.bind(this);
        this.onNomCiteChange = this.onNomCiteChange.bind(this);
        this.onIdVilleChange = this.onIdVilleChange.bind(this);
        this.onNbLitChange = this.onNbLitChange.bind(this);
        this.onSpeudoCiteChange = this.onSpeudoCiteChange.bind(this);
        this.onAdresseCiteChange = this.onAdresseCiteChange.bind(this);
        this.hideDeleteCiteDialog = this.hideDeleteCiteDialog.bind(this);
    }
    componentDidMount() {
        this.setState({loading : true})
        this.citeService.getAll().then(data => 
            this.setState({listeCite: data, loading : false})
            )
            .catch(error => {
                this.notify2()  
                console.log(error)
                this.setState({loading : false})
            })

        this.villeService.getAll().then(data => this.setState({listeVille: data}))
            .catch(error => {this.notify2() ; console.log(error)})
        
    }
    
    openNew() {
        this.setState({
            cite: this.emptyCite,
            submitted: false,
            citeDialog: true
        });
    }
    hideDialog() {
        this.setState({
            submitted: false,
            citeDialog: false
        });
    }
    hideDeleteCiteDialog() {
        this.setState({ deleteCiteDialog: false });
    }
    saveCite() {
        let state = { submitted: true };

        if (this.state.cite.idVille && this.state.cite.nbLit && this.state.cite.nomCite.trim()) {

            const newCite = {
                idCite : parseInt(this.state.cite.idCite) ,
                idVille : parseInt(this.state.cite.idVille) ,
                nomCite : this.state.cite.nomCite,
                nbLit : this.state.cite.nbLit,
                //speudoCite : this.state.cite.speudoCite,
                adresseCite : this.state.cite.adresseCite
            }
            console.log(newCite)
            if (this.state.cite.idCite) {
                this.citeService.update(parseInt(this.state.cite.idCite), newCite)
                                .then(data => {
                                    this.notify();
                                    console.log(data)
                                    this.setState({redirect : true})
                                })
                                .catch(error => {
                                    this.notify2()
                                    console.log(error);
                                })
            }
            else {
                this.citeService.create(newCite).then(data => {
                    this.notify();
                    this.setState({redirect : true})
                })
                .catch(error => {
                    this.notify2()
                    console.log(error);
                })
                
            }

            state = {
                ...state,
                citeDialog: false,
                cite: this.emptyCite
            };
        }

        this.setState(state);
    }
    editCite(cite) {
        this.setState({
            cite,
            citeDialog: true
        });
    }
    confirmDeleteCite(cite) {
        this.setState({
            cite,
            deleteCiteDialog: true
        });
    }
    deleteCite(idCite) {
        this.citeService.delete(parseInt(idCite)).then(data => {
            this.notify();
            this.setState({
                deleteCiteDialog: false,
                cite: this.emptyCite,
                redirect : true
            });
        })
        
        

    }
    
    onNomCiteChange(e, nomCite) {
        const val = (e.target && e.target.value) || '';
        let cite = this.state.cite;
        cite[`${nomCite}`] = val;

        this.setState({cite });
    }
    onIdVilleChange(e, idVille) {
        const val = (e.target && e.target.value) || '';
        let cite = this.state.cite;
        cite[`${idVille}`] = val;

        this.setState({ cite });
    }
    onNbLitChange(e, nbLit) {
        const val = (e.target && e.target.value) || '';
        let cite = this.state.cite;
        cite[`${nbLit}`] = val;

        this.setState({ cite });
    }
    onSpeudoCiteChange(e, speudoCite) {
        const val = (e.target && e.target.value) || '';
        let cite = this.state.cite;
        cite[`${speudoCite}`] = val;

        this.setState({ cite });
    }
    onAdresseCiteChange(e, adresseCite) {
        const val = (e.target && e.target.value) || '';
        let cite = this.state.cite;
        cite[`${adresseCite}`] = val;

        this.setState({ cite });
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editCite(rowData)} style={{marginRight: '.5em'}}/>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteCite(rowData)} />
            </React.Fragment>
        );
    }
    notify = () => toast.success('Opération réuçit!', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    notify2 = () => toast.error("Echec de l'opération !", {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
    loadingSpinner = ( type, color) => (
        <ReactLoading type={type} color={color} height={200} width={50} />
    )
    render() {
        if(this.state.redirect) {
            //window.location.reload(false);
        }
        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        const citeDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveCite} />
            </React.Fragment>
        );
        const deleteCiteDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteCiteDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteCite(this.state.cite.idCite) } />
            </React.Fragment>
        );
        
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-2">
                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                        </div>
                        <div className="p-col-2">
                            <Button label="Rafraichir" icon="pi pi-refresh" className="" onClick={()=>window.location.reload(false)} />
                        </div>
                    </div>
    
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.loadingSpinner("spin","black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeCite} selectionMode="single" header="Liste des cités" paginator={true} rows={5}
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="nomCite" header="Cité" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher cité"/>
                            <Column field="nomVille" header="Ville" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher direction"/>
                            <Column field="nbLit" header="Nb. de lit" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher nombre de lit"/>
                            <Column field="adresseCite" header="Adresse" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher téléphone"/>
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        }
                    </div>
                </div>

                <Dialog visible={this.state.citeDialog} style={{ width: '450px' }} header="Informations cité" modal className="p-fluid" footer={citeDialogFooter} onHide={this.hideDialog}>
                    <div className="p-field">
                        <label htmlFor="nomVille">Ville</label>
                        <Dropdown optionLabel="nomVille" optionValue="idVille" value={this.state.cite.idVille} options={this.state.listeVille} onChange={(e) => {this.onIdVilleChange(e, 'idVille')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner une ville"/>
                        {this.state.submitted && !this.state.cite.idVille && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="nomCite">Nom de la cité</label>
                        <InputText id="nomCite" value={this.state.cite.nomCite}  onChange={(e) => this.onNomCiteChange(e, 'nomCite')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                        {this.state.submitted && !this.state.cite.nomCite && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="nbLit">Nombre de lit</label>
                        <InputText id="nbLit" value={this.state.cite.nbLit}  onChange={(e) => this.onNbLitChange(e, 'nbLit')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                        {this.state.submitted && !this.state.cite.nbLit && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="adresseCite">Adresse</label>
                        <InputText id="adresseCite" value={this.state.cite.adresseCite}  onChange={(e) => this.onAdresseCiteChange(e, 'adresseCite')}   />
                    </div>
                </Dialog>
                <Dialog visible={this.state.deleteCiteDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteCiteDialogFooter} onHide={this.hideDeleteCiteDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.cite && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
            </div> 
        )
    }
}

export default Cite
