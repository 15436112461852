import React, { Component } from 'react'
import { Card } from 'primereact/card';
//import SlideShow  from './SlideImage'

export class Acceuil extends Component {
    render() {
        return (
            <div>
            <div className="p-lg-12 p-grid">
                <div className="p-lg-12 contenair " id="slideshow" style={{textAlign : ""}}>
                    <div id="slider" style={{backgroundColor : "#d1ecf1", padding : 5}}>
                        <p style={{fontSize : 16, color : "red", textAlign : "center"}}>
                        Attention ! Seule les etudiants inscrits dans les universités qui se trouvent dans les villes de Ouagadougou, de Koudougou et de Bobo-Dioulasso peuvent postuler pour les cités ou résisdences universitaire.
                        </p>
                    </div>
                </div>
            </div>
                
                <div className="p-lg-12">
                    <div className="p-grid">
                    <Card className="p-lg-3" title="Processus de depôt de dossier" >
                        <h3>Création de compte</h3>
                        <p style={{fontWeight : "bold"}}>
                            <ul>
                                <li>Dans le menu cliquez sur l'onglet connexion puis s'inscrire</li>
                                <li>Ensuite saisissez votre INE et cliquer sur Inscription</li>
                                <li>Si votre INE est correct vos données vous serons renvoyées, renseignez les champs à completer et cliquer sur crée compte</li>
                                <li>Un mail vous sera envoyé dans votre adresse mail avec les informations de connexion</li>
                            </ul>
                        </p>
                        <h3>Depôt de dossier</h3>
                        <p style={{fontWeight : "bold"}}>
                            <ul>
                                <li>Dans le menu cliquez sur l'onglet connexion puis se connecter</li>
                                <li>Saisissez votre INE et mot de passe et cliquer sur s'authentifier</li>
                                <li>Ensuite dans l'onglet depôt cliquer sur cité et remplisser les critères</li>
                                <li>Une fois les critères remplis cliquer sur deposer</li>
                                <li>Un numéro de recipissé vous sera envoyé par mail montrant que vous avez fait votre depot</li>
                                <li>Aller dans tableau de bord pour consulter votre dossier</li>
                            </ul>
                        </p>
                    </Card>
                    <div className="p-lg-1"></div>
                    <Card className="p-lg-3" title="Dossier de candidature" >
                        <p style={{fontWeight : "bold"}}>
                            <span style={{color : "red"}}>Le Dossier de candidature se fait entièrement en ligne.
                            Les étudiants dont les dossiers seront retenu deposerons un dossier physique avec les pièces suivantes : <br/></span>
                            
                            <ul>
                                <li>Une (01) demande d'admission avec deux (02) photos d'identité recentes</li>
                                <li style={{marginTop : "20px"}}>Un (01) certificat médical établi par le CENOU ou agrée </li>
                                <li style={{marginTop : "20px"}}>Un (01) engagement d'une caution personnelle et solidaire du père ou du tuteur</li>
                                <li style={{marginTop : "20px"}}>Une (01) photocopie de la carte d'identité burkinabè ou de la carte consulaire</li>
                                <li style={{marginTop : "20px"}}>Une (01) photocopie de la carte du CENOU pour les étudiants</li>
                            </ul>
                        </p>
                    </Card>
                    <div className="p-lg-1"></div>
                    <Card className="p-lg-3" title="Texts règlementaires et autres">
                        <p style={{fontWeight:"bold"}}>
                        <ul>
                            <li ><a style={{fontWeight:"bold"}} href="/" >Statut et règlement intereiur</a></li>
                            <li style={{marginTop : "20px"}}><a style={{ fontWeight:"bold"}} href="/">Engagement d'une caution personnelle et solidaire du père, de la mère ou du tuteur</a></li>
                        </ul>
                        </p>
                    </Card>
                </div>
                </div>
            </div>
            
        )
    }
}

export default Acceuil
