import axios from 'axios';
import { AdresseIP } from './AdresseIp';

export class DossierService {

    constructor(){
        this.adresse = new AdresseIP()
    }
    
    getAll() {
        const url = this.adresse.getAdresseIP() + "/service/api/hebergement-cenou/campus-faso/dossier"
        return axios.get(url)
                    .then(res => res.data);
    }
    get(username) {
        const url = this.adresse.getAdresseIP() +"/service/api/hebergement-cenou/campus-faso/dossier/etudiant/"
        return axios.get(url+username)
                    .then(res => res.data)
    }
    delete(idDossier) {
        const url = this.adresse.getAdresseIP() +"/service/api/hebergement-cenou/campus-faso/dossier/"
        return axios.patch(url+idDossier)
                    .then(res => res.data)
    }
    getListeDossierCritere(ine) {
        const url = this.adresse.getAdresseIP() +"/service/api/hebergement-cenou/campus-faso/dossier/depot/?ineEtudiant="+ine
        return axios.get(url)
                    .then(res => res)
    }
    depot (newDossier) {
        const url = this.adresse.getAdresseIP() + "/service/api/hebergement-cenou/campus-faso/dossier"
        return axios.put(url,newDossier)
        .then(res => res.data);
    }
    consulterDossier(ine, idDossier) {
        const url = this.adresse.getAdresseIP() + '/service/api/hebergement-cenou/campus-faso/dossier/depot?ineEtudiant='+ine+'&idDossier='+idDossier
        return axios.get(url)
                    .then(res => res)
    }
    getListeDossierEtudiant(idDirection, etatSession){
        const url = this.adresse.getAdresseIP() + '/service/api/hebergement-cenou/campus-faso/dossier?idDirection='+idDirection+'&etatSession='+etatSession
        return axios.get(url)
                    .then(res => res.data)
    }
    consulterFichier(ine, nomFichier) {
        const url = this.adresse.getAdresseIP() + '/service/api/hebergement-cenou/campus-faso/dossier/'
        return axios.get(url+ine+'/'+nomFichier)
                    .then(res => res)
    }

}