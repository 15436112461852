import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { DirectionService } from '../../service/DirectionService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class Direction extends Component {
    emptyDirection = {
        idDirection: null,
        libelleDirection: '',
        sigleDirection : "",
        emailDirection : "",
        telDirection : ""
    };
    constructor() {
        super();
        this.state = {
            listeDirection:[],
            directionDialog: false,
            deleteDirectionDialog: false,
            direction: this.emptyDirection,
            submitted: false,
            globalFilter: null
        }

        this.directionService = new DirectionService();

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveDirection = this.saveDirection.bind(this);
        this.editDirection = this.editDirection.bind(this);
        this.confirmDeleteDirection = this.confirmDeleteDirection.bind(this);
        this.deleteDirection = this.deleteDirection.bind(this);
        this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
        this.onLibelleDirectionChange = this.onLibelleDirectionChange.bind(this);
        this.onSigleDirectionChange = this.onSigleDirectionChange.bind(this);
        this.onEmailDirectionChange = this.onEmailDirectionChange.bind(this);
        this.onTelDirectionChange = this.onTelDirectionChange.bind(this);
        this.hideDeleteDirectionDialog = this.hideDeleteDirectionDialog.bind(this);
    }
    componentDidMount() {
        this.directionService.getAll().then(data => this.setState({listeDirection: data}));
    }
    actionTemplate(rowData, column) {
        return <div>
            <Button type="button" icon="pi pi-pencil" className="p-button-warning" style={{marginRight: '.5em'}}/>
            <Button type="button" icon="pi pi-trash" className="p-button-danger" />
        </div>;
    }
    openNew() {
        this.setState({
            direction: this.emptyDirection,
            submitted: false,
            directionDialog: true
        });
    }
    hideDialog() {
        this.setState({
            submitted: false,
            directionDialog: false
        });
    }
    hideDeleteDirectionDialog() {
        this.setState({ deleteDirectionDialog: false });
    }
    saveDirection() {
        let state = { submitted: true };

        if (this.state.direction.libelleDirection.trim()) {

            const newDirection = {
                idDirection : parseInt(this.state.direction.idDirection) ,
                libelleDirection : this.state.direction.libelleDirection,
                sigleDirection : this.state.direction.sigleDirection,
                emailDirection : this.state.direction.emailDirection,
                telDirection : this.state.direction.telDirection
            }
            if (this.state.direction.idDirection) {
                this.directionService.update(parseInt(this.state.direction.idDirection), newDirection)
                this.notify();

            }
            else {
                this.directionService.create(newDirection)
                this.notify();
            }

            state = {
                ...state,
                directionDialog: false,
                direction: this.emptyDirection
            };
        }

        this.setState(state);
    }
    editDirection(direction) {
        this.setState({
            direction,
            directionDialog: true
        });
    }
    confirmDeleteDirection(direction) {
        this.setState({
            direction,
            deleteDirectionDialog: true
        });
    }
    deleteDirection(idDirection) {
        this.directionService.delete(parseInt(idDirection))
        this.setState({
            deleteDirectionDialog: false,
            direction: this.emptyDirection
        });
        this.notify();
    }
    confirmDeleteSelected() {
        this.setState({ deleteDirectionsDialog: true });
    }
    onLibelleDirectionChange(e, libelleDirection) {
        const val = (e.target && e.target.value) || '';
        let direction = this.state.direction;
        direction[`${libelleDirection}`] = val;

        this.setState({direction });
    }
    onSigleDirectionChange(e, sigleDirection) {
        const val = (e.target && e.target.value) || '';
        let direction = this.state.direction;
        direction[`${sigleDirection}`] = val;

        this.setState({ direction });
    }
    onEmailDirectionChange(e, emailDirection) {
        const val = (e.target && e.target.value) || '';
        let direction = this.state.direction;
        direction[`${emailDirection}`] = val;

        this.setState({ direction });
    }
    onTelDirectionChange(e, telDirection) {
        const val = (e.target && e.target.value) || '';
        let direction = {...this.state.direction};
        direction[`${telDirection}`] = val;

        this.setState({ direction });
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                <Button label="Supprimer" icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} />
            </React.Fragment>
        )
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editDirection(rowData)} style={{marginRight: '.5em'}}/>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteDirection(rowData)} />
            </React.Fragment>
        );
    }
    notify = () => toast.success('Opération réuçit!', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    render() {

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        const directionDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveDirection} />
            </React.Fragment>
        );
        const deleteDirectionDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteDirectionDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteDirection(this.state.direction.idDirection) } />
            </React.Fragment>
        );
        
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-2">
                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                        </div>
                        <div className="p-col-2">
                            <Button label="Rafraichir" icon="pi pi-refresh" className="" onClick={()=>window.location.reload(false)} />
                        </div>
                    </div>
    
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeDirection} selectionMode="single" header="Liste des directions" paginator={true} rows={5}
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="libelleDirection" header="Direction" sortable={true} filter={true} filterPlaceholder="rechercher direction"/>
                            <Column field="sigleDirection" header="Sigle" sortable={true} filter={true} filterPlaceholder="rechercher direction"/>
                            <Column field="emailDirection" header="Email" sortable={true} filter={true} filterPlaceholder="rechercher email"/>
                            <Column field="telDirection" header="Téléphone" sortable={true} filter={true} filterPlaceholder="rechercher téléphone"/>
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                    </div>
                </div>

                <Dialog visible={this.state.directionDialog} style={{ width: '450px' }} header="Informations direction" modal className="p-fluid" footer={directionDialogFooter} onHide={this.hideDialog}>
                    <div className="p-field">
                        <label htmlFor="libelleDirection">Nom de la direction</label>
                        <InputText id="libelleDirection" value={this.state.direction.libelleDirection}  onChange={(e) => this.onLibelleDirectionChange(e, 'libelleDirection')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                        {this.state.submitted && !this.state.direction.libelleDirection && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="sigleDirection">Sigle</label>
                        <InputText id="sigleDirection" value={this.state.direction.sigleDirection}  onChange={(e) => this.onSigleDirectionChange(e, 'sigleDirection')}   />
                    </div>
                    <div className="p-field">
                        <label htmlFor="emailDirection">Email</label>
                        <InputText id="emailDirection" value={this.state.direction.emailDirection}  onChange={(e) => this.onEmailDirectionChange(e, 'emailDirection')}   />
                    </div>
                    <div className="p-field">
                        <label htmlFor="telDirection">Téléphone</label>
                        <InputText id="telDirection" value={this.state.direction.telDirection}  onChange={(e) => this.onTelDirectionChange(e, 'telDirection')}  />
                    </div>
                </Dialog>
                <Dialog visible={this.state.deleteDirectionDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteDirectionDialogFooter} onHide={this.hideDeleteDirectionDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.direction && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
            </div> 
        )
    }
}

export default Direction
