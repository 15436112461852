import axios from 'axios';

export class ChambreService {
    
    getAll() {
        
        return axios.get('http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/chambre')
                    .then(res => res.data);
    }
    create(newChambre) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/chambre'
        return axios.post(url,newChambre)
                    .then(res => res.data);
    }
    update(idChambre ,newChambre) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/chambre/'
        return axios.put(url+idChambre, newChambre)
                    .then(res => res.data);
    }
    delete(idChambre) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/chambre/'
        return axios.patch(url+idChambre)
                    .then(res => res.data)
    }
    get(idCouloir) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/chambre/?idCouloir='+idCouloir
        return axios.get(url)
                    .then(res => res.data)
    }

}