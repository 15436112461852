import axios from 'axios';
import { AdresseIP } from './AdresseIp';

export class DeliberationService {

    constructor(){
        this.adresse = new AdresseIP()
    }

    deliberation(idSession, idDirection) {
        const url = this.adresse.getAdresseIP() +"/service/api/hebergement-cenou/campus-faso/deliberation-nationaux?idSessionCenou="+idSession+"&idDirection="+idDirection
        return axios.get(url)
                    .then(res => res)
    }

    resultatDeliberation(idSession, idDirection) {
        const url = this.adresse.getAdresseIP() +"/service/api/hebergement-cenou/campus-faso/etudiant-admis?idSessionCenou="+idSession+"&idDirection="+idDirection
        return axios.get(url)
                    .then(res => res)
    }

}