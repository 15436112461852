import axios from 'axios';

export class CategorieChambreService {
    
    getAll() {
        
        return axios.get('http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/categorie-chambre')
                    .then(res => res.data);
    }
    create(newCategorie) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/categorie-chambre'
        return axios.post(url,newCategorie)
                    .then(res => res.data);
    }
    update(idCategorie ,newCategorie) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/categorie-chambre/'
        return axios.put(url+idCategorie, newCategorie)
                    .then(res => res.data);
    }
    delete(idCategorie) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/categorie-chambre/'
        return axios.patch(url+idCategorie)
                    .then(res => res.data)
    }
    

}