import axios from 'axios';

export class DirectionService {
    
    getAll() {
        
        return axios.get('http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/direction')
                    .then(res => res.data);
    }
    create(newDirection) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/direction'
        return axios.post(url,newDirection)
                    .then(res => res.data);
    }
    update(idDirection ,newDirection) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/direction/'
        return axios.put(url+idDirection, newDirection)
                    .then(res => res.data);
    }
    delete(idDirection) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/direction/'
        return axios.patch(url+idDirection)
                    .then(res => res.data)
    }
    get(idDirection) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/direction/'
        return axios.patch(url+idDirection)
                    .then(res => res.data)
    }

}