import axios from 'axios';
import { AdresseIP } from './AdresseIp';

export class ConnexionService {

    constructor(){
        this.adresse = new AdresseIP()
    }
    
    loginUser(newUser) {
        const url = this.adresse.getAdresseIP() +'/service/api/hebergement-cenou/campus-auth/signin'
        return axios.post(url,newUser)
                    .then(res => res);
    }

}