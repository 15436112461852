import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import { SessionService } from '../service/SessionService';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import { DeliberationService } from '../service/DeliberationService';


export class Deliberation extends Component {
    emptyDeliberation = {
        idSessionCenou: null,
        etatSession : "",
        idDirection : null,
        libelleDirection : "",
        statutSession : "",
        dateOuverture : null,
        dateFermeture : null
    }
    constructor() {
        super()
        this.state = {
            deliberation : this.emptyDeliberation,
            session : {},
            idDirection : null,
            listeAdmisEtranger : [],
            listeAdmisPupille : [],
            listeAdmisNationaux : [],
            submited : false,
            loading : false,
            verifeDeliberation : false
        }
        this.sessionService = new SessionService();
        this.deliberationService = new DeliberationService();
        this.exportCSV = this.exportCSV.bind(this);

        this.cols = [
            { field: 'ine', header: 'INE' },
            { field: 'nomEtudiant', header: 'Nom' },
            { field: 'prenomEtudiant', header: 'Prénom' },
            { field: 'sexeEtudiant', header: 'Sexe' },
            { field: 'dateNaissance', header: 'Date de naiss.' },
            { field: 'lieuNaissance', header: 'Lieu de naiss.' },
            { field: 'villeOrientation', header: 'Ville orientation' },
            { field: 'moyenne', header: 'Moyenne obtenue' },
        ];

        this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    }
    componentDidMount = () => {
        const data = localStorage.getItem('user')
        const user = JSON.parse(data)
        let role = user.roles[0]
        const idDirection = user.idDirection
        this.setState({idDirection : idDirection})
         if(role === "ROLE_HEBERGEMENT_GESTIONNAIRE_DR") {
            this.setState({roleDelibere : true})
            this.sessionService.getSessionDirection(idDirection).then(data => {
            
                let sessionOuverte = data.filter(session => {
                    if(session.statutSession === "OUVERT") return session
                })
                let sessionDr = sessionOuverte[0]
                if(sessionDr.etatSession === "DELIBERATION") this.setState({verifeDeliberation : true})
                this.setState({session : sessionDr})
                
            })
            .catch(error => {
                console.log(error)
            })
         }
        
        
    }
    exportCSV() {
        this.dt.exportCSV();
    }
    paginationPage = (doc) => {
        const pageCount = doc.getNumberOfPages()
        for(let i = 1; i<=pageCount; i++){
            doc.setPage(i)
            doc.text('' + String(i) + '/' + String(pageCount), 290,200,null,null,"right")
        }

    }
    
    exportPdfPupille = () => {
        let title = "Admissibles pupilles de la nation 2021 - 2022_"+this.state.idDirection
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default("l");
                doc.text(title, 10, 10)
                doc.autoTable(this.exportColumns, this.state.listeAdmisPupille);
                this.paginationPage(doc)
                doc.save(title+'.pdf');
                
            })
        })
    }
    exportPdfEtranger = () => {
        let title = "Admissibles étrangers 2021 - 2022_"+this.state.idDirection
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default("l");
                doc.text(title, 10, 10)
                doc.autoTable(this.exportColumns, this.state.listeAdmisEtranger);
                this.paginationPage(doc)
                doc.save(title+'.pdf');
                
            })
        })
    }
    exportPdfNationaux = () => {
        let title = "Admissibles nationaux 2021 - 2022_"+this.state.idDirection
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default("l");
                doc.text(title, 10, 10)
                doc.autoTable(this.exportColumns, this.state.listeAdmisNationaux);
                this.paginationPage(doc)
                doc.save(title+'.pdf');
                
            })
        })
    }
    deliberation = () => {
        const data = localStorage.getItem('user')
        const user = JSON.parse(data)
        const idDirection = user.idDirection
        let session = this.state.session
            
                this.setState({loading : true})
                
                this.deliberationService.deliberation(session.idSessionCenou, idDirection).then(res => {
                    console.log(res.data)
                    let listeAdmisEtranger = res.data.listeAdmisEtrangers
                         let listeAdmisPupille = res.data.listeAdmisPupilles
                         let listeAdmisNationaux = res.data.listeAdmisNationaux
                        if(listeAdmisNationaux){
                            listeAdmisNationaux.sort(function(a, b){
                                if(a.moyenne > b.moyenne)
                                    return -1;
                                if(a.moyenne < b.moyenne)
                                    return 1;
                                return 0;
                            });
                        }
                        if(listeAdmisPupille){
                            listeAdmisPupille.sort(function(a, b){
                                if(a.moyenne > b.moyenne)
                                    return -1;
                                if(a.moyenne < b.moyenne)
                                    return 1;
                                return 0;
                            });
                        }
                        if(listeAdmisEtranger){
                            listeAdmisEtranger.sort(function(a, b){
                                if(a.moyenne > b.moyenne)
                                    return -1;
                                if(a.moyenne < b.moyenne)
                                    return 1;
                                return 0;
                            });
                        }
                        
                        console.log(listeAdmisNationaux)
                        console.log(listeAdmisPupille)
                        console.log(listeAdmisEtranger)
                        
                        this.setState({
                            listeAdmisEtranger : listeAdmisEtranger,
                            listeAdmisPupille : listeAdmisPupille,
                            listeAdmisNationaux : listeAdmisNationaux,
                            loading : false
                        })
                })
                .catch(error => {
                    console.log(error)
                    this.setState({loading : false})
                })
    }
    loadingSpinner = ( type, color) => (
        <ReactLoading type={type} color={color} height={200} width={50} />
    )
    render() {
        if(this.state.listeAdmisEtranger) {
            this.state.listeAdmisEtranger.map(admisE => {
                admisE.moyenne = Math.round(admisE.moyenne * 100) / 100 
            })
        }
        if(this.state.listeAdmisPupille){
            this.state.listeAdmisPupille.map(admisP => {
                admisP.moyenne = Math.round(admisP.moyenne * 100) / 100 
            })
        }
        if(this.state.listeAdmisNationaux){
            this.state.listeAdmisNationaux.map(admisN => {
                admisN.moyenne = Math.round(admisN.moyenne * 100) / 100 
            })
        }
        
        const header = <div style={{textAlign:'left'}}><Button type="button" icon="pi pi-external-link" label="Export" onClick={this.exportCSV}></Button></div>;
        return (
            <div>
                {this.state.roleDelibere ?
                    this.state.verifeDeliberation ?
                <>
                <div className="deliberation" style={{textAlign : "center"}}>
                    <Button onClick={this.deliberation} label="Deliberation" className="p-button-success"  />
                </div>
                {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.loadingSpinner("spin","black")}
                            </div>
                        </div>
                        :
                <div>
                <div className="p-d-flex p-ai-center export-buttons" style={{marginLeft : 10}}>
                    <Button title="Exporter en pdf" type="button" icon="pi pi-file-pdf" onClick={this.exportPdfEtranger} style={{marginLeft : 10}} className="p-button-warning p-mr-2" data-pr-tooltip="PDF"/>
                </div>
                <div className="admisEtranger" style={{marginTop : 10}}>
                    <DataTable  ref={(el) => this.dt = el} value={this.state.listeAdmisEtranger} selectionMode="single" header={"Liste des admissibles etrangers : "+this.state.listeAdmisEtranger.length} paginator={true} rows={10}
                                globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                        <Column field="ine" header="INE" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher ine"/>
                        <Column field="nomEtudiant" header="Nom" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher nom"/>
                        <Column field="prenomEtudiant" header="Prénom" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher prénom"/>
                        <Column field="sexeEtudiant" header="Sexe" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher sexe"/>
                        <Column field="telEtudiant" header="Téléphone" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher telephone"/>
                        <Column field="villeOrientation" header="Ville" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher ville"/>
                        <Column field="moyenne" header="Moyenne" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher Moyenne"/>
                    </DataTable>
                </div>
                <div className="p-d-flex p-ai-center export-buttons" style={{marginLeft : 10, marginTop : 30}}>
                    <Button title="Exporter en pdf" type="button" icon="pi pi-file-pdf" onClick={this.exportPdfPupille} style={{marginLeft : 10}} className="p-button-warning p-mr-2" data-pr-tooltip="PDF"/>
                </div>
                <div className="admisPupille" style={{marginTop : 10}}>
                    <DataTable  ref={(el) => this.dt = el} value={this.state.listeAdmisPupille} selectionMode="single" header={"Liste des admissibles des pupilles de la nation : "+this.state.listeAdmisPupille.length} paginator={true} rows={10}
                                globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                        <Column field="ine" header="INE" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher ine"/>
                        <Column field="nomEtudiant" header="Nom" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher nom"/>
                        <Column field="prenomEtudiant" header="Prénom" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher prénom"/>
                        <Column field="sexeEtudiant" header="Sexe" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher sexe"/>
                        <Column field="telEtudiant" header="Téléphone" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher telephone"/>
                        <Column field="villeOrientation" header="Ville" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher ville"/>
                        <Column field="moyenne" header="Moyenne" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher Moyenne"/>
                        <Column field="rang" header="Rang" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher Rang"/>
                    </DataTable>
                </div>
                <div className="p-d-flex p-ai-center export-buttons" style={{marginLeft : 10, marginTop : 30}}>
                    <Button title="Exporter en pdf" type="button" icon="pi pi-file-pdf" onClick={this.exportPdfNationaux} style={{marginLeft : 10}} className="p-button-warning p-mr-2" data-pr-tooltip="PDF"/>
                </div>
                <div className="admisNationaux" style={{marginTop : 10}}>
                    <DataTable  ref={(el) => this.dt = el} value={this.state.listeAdmisNationaux} selectionMode="single" header={"Liste des admissibles nationaux : "+this.state.listeAdmisNationaux.length} paginator={true} rows={10}
                                globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                        <Column field="ine" header="INE" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher ine"/>
                        <Column field="nomEtudiant" header="Nom" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher nom"/>
                        <Column field="prenomEtudiant" header="Prénom" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher prénom"/>
                        <Column field="sexeEtudiant" header="Sexe" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher sexe"/>
                        <Column field="telEtudiant" header="Téléphone" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher telephone"/>
                        <Column field="villeOrientation" header="Ville" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher ville"/>
                        <Column field="moyenne" header="Moyenne" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher Moyenne"/>
                    </DataTable>
                </div>
                </div>
                
                }
                </>
                :
                <div className="p-lg-12 p-grid">
                    <div className="p-lg-12 contenair " id="slideshow" style={{textAlign : ""}}>
                        <div id="slider" style={{backgroundColor : "#d1ecf1", padding : 5}}>
                            <p style={{fontWeight : "bold", fontStyle : "italic", fontSize : 16, color : "red", textAlign : "center", marginTop : 10}}>
                                Session de deliberation close
                            </p>
                        </div>
                    </div>
                </div>
                :
                <div className="p-lg-12 p-grid">
                    <div className="p-lg-12 contenair " id="slideshow" style={{textAlign : ""}}>
                        <div id="slider" style={{backgroundColor : "#d1ecf1", padding : 5}}>
                            <p style={{fontWeight : "bold", fontStyle : "italic", fontSize : 16, color : "red", textAlign : "center", marginTop : 10}}>
                                La delibération est reservée aux Directions régionales !
                            </p>
                        </div>
                    </div>
                </div>
                }
            </div>
            
        )
    }
}

export default Deliberation
