import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import {Button} from 'primereact/button';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import ReactLoading from 'react-loading';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { DirectionService } from '../service/DirectionService';
import { DossierService } from '../service/DossierService';

export class DepotDirection extends Component {
    state = {
        idDirection : 0,
        etatSession : "",
        statutSession : "",
        listeDepot : [],
        listeDirection : [],
        listeEtatSession : [],
        listeStatutSession : [],
        listeExcel : [],
        titleFichier : "",
        loading : false,
        globalFilter: null,
        verifeRole : false
    }
    constructor(props){
        super(props)
        this.directionService = new DirectionService();
        this.dossierService = new DossierService();

        this.cols = [
            { field: 'ine', header: 'INE étudiant' },
            { field: 'nom', header: 'Nom étudiant' },
            { field: 'prenom', header: 'Prénom(s) étudiant' },
            { field: 'dateNaissance', header: 'Date naiss.' },
            { field: 'numeroRecipisse', header: 'Recipissé' },
            { field: 'dateDepot', header: 'Date de dépôt' },
            { field: 'villeOrientation', header: 'Ville orientation' },
        ];

        this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    }
    componentDidMount = () => {
        const data = localStorage.getItem('user')
        const user = JSON.parse(data)
        let role = user.roles[0]
        if(role === "ROLE_HEBERGEMENT_ADMIN" || role === "ROLE_HEBERGEMENT_GESTIONNAIRE_CENOU" ) {
            this.setState({verifeRole : true})
            this.directionService.getAll().then(data => {
                this.setState({listeDirection : data})
            })
            
        }
        else if(role === "ROLE_HEBERGEMENT_GESTIONNAIRE_DR") {
            this.setState({verifeRole : false, idDirection : user.idDirection})
        }
        const listeEtatSession = [
            {idEtatSession : 1, etatSession : "INSCRIPTION"},
            {idEtatSession : 2, etatSession : "ANALYSE"},
            {idEtatSession : 3, etatSession : "DELIBERATION"},
        ]
        
        this.setState({listeEtatSession : listeEtatSession})
        
    }
    
    onIdDirectionChange = (e) => {
        const val = parseInt(e.target.value)
        this.setState({idDirection : val})   
    }
    onEtatSessionChange = (e) => {
        const val = e.target.value
        this.setState({etatSession : val})   
    }
    /*onStatutSessionChange = (e) => {
        const val = e.target.value
        this.setState({statutSession : val})
    }*/
    getListeEtudiant = () => {
        
        let idDirection = this.state.idDirection
        let etatSession = this.state.etatSession
        let title = "Fichier_excel_cenou"+this.state.idDirection+this.state.etatSession
        
        this.setState({loading : true})
        this.dossierService.getListeDossierEtudiant(idDirection,etatSession).then(data => {
            let donneeExcel = data.map(d => ({'Ine étudiant' :  d.ine, 'Nom' : d.nomEtudiant, 'Prenom' : d.prenomEtudiant, 'sexe' : d.sexeEtudiant, 'Date naiss.' : d.dateNaissance, 'Recipissé' : d.referenceDossier, 'Date depôt' : d.dateDepot, 'ville orientation' : d.villeOrientation}))
            this.setState({listeDepot : data, listeExcel : donneeExcel, titleFichier : title, loading : false})
        })
        .catch(error => {
            console.log(error);
            this.setState({loading : false})
            this.notify();
        })
    }

    loadingSpinner = ( type, color) => (
        <ReactLoading type={type} color={color} height={200} width={50} />
    )
    notify = () => toast.error("Echec d'envoie des données !", {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.state.listeExcel);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, this.state.titleFichier);   
        });
    }
    saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    actionBodyTemplate = (rowData) => {
       return (
           <React.Fragment>
              <Link to={"/consult-dossier-etudiant/"+rowData.idDossier+"/"+rowData.ine} style={{marginRight: '.5em'}}><Button icon="pi pi-eye" className="p-button-success"  /></Link> 
           </React.Fragment>
       );
   }
   
    
    render() {
       //console.log(this.state.idDirection)
       //console.log(this.state.idSessionCenou)
       let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="p-grid p-col-12" style={{marginTop : "10px"}}>
                <ToastContainer
                            position="top-right"
                            autoClose={10000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                    <ToastContainer />
                <div className="table-header p-col-5">
                    <span className="p-input-icon-left">
                        <InputText className="p-col-6" type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                    </span>
                    
                </div>
                <div className="table-header p-col-6">
                    <span className="p-input-icon-left">
                        <label className="p-col-12">Nombre total de dossiers : {this.state.listeDepot.length}</label>
                    </span>
                    
                </div>
            </div>
        );
        return (
            <div className="p-grid">
                
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-field p-grid ">
                            <div className="p-col-2"></div>
                            <div className="p-col-10 p-grid">
                                {this.state.verifeRole ?
                                <>
                                <div className="p-col-12 p-sm-3 p-md-3">
                                    <Dropdown optionLabel="libelleDirection" optionValue="idDirection" value={this.state.idDirection} options={this.state.listeDirection} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner une direction"
                                    onChange={this.onIdDirectionChange} 

                                    />
                                    {this.state.submitted && !this.state.idDirection && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                </div>
                                <div className="p-col-12 p-sm-3 p-md-3" >
                                    <Dropdown optionLabel="etatSession"  optionValue="etatSession" value={this.state.etatSession} options={this.state.listeEtatSession} onChange={this.onEtatSessionChange} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner etat session"/>
                                    {this.state.submitted && !this.state.etatSession && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                </div>
                                </>
                                :
                                <div className="p-col-12 p-sm-3 p-md-3" >
                                    <Dropdown optionLabel="etatSession"  optionValue="etatSession" value={this.state.etatSession} options={this.state.listeEtatSession} onChange={this.onEtatSessionChange} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner etat session"/>
                                    {this.state.submitted && !this.state.etatSession && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                </div>
                                }
                                <div className="p-col-12 p-sm-3 p-md-3">
                                    <Button  type="button" onClick={this.getListeEtudiant} label="Afficher" icon="pi pi-check"/>
                                </div>
                            </div>
                        </div>

                        {header}
                        {this.state.loading ? 
                        <div className="p-grid p-col-12">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.loadingSpinner("spin","black")}
                            </div>
                        </div>
                        :
                        <>
                        <div className="p-d-flex p-ai-center export-buttons" style={{marginLeft : 10}}>
                            <Button type="button" icon="pi pi-file-excel" onClick={this.exportExcel} className="p-button-success p-mr-2" data-pr-tooltip="XLS" />
                        </div>
                        <DataTable style={{marginTop : 10}}  ref={(el) => this.dt = el} value={this.state.listeDepot} selectionMode="single" header="Liste des dossiers" paginator={true} rows={10}
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="ine" header="INE" sortable={true} filter={true} filterPlaceholder="rechercher ine"/>
                            <Column field="nomEtudiant" header="Nom" sortable={true} filter={true} filterPlaceholder="rechercher nom"/>
                            <Column field="prenomEtudiant" header="Prénom" sortable={true} filter={true} filterPlaceholder="rechercher prénom"/>
                            <Column field="sexeEtudiant" header="Sexe" sortable={true} filter={true} filterPlaceholder="rechercher sexe"/>
                            <Column field="dateNaissance" header="Date naiss." sortable={true} filter={true} filterPlaceholder="rechercher date naiss."/>
                            <Column field="referenceDossier" header="Recipissé" sortable={true} filter={true} filterPlaceholder="rechercher recipissé"/>
                            <Column field="dateDepot" header="Date depôt" sortable={true} filter={true} filterPlaceholder="rechercher date de depôt"/>
                            <Column field="statutDossier" header="Statut dossier" sortable={true} filter={true} filterPlaceholder="rechercher statut dossier"/>
                            <Column field="villeOrientation" header="Ville" sortable={true} filter={true} filterPlaceholder="rechercher Ville"/>
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        </>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default DepotDirection
