import axios from 'axios';

export class BatimentService {
    
    getAll() {
        
        return axios.get('http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/batiment')
                    .then(res => res.data);
    }
    create(newBatiment) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/batiment'
        return axios.post(url,newBatiment)
                    .then(res => res.data);
    }
    update(idBatiment ,newBatiment) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/batiment/'
        return axios.put(url+idBatiment, newBatiment)
                    .then(res => res.data);
    }
    delete(idBatiment) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/batiment/'
        return axios.patch(url+idBatiment)
                    .then(res => res.data)
    }
    get(idCite) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/batiment/?idCite='+idCite
        return axios.get(url)
                    .then(res => res.data)
    }

}