import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { CiteService } from '../../service/CiteService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import { CategorieChambreService } from '../../service/CategorieChambreService';



export class CategorieChambre extends Component {
    emptyCategorieChambre = {
        idCategorieChambre : 0,
        idCite : 0,
        libelleCategorieChambre : "",
        montantChambre : ""
    };
    constructor() {
        super();
        this.state = {
            
            listeCategorieChambre : [],
            listeCite : [],
            categorieChambreDialog: false,
            deleteCategorieDialog: false,
            categorieChambre: this.emptyCategorieChambre,
            submitted: false,
            redirect : false,
            loading : false,
            globalFilter: null
        }

        this.categorieChambreService = new CategorieChambreService();
        this.citeService = new CiteService();

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveCategorieChambre = this.saveCategorieChambre.bind(this);
        this.editCategorieChambre = this.editCategorieChambre.bind(this);
        this.confirmDeleteCategorieChambre = this.confirmDeleteCategorieChambre.bind(this);
        this.deleteCategorieChambre = this.deleteCategorieChambre.bind(this);
        this.onLibelleCategorieChambreChange = this.onLibelleCategorieChambreChange.bind(this);
        this.onMontantChambreChange = this.onMontantChambreChange.bind(this);
        this.onNbPlaceChange = this.onNbPlaceChange.bind(this);
        this.hideDeleteCategorieChambreDialog = this.hideDeleteCategorieChambreDialog.bind(this);
    }
    componentDidMount() {
        this.setState({loading : true})
        this.categorieChambreService.getAll().then(data => 
            this.setState({listeCategorieChambre: data, loading : false})
            )
            .catch(error => {
                this.notify2()  
                console.log(error)
                this.setState({loading : false})
            })
        this.citeService.getAll().then(data => {
            this.setState({listeCite: data})
        })
        
    }
    
    openNew() {
        this.setState({
            categorieChambre: this.emptyCategorieChambre,
            submitted: false,
            categorieChambreDialog: true
        });
    }
    hideDialog() {
        this.setState({
            submitted: false,
            categorieChambreDialog: false
        });
    }
    hideDeleteCategorieChambreDialog() {
        this.setState({ deleteCategorieChambreDialog: false });
    }
    saveCategorieChambre() {
        let state = { submitted: true };

        if (this.state.categorieChambre.idCite && this.state.categorieChambre.libelleCategorieChambre.trim() && this.state.categorieChambre.montantChambre ) {

            const newCategorieChambre = {
                idCategorieChambre : parseInt(this.state.categorieChambre.idCategorieChambre) ,
                idCite : parseInt(this.state.categorieChambre.idCite) ,
                libelleCategorieChambre : this.state.categorieChambre.libelleCategorieChambre,
                montantChambre : parseInt(this.state.categorieChambre.montantChambre) ,
            }
            console.log(newCategorieChambre)
            if (this.state.categorieChambre.idCategorieChambre) {
                this.categorieChambreService.update(parseInt(this.state.categorieChambre.idCategorieChambre), newCategorieChambre)
                                .then(data => {
                                    this.notify();
                                    console.log(data)
                                    this.setState({redirect : true})
                                })
                                .catch(error => {
                                    this.notify2()
                                    console.log(error);
                                })
            }
            else {
                this.categorieChambreService.create(newCategorieChambre).then(data => {
                    this.notify();
                    this.setState({redirect : true})
                })
                .catch(error => {
                    this.notify2()
                    console.log(error);
                })
                
            }

            state = {
                ...state,
                categorieChambreDialog: false,
                categorieChambre: this.emptyCategorieChambre
            };
        }

        this.setState(state);
    }
    editCategorieChambre(categorieChambre) {
        this.setState({
            categorieChambre,
            categorieChambreDialog: true
        });
    }
    confirmDeleteCategorieChambre(categorieChambre) {
        this.setState({
            categorieChambre,
            deleteCategorieChambreDialog: true
        });
    }
    deleteCategorieChambre(idCategorieChambre) {
        this.categorieChambreService.delete(parseInt(idCategorieChambre)).then(data => {
            this.notify();
            this.setState({
                deleteCategorieChambreDialog: false,
                categorieChambre: this.emptyCategorieChambre,
                redirect : true
            });
        })
        
        

    }
    
    onLibelleCategorieChambreChange(e, libelleCategorieChambre) {
        const val = (e.target && e.target.value) || '';
        let categorieChambre = this.state.categorieChambre;
        categorieChambre[`${libelleCategorieChambre}`] = val;

        this.setState({categorieChambre });
    }
    onIdCiteChange(e, idCite) {
        const val = (e.target && e.target.value) || '';
        let categorieChambre = this.state.categorieChambre;
        categorieChambre[`${idCite}`] = val;

        this.setState({ categorieChambre });
    }
    onMontantChambreChange(e, montantChambre) {
        const val = (e.target && e.target.value) || '';
        let categorieChambre = this.state.categorieChambre;
        categorieChambre[`${montantChambre}`] = val;

        this.setState({ categorieChambre });
    }
    onNbPlaceChange(e, nbPlace) {
        const val = (e.target && e.target.value) || '';
        let categorieChambre = this.state.categorieChambre;
        categorieChambre[`${nbPlace}`] = val;

        this.setState({ categorieChambre });
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editCategorieChambre(rowData)} style={{marginRight: '.5em'}}/>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteCategorieChambre(rowData)} />
            </React.Fragment>
        );
    }
    notify = () => toast.success('Opération réuçit!', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    notify2 = () => toast.error("Echec de l'opération !", {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
    loadingSpinner = ( type, color) => (
        <ReactLoading type={type} color={color} height={200} width={50} />
    )
    render() {
        if(this.state.redirect) {
            //window.location.reload(false);
        }
        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        const categorieChambreDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveCategorieChambre} />
            </React.Fragment>
        );
        const deleteCategorieChambreDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteCategorieChambreDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteCategorieChambre(this.state.categorieChambre.idCategorieChambre) } />
            </React.Fragment>
        );
        
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-2">
                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                        </div>
                        <div className="p-col-2">
                            <Button label="Rafraichir" icon="pi pi-refresh" className="" onClick={()=>window.location.reload(false)} />
                        </div>
                    </div>
                    
    
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.loadingSpinner("spin","black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeCategorieChambre} selectionMode="single" header="Liste des catégorie des chambre" paginator={true} rows={5}
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="libelleCategorieChambre" header="Catégorie chambre" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher catégorie chambre"/>
                            <Column field="montantChambre" header="montant" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher montant"/>
                            <Column field="nomCite" header="Cité" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher cité"/>
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        }
                    </div>
                </div>

                <Dialog visible={this.state.categorieChambreDialog} style={{ width: '450px' }} header="Informations cité" modal className="p-fluid" footer={categorieChambreDialogFooter} onHide={this.hideDialog}>
                    <div className="p-field">
                        <label htmlFor="nomCite">Cité</label>
                        <Dropdown optionLabel="nomCite" optionValue="idCite" value={this.state.categorieChambre.idCite} options={this.state.listeCite} onChange={(e) => { this.onIdCiteChange(e, 'idCite') }}  placeholder="Selectionner une cité"/>
                    </div>
                    <div className="p-field">
                        <label htmlFor="libelleCategorieChambre">Catégorie chambre</label>
                        <InputText id="libelleCategorieChambre" value={this.state.categorieChambre.libelleCategorieChambre}  onChange={(e) => this.onLibelleCategorieChambreChange(e, 'libelleCategorieChambre')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                        {this.state.submitted && !this.state.categorieChambre.libelleCategorieChambre && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="montantChambre">Montant chambre</label>
                        <InputText id="montantChambre" value={this.state.categorieChambre.montantChambre}  onChange={(e) => this.onMontantChambreChange(e, 'montantChambre')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                        {this.state.submitted && !this.state.categorieChambre.montantChambre && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                </Dialog>
                <Dialog visible={this.state.deleteCategorieChambreDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteCategorieChambreDialogFooter} onHide={this.hideDeleteCategorieChambreDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.categorieChambre && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
            </div> 
        )
    }
}

export default CategorieChambre
