import React, { Component } from 'react'

export class Deconnexion extends Component {

    componentDidMount = () => {
		localStorage.clear()
		window.location.assign("/")
	}
    render() {
        return (
            <div>
                
            </div>
        )
    }
}

export default Deconnexion
