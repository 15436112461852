import axios from 'axios';
import { AdresseIP } from './AdresseIp';

export class AnalyseService {

    constructor(){
        this.adresse = new AdresseIP()
    }
    analyseDossier(newAnalyse) {
        const url = this.adresse.getAdresseIP() +"/service/api/hebergement-cenou/campus-faso/analyse-dossier"
        return axios.patch(url,newAnalyse)
                    .then(res => res);
    }
}