import axios from 'axios';

export class NiveauService {
    
    getAll() {
        
        return axios.get('http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/niveau')
                    .then(res => res.data);
    }
    create(newNiveau) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/niveau'
        return axios.post(url,newNiveau)
                    .then(res => res.data);
    }
    update(idNiveau ,newNiveau) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/niveau/'
        return axios.put(url+idNiveau, newNiveau)
                    .then(res => res.data);
    }
    delete(idNiveau) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/niveau/'
        return axios.patch(url+idNiveau)
                    .then(res => res.data)
    }
    get(idBatiment) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/niveau/?idBatiment='+idBatiment
        return axios.get(url)
                    .then(res => res.data)
    }

}