import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { BatimentService } from '../../service/BatimentService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import { CiteService } from '../../service/CiteService';
import { NiveauService } from '../../service/NiveauService';
import { CouloirService } from '../../service/CouloirService';
import { CategorieChambreService } from '../../service/CategorieChambreService';
import { ChambreService } from '../../service/ChambreService';
import { LitService } from '../../service/LitService';


export class Lit extends Component {

    emptyLit = {
        idLit : 0,
        idChambre : 0,
        idCouloir : 0,
        idNiveau : 0,
        idBatiment  : 0,
        idCite : 0,
        codeLit : "",
        libelleLit : ""
    };
    constructor() {
        super();
        this.state = {
            listeLit : [],
            listeCite : [],
            listeBatiment : [],
            listeNiveau : [],
            listeCouloir : [],
            listeChambre : [],
            litDialog: false,
            deleteLitDialog: false,
            lit: this.emptyLit,
            submitted: false,
            redirect : false,
            loading : false,
            globalFilter: null
        }

        this.citeService = new CiteService();
        this.batimentService = new BatimentService();
        this.niveauService = new NiveauService();
        this.couloirService = new CouloirService();
        this.chambreService = new ChambreService();
        this.litService = new LitService();

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveLit = this.saveLit.bind(this);
        this.editLit = this.editLit.bind(this);
        this.confirmDeleteLit = this.confirmDeleteLit.bind(this);
        this.deleteLit = this.deleteLit.bind(this);
        this.onLibelleLitChange = this.onLibelleLitChange.bind(this);
        this.onCodeLitChange = this.onCodeLitChange.bind(this);
        this.onIdCiteChange = this.onIdCiteChange.bind(this);
        this.onIdBatimentChange = this.onIdBatimentChange.bind(this);
        this.onIdNiveauChange = this.onIdNiveauChange.bind(this);
        this.onIdCouloirChange = this.onIdCouloirChange.bind(this);
        this.onIdChambreChange = this.onIdChambreChange.bind(this);
        this.hideDeleteLitDialog = this.hideDeleteLitDialog.bind(this);
    }
    componentDidMount() {
        this.setState({loading : true})
        this.litService.getAll().then(data => 
            this.setState({listeLit: data, loading : false})
            )
            .catch(error => {
                this.notify2()  
                console.log(error)
                this.setState({loading : false})
            })

        this.citeService.getAll().then(data => {
            //console.log(data)
            this.setState({listeCite: data})
        })
            .catch(error => {this.notify2() ; console.log(error)})
        
    }
    
    openNew() {
        this.setState({
            lit: this.emptyLit,
            submitted: false,
            litDialog: true
        });
    }
    hideDialog() {
        this.setState({
            submitted: false,
            litDialog: false
        });
    }
    hideDeleteLitDialog() {
        this.setState({ deleteLitDialog: false });
    }
    saveLit() {
        let state = { submitted: true };

        if (this.state.lit.idChambre && this.state.lit.libelleLit.trim()) {
            //alert("yo !")
            const newLit = {
                idLit : parseInt(this.state.lit.idLit) ,
                idChambre : parseInt(this.state.lit.idChambre) ,
                libelleLit : this.state.lit.libelleLit,
                codeLit : this.state.lit.codeLit
            }
            console.log(newLit)
            if (this.state.lit.idLit) {
                this.litService.update(parseInt(this.state.lit.idLit
                    ), newLit)
                                .then(data => {
                                    this.notify();
                                    //console.log(data)
                                    this.setState({redirect : true})
                                })
                                .catch(error => {
                                    this.notify2()
                                    console.log(error);
                                })
            }
            else {
                this.litService.create(newLit).then(data => {
                    this.notify();
                    this.setState({redirect : true})
                })
                .catch(error => {
                    this.notify2()
                    console.log(error);
                })
                
            }

            state = {
                ...state,
                litDialog: false,
                lit: this.emptyLit
            };
        }

        this.setState(state);
    }
    editLit(lit) {
        this.loadBatiment(lit.idCite)
        this.loadNiveau(lit.idBatiment)
        this.loadCouloir(lit.idNiveau)
        this.loadChambre(lit.idCouloir)
        this.setState({
            lit,
            litDialog: true
        });
    }
    confirmDeleteLit(lit) {
        this.setState({
            lit,
            deleteLitDialog: true
        });
    }
    deleteLit(idLit) {
        this.litService.delete(parseInt(idLit)).then(data => {
            this.notify();
            this.setState({
                deleteLitDialog: false,
                lit: this.emptyLit,
                redirect : true
            });
        })
    }
    
    onLibelleLitChange(e, libelleLit) {
        const val = (e.target && e.target.value) || '';
        let lit = this.state.lit;
        lit[`${libelleLit}`] = val;

        this.setState({lit });
    }
    onCodeLitChange(e, codeLit) {
        const val = (e.target && e.target.value) || '';
        let lit = this.state.lit;
        lit[`${codeLit}`] = val;

        this.setState({ lit });
    }
    onIdCiteChange(e, idCite) {
        const val = (e.target && e.target.value) || '';
        let lit = this.state.lit;
        lit[`${idCite}`] = val;

        this.setState({ lit });
    }
    onIdBatimentChange(e, idBatiment) {
        const val = (e.target && e.target.value) || '';
        let lit = this.state.lit;
        lit[`${idBatiment}`] = val;

        this.setState({ lit });
    }
    onIdNiveauChange(e, idNiveau) {
        const val = (e.target && e.target.value) || '';
        let lit = this.state.lit;
        lit[`${idNiveau}`] = val;
        this.setState({ lit });
    }
    onIdCouloirChange(e, idCouloir) {
        const val = (e.target && e.target.value) || '';
        let lit = this.state.lit;
        lit[`${idCouloir}`] = val;
        this.setState({ lit });
    }
    onIdChambreChange(e, idChambre) {
        const val = (e.target && e.target.value) || '';
        let lit = this.state.lit;
        lit[`${idChambre}`] = val;
        this.setState({ lit });
    }
    
    loadBatiment = (idCite) => {
        this.batimentService.get(idCite).then(data => {
            this.setState({listeBatiment : data})
        })
    }
    loadNiveau = (idBatiment) => {
        this.niveauService.get(idBatiment).then(data => {
            console.log(data)
            this.setState({listeNiveau : data})
        })
    }
    loadCouloir = (idNiveau) => {
        this.couloirService.get(idNiveau).then(data => {
            console.log(data)
            this.setState({listeCouloir : data})
        })
    }
    loadChambre = (idChambre) => {
        this.chambreService.get(idChambre).then(data => {
            console.log(data)
            this.setState({listeChambre : data})
        })
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editLit(rowData)} style={{marginRight: '.5em'}}/>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteLit(rowData)} />
            </React.Fragment>
        );
    }
    notify = () => toast.success('Opération réuçit!', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    notify2 = () => toast.error("Echec de l'opération !", {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
    loadingSpinner = ( type, color) => (
        <ReactLoading type={type} color={color} height={200} width={50} />
    )
    render() {
        //console.log(this.state.listeLit)
        if(this.state.redirect) {
            //window.location.reload(false);
        }
        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        const litDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveLit} />
            </React.Fragment>
        );
        const deleteLitDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteLitDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteLit(this.state.lit.idLit) } />
            </React.Fragment>
        );
        
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-2">
                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                        </div>
                        <div className="p-col-2">
                            <Button label="Rafraichir" icon="pi pi-refresh" className="" onClick={()=>window.location.reload(false)} />
                        </div>
                    </div>
    
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.loadingSpinner("spin","black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeLit} selectionMode="single" header="Liste des lits" paginator={true} rows={5}
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="libelleLit" header="Lit" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher lit"/>       
                            <Column field="libelleChambre" header="Chambre" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher chambre"/> 
                            <Column field="libelleCouloir" header="Couloir" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher couloir"/>       
                            <Column field="libelleNiveau" header="Niveau" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher niveau"/>
                            <Column field="libelleBatiment" header="Bâtiment" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher bâtiment"/>
                            <Column field="nomCite" header="Cité" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher cité"/>
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        }
                    </div>
                </div>

                <Dialog visible={this.state.litDialog} style={{ width: '450px' }} header="Informations lit" modal className="p-fluid" footer={litDialogFooter} onHide={this.hideDialog}>
                    <div className="p-field">
                        <label htmlFor="nomCite">Cité</label>
                        <Dropdown optionLabel="nomCite" optionValue="idCite" value={this.state.lit.idCite} options={this.state.listeCite} onChange={(e) => {this.loadBatiment(e.value); this.onIdCiteChange(e, 'idCite') }}  placeholder="Selectionner une cité"/>
                    </div>
                    <div className="p-field">
                        <label htmlFor="libelleBatiment">Nom du Bâtiment</label>
                        <Dropdown optionLabel="libelleBatiment" optionValue="idBatiment" value={this.state.lit.idBatiment} options={this.state.listeBatiment} onChange={(e) => {this.loadNiveau(e.value); this.onIdBatimentChange(e, 'idBatiment')}} autoFocus  placeholder="Selectionner un bâtiment"/>
                    </div>
                    <div className="p-field">
                        <label htmlFor="libelleNiveau">Niveau</label>
                        <Dropdown optionLabel="libelleNiveau" optionValue="idNiveau" value={this.state.lit.idNiveau} options={this.state.listeNiveau} onChange={(e) => { this.loadCouloir(e.value); this.onIdNiveauChange(e, 'idNiveau')}} autoFocus placeholder="Selectionner un niveau"/>
                    </div>
                    <div className="p-field">
                        <label htmlFor="libelleCouloir">Couloir</label>
                        <Dropdown optionLabel="libelleCouloir" optionValue="idCouloir" value={this.state.lit.idCouloir} options={this.state.listeCouloir} onChange={(e) => { this.loadChambre(e.value); this.onIdCouloirChange(e, 'idCouloir')}} autoFocus placeholder="Selectionner un couloir"/>
                    </div>
                    <div className="p-field">
                        <label htmlFor="libelleCouloir">Chambre</label>
                        <Dropdown optionLabel="libelleChambre" optionValue="idChambre" value={this.state.lit.idChambre} options={this.state.listeChambre} onChange={(e) => { this.onIdChambreChange(e, 'idChambre')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })}  placeholder="Selectionner une chambre"/>
                        {this.state.submitted && !this.state.lit.idChambre && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="libelleLit">Lit</label>
                        <InputText id="libelleLit" value={this.state.lit.libelleLit}  onChange={(e) => this.onLibelleLitChange(e, 'libelleLit')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })}   />
                        {this.state.submitted && !this.state.lit.libelleLit && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="codeLit">Code lit</label>
                        <InputText id="codeLit" value={this.state.lit.codeLit}  onChange={(e) => this.onCodeLitChange(e, 'codeLit')}   />
                    </div>
                </Dialog>
                <Dialog visible={this.state.deleteLitDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteLitDialogFooter} onHide={this.hideDeleteLitDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.lit && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
            </div> 
        )
    }
}

export default Lit
