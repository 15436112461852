import React, { Component } from 'react'
import ReactLoading from 'react-loading';
import {Button} from 'primereact/button';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'

import { DossierService } from '../service/DossierService';


export class ConsultDossierEtudiant extends Component {

    emptyDossierCritere = {
        idCritere : 0,
        idTypeCritere : 0,
        libelleCritere : "",
        libelleTypeCritere : "",
        nomFichier : "",
        fichier : ""
   }
   constructor(){
       super()
       this.state = {
        listeDossierCritere : new Array(),
        ine : "",
        idDossier : "",
        dossierCritere : this.emptyDossierCritere,
        valeurAnalyse : "",
        file : false,
        fileBase64 : null,
        extension : false,
        longueur : 1200,
        largeur : 700,
        idCritere : null,
        nomFichier : null,
        fichier : null,
        loading : false,
       }

       this.onFileDecode = this.onFileDecode.bind(this);

       this.dossierService = new DossierService();
        
   }

    componentDidMount = () => {
        
        //let ine = "N02770120202"
        //let idDossier = 1668
        let ine = this.props.match.params.ine
        let idDossier = this.props.match.params.idDossier

        this.setState({loading : true, ine : ine, idDossier: idDossier})
        this.dossierService.consulterDossier(ine, idDossier)
        .then(res => { 
            
            this.setState({
                //dossierEtudiant : res.data,
                listeDossierCritere : res.data.listeDossierCritere,
                loading : false
            })
           
         
        })
        .catch(error => {
            console.log(error);
            this.setState({loading : false})
        })
          
    }

    onFileDecode (dossierCritere) { 
        let ine = this.state.ine
        //let fichier = dossierCritere.fichier
        let nomFichier = dossierCritere.nomFichier
        this.dossierService.consulterFichier(ine, nomFichier).then(res => {
            let fichier = res.data
            //console.log(fichier)
            if(fichier){
                let extension = nomFichier.split('.').pop();
                console.log(extension)
                if(extension === "png" || extension === "jpg" || extension === "jpeg" || extension === "gif") 
                    this.setState({fileBase64 : "data:application/octet-stream;base64,"+fichier, fileClick : true, extension : true })
                else {
                    this.setState({fileBase64 : "data:application/pdf;base64,"+fichier, fileClick : true, extension : false })
                }
            }
        })
        /*if(fichier){
            let extension = nomFichier.split('.').pop();
            
            if(extension === "png" || extension === "jpg" || extension === "jpeg" || extension === "gif") 
                this.setState({fileBase64 : "data:application/octet-stream;base64,"+fichier, fileClick : true, extension : true })
            else {
                this.setState({fileBase64 : "data:application/pdf;base64,"+fichier, fileClick : true, extension : false })
            }
            
        }*/
        
    }
    
    loadingSpinner = ( type, color) => (
        <ReactLoading type={type} color={color} height={200} width={50} />
    )
    actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {rowData.fichier ? 
                <Button  type="button" icon="pi pi-eye" className="p-button-succes" onClick={() => this.onFileDecode(rowData)} style={{marginRight: '.5em'}}/>
                :
                ""
                }
            </React.Fragment>
        );
    }
    render() {
        //console.log(this.state.dossierEtudiant)
        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        return (
            <div className=""> 
                {this.state.loading ? 
                        this.loadingSpinner("spin","black")
                        :
                <div className="">
                    
                        <div className="card card-w-title datatable-demo">

                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeDossierCritere} selectionMode="single" header="Liste des critères choisis" paginator={true} rows={100}
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="libelleTypeCritere" header="Type critère" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher identifiant"/>
                            <Column field="libelleCritere" header="Critère" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher nom"/>
                            {/*<Column field="nomFichier" header="Nom du fichier" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher prénom"/>*/}
                            <Column field="valeurAnalyse" header="Analyse" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher email"/>
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                            
                            {/*<table className="table table-striped" >
                                <thead>
                                    <tr>
                                        <th>Type critère</th>
                                        <th>Critère choisi</th>
                                        <th>Nom du fichier</th>
                                        <th>Analyse</th>
                                        <th>Consulter</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.listeDossierCritere.map((dossierCritere,i)=>
                                    dossierCritere.idDossierCritere ?
                                    <tr key={i}>
                                        
                                        <td>{dossierCritere.libelleTypeCritere}</td>
                                        <td>{dossierCritere.libelleCritere}</td>
                                        <td>{dossierCritere.nomFichier}</td>
                                        <td>{dossierCritere.valeurAnalyse}</td>
                                        <td>
                                            {dossierCritere.fichier ? 
                                            <Button icon="pi pi-eye" className="p-button-success"
                                            onClick={ () => {
                                                this.onFileDecode(dossierCritere)
                                            }

                                            }
                                        
                                            />
                                            :
                                            ""
                                        }
                                        </td>
                                
                                    </tr>
                                    :
                                        ""
                                    
                                    )}
                                </tbody>
                            </table> */}
                            
                        </div>
                        <div className="p-col-12 card">
                            {this.state.fileClick ?
                                this.state.extension ?
                                    <img alt="fichier" style={{width : this.state.longueur, height : this.state.largeur}} src={this.state.fileBase64}  />
                                    :
                                    <div className="p-col-12">
                                    <iframe className="p-col-12" src={this.state.fileBase64}
                                        style={{height:this.state.largeur}}>
                                    </iframe>
                                    </div>
                                    :
                                    "je ne comprends pas"
                            }
                        </div>
                        <div className="" style={{marginLeft : 30}}>
                            <Button  type="button"  label="" icon="pi pi-plus"
                                onClick={() => {
                                    let longueur = this.state.longueur + 20
                                    let largeur = this.state.largeur + 20
                                    this.setState({longueur : longueur, largeur : largeur})
                                }}
                            /> <br/>
                            <Button style={{marginTop : 5}}  type="button"  label="" icon="pi pi-minus"
                                onClick={() => {
                                    let longueur = this.state.longueur - 20
                                    let largeur = this.state.largeur - 20
                                    this.setState({longueur : longueur, largeur : largeur})
                                }}
                            />
                        </div>
                        
                    
                </div> 
                }
                
            </div> 
            
        )
    }
}

export default ConsultDossierEtudiant
