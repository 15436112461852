import React, { Component } from 'react'
import { InputText } from 'primereact/inputtext'
import classNames from 'classnames';
import {Button} from 'primereact/button';
import {Checkbox} from 'primereact/checkbox';
import { InscriptionService } from '../service/InscriptionService';
import { Messages } from 'primereact/messages';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';

export class Inscription extends Component {
    emptyEtudiant = {
        idEtudiant : 0,
        ine : '',
        nomEtudiant : '',
        prenomEtudiant : '',
        sexeEtudiant : '',
        universite : '',
        filiere : '',
        niveau : '',
        villeOrientation : '',
        anneeAcademique : 0,
        emailEtudiant : '',
        telEtudiant : '',
        anneeBac : null,
        serieBac : '',
        moyenneBac : null,
        dateNaissance : null,
        lieuNaissance : '',
        idPays : 0,
        libellePays : '',
        typePays : '',
        personnelBesoin : '',
        contact : '',
        engagementStatutReglement : false,
        engagementPersonnelCaution : false,
        declarationSurHonneur : false,
        luApprouve : false,
        lit : null,
        dossier : null,
        listeAffectationCite : null

    }

    constructor(){
        super()
        this.state = {
            etudiant : this.emptyEtudiant,
            redirect: true,
            verife : false,
            verife2 : false,
            submitted : false,
            submitted2 : false,
            sucssesCompte : false,
            valide : true,
            loading : false,
            dataEtudiant : {},
        }
        this.inscriptionService = new InscriptionService()

        this.sendIne = this.sendIne.bind(this)
        this.createCompte = this.createCompte.bind(this)
        this.onIneChange = this.onIneChange.bind(this)
        this.onEmailEtudiantChange = this.onEmailEtudiantChange.bind(this)
        this.onTelEtudiantChange = this.onTelEtudiantChange.bind(this)
        this.onPersonnelBesoinChange = this.onPersonnelBesoinChange.bind(this)
        this.onContactChange = this.onContactChange.bind(this)
    }
    componentDidMount = () => {
        /*const dataEtudiant = 
            {
                idEtudiant : 1, ine : "N0000202001", nomEtudiant : "OUEDRAOGO",
                prenomEtudiant : "Fatimata", dateNaissance : "12/03/2000",
                lieuNaissance : "Ouagadougou", emailEtudiant : "ouedfatimata@gmail.com",
                telEtudiant : "70324567"
            }
            
        
        this.setState({dataEtudiant : dataEtudiant})*/
    }
    sendIne(){
        let state = {};
        this.setState({submitted : true})
        if(this.state.etudiant.ine.trim()){
            const newIne =  this.state.etudiant.ine
            this.inscriptionService.sendIne(newIne).then(
                data => 
                {
                    console.log(data)
                    this.setState({
                        dataEtudiant: data,
                        redirect : false
                    })     
                })
                .catch(error => {
                    console.log(error);
                    this.setState({verife : true})
                })
            

            state = {
                etudiant: this.emptyEtudiant
            };
        }
        this.setState(state);

        /*const dataEtudiant = 
            {
                idEtudiant : 1, ine : "N0000202001", nomEtudiant : "OUEDRAOGO",
                prenomEtudiant : "Fatimata", dateNaissance : "12/03/2000",
                lieuNaissance : "Ouagadougou", emailEtudiant : "ouedfatimata@gmail.com",
                telEtudiant : "70324567"
            }
            
        
        this.setState({dataEtudiant : dataEtudiant, redirect : false})*/
    }
    createCompte (){
        
        let state = {};
        this.setState({submitted2 : true})
        if(this.state.etudiant.personnelBesoin.trim() && this.state.etudiant.contact.trim() && this.state.engagementStatutReglement && this.state.engagementPersonnelCaution && this.state.declarationSurHonneur && this.state.luApprouve && this.state.dataEtudiant.emailEtudiant){
            let engagementStatutReglement = this.state.engagementStatutReglement
            let engagementPersonnelCaution = this.state.engagementPersonnelCaution
            let declarationSurHonneur = this.state.declarationSurHonneur
            let luApprouve = this.state.luApprouve
            this.state.dataEtudiant.personnelBesoin = this.state.etudiant.personnelBesoin
            this.state.dataEtudiant.contact = this.state.etudiant.contact
            this.state.dataEtudiant.engagementStatutReglement = engagementStatutReglement
            this.state.dataEtudiant.engagementPersonnelCaution = engagementPersonnelCaution
            this.state.dataEtudiant.declarationSurHonneur = declarationSurHonneur
            this.state.luApprouve = luApprouve
            this.setState({loading : true})
            console.log(this.state.dataEtudiant)
            this.inscriptionService.create(this.state.dataEtudiant).then(
                data => {
                        this.setState({sucssesCompte : true, loading : false})
                        this.notify()
                        //this.messages.show({severity: 'success', summary: 'Compte créer avec succès, consulter votre mail pour recupérer le mot de passe'});  
                }
            )
            .catch(error => {
                console.log(error);
                this.setState({verife2 : true, loading : false})
                this.notify3()
            })
            
            state = {
                etudiant: this.emptyEtudiant,
                dataEtudiant: this.emptyEtudiant
            }
            this.setState({state})
            

        }
        else {
            this.setState({valide : false, submitted2 : true})
            this.notify2()
            //this.messages.show({severity: 'error', summary: 'Remplissez tous les champs !'});
            
        }
        
    }
    loadingSpinner = ( type, color) => (
        <ReactLoading type={type} color={color} height={200} width={50} />
    )
    onIneChange (e, ine){
        const val = (e.target && e.target.value) || '';
        let etudiant = this.state.etudiant;
        etudiant[`${ine}`] = val;

        this.setState({etudiant });
    }
    onEmailEtudiantChange(e, emailEtudiant) {
        const val = (e.target && e.target.value) || '';
        let etudiant = this.state.etudiant;
        let dataEtudiant = this.state.dataEtudiant;
        etudiant[`${emailEtudiant}`] = val;
        dataEtudiant[`${emailEtudiant}`] = val;

        this.setState({ etudiant });
        this.setState({ dataEtudiant });
    }
    onTelEtudiantChange(e, telEtudiant) {
        const val = (e.target && e.target.value) || '';
        let etudiant = this.state.etudiant;
        etudiant[`${telEtudiant}`] = val;

        this.setState({ etudiant });
    }
    onPersonnelBesoinChange(e, personnelBesoin) {
        const val = (e.target && e.target.value) || '';
        let etudiant = this.state.etudiant;
        etudiant[`${personnelBesoin}`] = val;

        this.setState({ etudiant });
    }
    onContactChange(e, contact) {
        const val = (e.target && e.target.value) || '';
        let etudiant = this.state.etudiant;
        etudiant[`${contact}`] = val;

        this.setState({ etudiant });
    }
    
    notify = () => toast.success('Consulter votre mail pour recuperer le mot de passe, inscription effectuée !', {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    notify2 = () => toast.error('Renseigner les données complémentaires !', {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    notify3 = () => toast.error('Un compte existe dejà avec le même INE !', {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    render() {
        //console.log(this.state.dataEtudiant)
        return (
            
            <div className="p-lg-12" >
                <ToastContainer
                    position="top-right"
                    autoClose={10000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <ToastContainer />
                { this.state.redirect ?
                <div className="p-grid">
                    <div className="p-lg-4"></div>
                <div className="p-col-12 p-md-12 p-lg-4" >
					<div className="card card-w-title resolution-center p-fluid">
                    
						<h1>Inscription</h1>
                        {
                            this.state.verife ? 
                                    <h2 style={{color : "red"}}>Votre INE est invalide, réesayez !</h2>
                                :
                                    ''
                        }
						<label htmlFor="ine">INE( <span style={{color : "red"}}>ceux ayant payés des frais d'inscription</span> )</label>
						<InputText style={{marginTop : 20}} value={this.state.etudiant.ine} id="ine" type="text" onChange={(e)=>this.onIneChange(e, 'ine')} placeholder="INE"
                         required autoFocus className={classNames({ 'p-invalid': this.state.submitted })}/>
                         {this.state.submitted && !this.state.etudiant.ine && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
						<div className="resolution-button-bar p-lg-6 " style={{marginTop : "20px", textAlign : "center"}}>
							<Button onClick={this.sendIne} type="button" label="S'inscrire" icon="pi pi-check"/>
						</div>
					</div>
				</div>
                </div>
                :
                
                <div className="p-col-12 p-md-12 p-lg-6" >
                  
                  {this.state.loading ?
                    <div className="p-lg-12" style={{margin: "0px auto", width: 300}}> 
                        
                        
                         {this.loadingSpinner("spin","black")}
                         
                    </div>
                        
                        :
            
    <div key={this.state.dataEtudiant.idEtudiant} className="card card-w-title resolution-center p-fluid">
        
        <h1>Création de compte</h1>
        <div style={{fontSize : "14px"}}> { this.state.submitted2 && this.state.sucssesCompte  &&  <Link to={'/connexion'} style={{fontSize : "20px"}}>Cliquer ici pour vous connecter</Link> }</div>
        <Messages ref={(el) => this.messages = el}></Messages>
        <div className="p-grid" style={{marginTop:"10px"}}>
            <div className="p-lg-6"> 
                <label htmlFor="ine">Identifiant National d'Etudiant</label>
                <InputText value={this.state.dataEtudiant.ine} disabled type="text" />
            </div>
        </div>
        <div className="p-grid">
            <div className="p-lg-6"> 
                <label htmlFor="nom">Nom</label>
                <InputText value={this.state.dataEtudiant.nomEtudiant} disabled  type="text" />
            </div>
            <div className="p-lg-6"> 
                <label htmlFor="prenom">Prénom</label>
                <InputText value={this.state.dataEtudiant.prenomEtudiant} disabled  type="text" />
            </div>
        </div>
        <div className="p-grid">
            <div className="p-lg-6"> 
                <label htmlFor="datenaiss">Date de naissance</label>
                <InputText value={this.state.dataEtudiant.dateNaissance} disabled  type="text" />
            </div>
            <div className="p-lg-6"> 
                <label htmlFor="lieunaiss">Lieu de naissance</label>
                <InputText value={this.state.dataEtudiant.lieuNaissance} disabled  type="text" />
            </div>
        </div>
        <div className="p-grid">
            <div className="p-lg-6"> 
                <label htmlFor="emailEtudiant">Email</label>
                <InputText id="emailEtudiant" value={this.state.dataEtudiant.emailEtudiant || ''}  type="text" onChange={(e)=>this.onEmailEtudiantChange(e,'emailEtudiant')} />
            </div>
            <div className="p-lg-6"> 
                <label htmlFor="telEtudiant">Téléphone</label>
                <InputText id="telEtudiant" value={this.state.dataEtudiant.telEtudiant || ''} disabled type="text" />
            </div>
        </div>
        <div className="p-grid">
            <div className="p-lg-6"> 
                <label htmlFor="personneBesoin">Personne à prévenir en cas de besoin <span style={{color : "red"}}>*</span> </label>
                <InputText value={this.state.etudiant.personnelBesoin} id="personnelBesoin" onChange={(e)=>this.onPersonnelBesoinChange(e, 'personnelBesoin')} placeholder="Nom et prénom"  type="text" />
            </div>
            <div className="p-lg-6"> 
                <label htmlFor="contact">Contact <span style={{color : "red"}}>*</span></label>
                <InputText id="contact" value={this.state.etudiant.contact} onChange={(e)=>this.onContactChange(e, 'contact')} placeholder="numéro téléphone" type="text" />
            </div>
        </div>
        <div className="p-grid">
            <div className="p-lg-6">
                <Checkbox inputId="reglement" onChange={e => this.setState({engagementStatutReglement: e.checked})} checked={this.state.engagementStatutReglement}></Checkbox>
                <label htmlFor="reglement" style={{marginLeft:"5px" }} >Engagement de statut et règlement intérieur <span style={{color : "red"}}>*</span></label>   
            </div>
            <div className="p-lg-6">
                <Link  className="p-lg-12">Consulter le document</Link>    
            </div>
        </div>
        <div className="p-grid">
            <div className="p-lg-6">
                <Checkbox onChange={e => this.setState({engagementPersonnelCaution: e.checked})} checked={this.state.engagementPersonnelCaution}></Checkbox>
                <label style={{marginLeft:"5px" }}>Engagement personnel de caution <span style={{color : "red"}}>*</span></label>
            </div>
            <div className="p-lg-6">
                <Link  className="p-lg-12">Consulter le document</Link>    
            </div>
        </div>
        <div className="p-grid">
            <div className="p-lg-6">
                <Checkbox onChange={e => this.setState({declarationSurHonneur: e.checked})} checked={this.state.declarationSurHonneur}></Checkbox>
                <label style={{marginLeft:"5px" }} >Déclaration sur l’honneur <span style={{color : "red"}}>*</span></label>
            </div>
            <div className="p-lg-6">
                <Link  className="p-lg-12">Consulter le document</Link>    
            </div>
        </div>
        <div className="p-grid">
            <div className="p-lg-6">
                <Checkbox onChange={e => this.setState({luApprouve: e.checked})} checked={this.state.luApprouve}></Checkbox>
                <label style={{marginLeft:"5px" }} >Lu et approuvé <span style={{color : "red"}}>*</span></label>
            </div>
        </div>
        <div className="resolution-button-bar p-lg-3 " style={{marginTop : "20px"}}>
                <Button onClick={this.createCompte} type="button" label="Créer compte" icon="pi pi-check"/>
        </div>
    </div>
                }

    			</div>
                }
                
            </div>  
        )
    }
}

export default Inscription
