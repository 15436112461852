import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { CritereService } from '../../service/CritereService';
import { TypeCritereService} from '../../service/TypeCritereService'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class Critere extends Component {
    emptyCritere = {
        idCritere: 0,
        idTypeCritere : null,
        libelleCritere: "",
        note : null
    }
    constructor() {
        super();
        this.state = {
            listeCritere:[],
            listeTypeCritere : [],
            critereDialog: false,
            deleteCritereDialog: false,
            critere: this.emptyCritere,
            submitted: false,
            globalFilter: null,
            redirect : false
        }

        this.critereService = new CritereService();
        this.typeCritereService = new TypeCritereService()

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveCritere = this.saveCritere.bind(this);
        this.editCritere = this.editCritere.bind(this);
        this.confirmDeleteCritere = this.confirmDeleteCritere.bind(this);
        this.deleteCritere = this.deleteCritere.bind(this);
        this.onLibelleCritereChange = this.onLibelleCritereChange.bind(this);
        this.onNoteChange = this.onNoteChange.bind(this);
        this.hideDeleteCritereDialog = this.hideDeleteCritereDialog.bind(this);
    }
    componentDidMount() {
        this.critereService.getAll().then(data => this.setState({listeCritere: data}));
        this.typeCritereService.getAll().then(data => this.setState({listeTypeCritere: data}));
    }
    actionTemplate(rowData, column) {
        return <div>
            <Button type="button" icon="pi pi-pencil" className="p-button-warning" style={{marginRight: '.5em'}}/>
            <Button type="button" icon="pi pi-trash" className="p-button-danger" />
        </div>;
    }
    openNew() {
        this.setState({
            critere: this.emptyCritere,
            submitted: false,
            critereDialog: true
        });
    }
    hideDialog() {
        this.setState({
            submitted: false,
            critereDialog: false
        });
    }
    hideDeleteCritereDialog() {
        this.setState({ deleteCritereDialog: false });
    }
    saveCritere() {
        let state = { submitted: true };

        if (this.state.critere.libelleCritere.trim()) {

            const newCritere = {
                idCritere : parseInt(this.state.critere.idCritere) ,
                idTypeCritere : parseInt(this.state.critere.idTypeCritere),
                libelleCritere : this.state.critere.libelleCritere,
                note : parseInt(this.state.critere.note)
            }
            console.log(newCritere)
            if (this.state.critere.idCritere) {
                this.critereService.update(parseInt(this.state.critere.idCritere), newCritere).then(data => {
                    this.notify();
                    this.setState({redirect : true})
                })
                

            }
            else {
                this.critereService.create(newCritere).then(data => {
                    this.notify();
                    this.setState({redirect : true})
                })
                
            }

            state = {
                ...state,
                critereDialog: false,
                critere: this.emptyCritere
            };
        }

        this.setState(state);
    }
    editCritere(critere) {
        this.setState({
            critere,
            critereDialog: true
        });
    }
    deleteCritere(idCritere) {
        this.critereService.delete(parseInt(idCritere)).then(data => {
            this.notify();
            this.setState({
                deleteCritereDialog: false,
                critere: this.emptyCritere,
                redirect : true
            });
            
            
        })
        
    }
    confirmDeleteCritere(critere) {
        this.setState({
            critere,
            deleteCritereDialog: true
        });
    }
    onIdTypeCritereChange(e, idTypeCritere) {
        const val = (e.target && e.target.value) || '';
        let critere = this.state.critere;
        critere[`${idTypeCritere}`] = parseInt(val);

        this.setState({critere });
    }
    onLibelleCritereChange(e, libelleCritere) {
        const val = (e.target && e.target.value) || '';
        let critere = this.state.critere;
        critere[`${libelleCritere}`] = val;

        this.setState({critere });
    }
    onNoteChange(e, note) {
        const val = (e.target && e.target.value) || '';
        let critere = this.state.critere;
        critere[`${note}`] = parseInt(val);

        this.setState({critere });
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editCritere(rowData)} style={{marginRight: '.5em'}}/>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteCritere(rowData)} />
            </React.Fragment>
        );
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                <Button label="Supprimer" icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} />
            </React.Fragment>
        )
    }
    notify = () => toast.success('Opération réuçit!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    render() {
        if(this.state.redirect){
            //window.location.reload(false);
        }
        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        const critereDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveCritere} />
            </React.Fragment>
        );
        const deleteCritereDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteCritereDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteCritere(this.state.critere.idCritere) } />
            </React.Fragment>
        );
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-2">
                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                        </div>
                        <div className="p-col-2">
                            <Button label="Rafraichir" icon="pi pi-refresh" className="" onClick={()=>window.location.reload(false)} />
                        </div>
                    </div>
                    
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeCritere} selectionMode="single" header="Liste des Critères" paginator={true} rows={5}
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="libelleCritere" header="Critère" sortable={true} filter={true} filterPlaceholder="rechercher critère"/>
                            <Column field="note" header="Note" sortable={true} filter={true} filterPlaceholder="rechercher note"/>
                            <Column field="libelleTypeCritere" header="Type critère" sortable={true} filter={true} filterPlaceholder="rechercher type critère"/>
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                    </div>
                </div>

                <Dialog visible={this.state.critereDialog} style={{ width: '500px', height:"300px" }} header="Informations critère" modal className="p-fluid" footer={critereDialogFooter} onHide={this.hideDialog}>
                    <div className="p-field">
                        <label htmlFor="idTypeCritere">Type critère</label>
                        <Dropdown optionLabel="libelleTypeCritere" optionValue="idTypeCritere" value={this.state.critere.idTypeCritere} options={this.state.listeTypeCritere} onChange={(e) => {this.onIdTypeCritereChange(e, 'idTypeCritere')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un type critère"/>
                        {this.state.submitted && !this.state.critere.idTypeCritere && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="libelleCritere">Libellé critère</label>
                        <InputText id="libelleCritere" value={this.state.critere.libelleCritere || ''}  onChange={(e) => this.onLibelleCritereChange(e, 'libelleCritere')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                        {this.state.submitted && !this.state.critere.libelleCritere && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="note">Note</label>
                        <InputText id="note" value={this.state.critere.note || ''}  onChange={(e) => this.onNoteChange(e, 'note')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })}   />
                        {this.state.submitted && !this.state.critere.note && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                </Dialog>
                <Dialog visible={this.state.deleteCritereDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteCritereDialogFooter} onHide={this.hideDeleteCritereDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.critere && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
            </div> 
        )
    }
}

export default Critere
