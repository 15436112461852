import React, {Component} from 'react';
import {Chart} from 'primereact/chart';
import Axios from 'axios'
import ReactLoading from 'react-loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class TableauBordDirection extends Component {

    constructor() {
        super();
        this.state = {
            dataDashBoard : [],
            pieData: [],
            loading : false
            
        }
    }
    componentDidMount = () => {
        this.setState({loading : true})
        Axios.get("http://hebergement.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/dash-board")
        .then(res => {
            this.setState({dataDashBoard : res.data.villeDepotDtos})
            let valeurs = []
            res.data.villeDepotDtos.map((donnee, i) => {
                valeurs[i] = {
                    labels: ["Nb. d'etudiants","Nb. d'inscrits cité","Nb. de dossiers deposés"],
                    ville : donnee.libelleVille,
                    datasets: [
                        {
                            data:[donnee.nbEtudiant, donnee.nbEtudiantInscrit, donnee.nbEtudiantDepose] ,
                            backgroundColor: [
                                "#FFC107",
                                "#03A9F4",
                                "#4CAF50"
                            ],
                            hoverBackgroundColor: [
                                "#FFE082",
                                "#81D4FA",
                                "#A5D6A7"
                            ]
                        }]
                } 
            })
            this.setState({pieData : valeurs, loading : false})   
        })
        .catch(error => {
            console.log(error);
            this.notify()
            this.setState({ loading : false})
        })
    }
    loadingSpinner = ( type, color) => (
        <ReactLoading type={type} color={color} height={200} width={50} />
    )
    notify = () => toast.error("Problème de connexion, reconnectez-vous !", {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    render() {
        //console.log(this.state.pieData)
        return(
            this.state.loading ? 
                <div className="p-grid">
                    <ToastContainer
                            position="top-right"
                            autoClose={10000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                    <ToastContainer />
                    <div className="p-col-5" ></div>
                    <div className="p-col-7" >
                        {this.loadingSpinner("spin","black")}
                    </div>
                </div>
                        :
            <div className="p-grid p-fluid">
              {this.state.pieData.map((dataDashBoard, i) =>
                <div key={i} className="p-col-12 p-lg-6">
                    <div className="card">
                        <h1 className="centerText">{dataDashBoard.ville}</h1>
                        <Chart type="pie" data={dataDashBoard} height="150"/> 
                    </div> 
                </div>
                )}
            </div>

        )
    }
}
export default TableauBordDirection