import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { TypeCritereService } from '../../service/TypeCritereService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class TypeCritere extends Component {
    emptyTypeCritere = {
        idTypeCritere: 0,
        libelleTypeCritere: "",
        coefficient : null,
        typeItem : "",
        statutItem : ""
    }
    constructor() {
        super();
        this.state = {
            listeTypeCritere:[],
            typeCritereDialog: false,
            deleteTypeCritereDialog: false,
            typeCritere: this.emptyTypeCritere,
            submitted: false,
            globalFilter: null,
            redirect : false
        }

        this.typeCritereService = new TypeCritereService();

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveTypeCritere = this.saveTypeCritere.bind(this);
        this.editTypeCrite = this.editTypeCrite.bind(this);
        this.confirmDeleteTypeCritere = this.confirmDeleteTypeCritere.bind(this);
        this.deleteTypeCritere = this.deleteTypeCritere.bind(this);
        this.onLibelleTypeCritereChange = this.onLibelleTypeCritereChange.bind(this);
        this.onCoefficientChange = this.onCoefficientChange.bind(this);
        this.onTypeItemChange = this.onTypeItemChange.bind(this);
        this.onStatutItemChange = this.onStatutItemChange.bind(this);
        this.hideDeleteTypeCritereDialog = this.hideDeleteTypeCritereDialog.bind(this);
    }
    componentDidMount() {
        this.typeCritereService.getAll().then(data => this.setState({listeTypeCritere: data}));
    }
    actionTemplate(rowData, column) {
        return <div>
            <Button type="button" icon="pi pi-pencil" className="p-button-warning" style={{marginRight: '.5em'}}/>
            <Button type="button" icon="pi pi-trash" className="p-button-danger" />
        </div>;
    }
    openNew() {
        this.setState({
            typeCritere: this.emptyTypeCritere,
            submitted: false,
            typeCritereDialog: true
        });
    }
    hideDialog() {
        this.setState({
            submitted: false,
            typeCritereDialog: false
        });
    }
    hideDeleteTypeCritereDialog() {
        this.setState({ deleteTypeCritereDialog: false });
    }
    saveTypeCritere() {
        let state = { submitted: true };

        if (this.state.typeCritere.libelleTypeCritere.trim()) {

            const newTypeCritere = {
                idTypeCritere : parseInt(this.state.typeCritere.idTypeCritere) ,
                libelleTypeCritere : this.state.typeCritere.libelleTypeCritere,
                coefficient : this.state.typeCritere.coefficient,
                typeItem : this.state.typeCritere.typeItem,
                statutItem : this.state.typeCritere.statutItem
            }
            console.log(newTypeCritere)
            if (this.state.typeCritere.idTypeCritere) {
                this.typeCritereService.update(parseInt(this.state.typeCritere.idTypeCritere), newTypeCritere)
                this.notify();
                this.setState({redirect : true})

            }
            else {
                this.typeCritereService.create(newTypeCritere)
                this.notify();
                this.setState({redirect : true})
            }

            state = {
                ...state,
                typeCritereDialog: false,
                typeCritere: this.emptyTypeCritere
            };
        }

        this.setState(state);
    }
    editTypeCrite(typeCritere) {
        this.setState({
            typeCritere,
            typeCritereDialog: true
        });
    }
    deleteTypeCritere(idTypeCritere) {
        this.typeCritereService.delete(parseInt(idTypeCritere))
        this.setState({
            deleteTypeCritereDialog: false,
            typeCritere: this.emptyTypeCritere
        });
        this.notify();
        this.setState({redirect : true})
    }
    confirmDeleteTypeCritere(typeCritere) {
        this.setState({
            typeCritere,
            deleteTypeCritereDialog: true
        });
    }

    onLibelleTypeCritereChange(e, libelleTypeCritere) {
        const val = (e.target && e.target.value) || '';
        let typeCritere = this.state.typeCritere;
        typeCritere[`${libelleTypeCritere}`] = val;

        this.setState({typeCritere });
    }
    onCoefficientChange(e, coefficient) {
        const val = (e.target && e.target.value) || '';
        let typeCritere = this.state.typeCritere;
        typeCritere[`${coefficient}`] = parseInt(val);

        this.setState({typeCritere });
    }
    onTypeItemChange(e, typeItem) {
        const val = (e.target && e.target.value) || '';
        let typeCritere = this.state.typeCritere;
        typeCritere[`${typeItem}`] = val;

        this.setState({typeCritere });
    }
    onStatutItemChange(e, statutItem) {
        const val = (e.target && e.target.value) || '';
        let typeCritere = this.state.typeCritere;
        typeCritere[`${statutItem}`] = val;

        this.setState({statutItem });
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editTypeCrite(rowData)} style={{marginRight: '.5em'}}/>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteTypeCritere(rowData)} />
            </React.Fragment>
        );
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                <Button label="Supprimer" icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} />
            </React.Fragment>
        )
    }
    notify = () => toast.success('Opération réuçit!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    
    render() {
        if(this.state.redirect){
            //window.location.reload(false);
        }
        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        const typeCritereDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveTypeCritere} />
            </React.Fragment>
        );
        const deleteTypeCritereDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteTypeCritereDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteTypeCritere(this.state.typeCritere.idTypeCritere) } />
            </React.Fragment>
        );
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-2">
                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                        </div>
                        <div className="p-col-2">
                            <Button label="Rafraichir" icon="pi pi-refresh" className="" onClick={()=>window.location.reload(false)} />
                        </div>
                    </div>
    
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeTypeCritere} selectionMode="single" header="Liste des Types criteres" paginator={true} rows={5}
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="libelleTypeCritere" header="Type critère" sortable={true} filter={true} filterPlaceholder="rechercher type critère"/>
                            <Column field="coefficient" header="Coefficient" sortable={true} filter={true} filterPlaceholder="rechercher coefficient"/>
                            <Column field="typeItem" header="Type item" sortable={true} filter={true} filterPlaceholder="rechercher type item"/>
                            <Column field="statutItem" header="Statut item" sortable={true} filter={true} filterPlaceholder="statut item"/>
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                    </div>
                </div>

                <Dialog visible={this.state.typeCritereDialog} style={{ width: '500px', height:"400px" }} header="Informations type critère" modal className="p-fluid" footer={typeCritereDialogFooter} onHide={this.hideDialog}>
                    <div className="p-field">
                        <label htmlFor="libelleTypeCritere">Libellé type critère</label>
                        <InputText id="libelleTypeCritere" value={this.state.typeCritere.libelleTypeCritere || ''}  onChange={(e) => this.onLibelleTypeCritereChange(e, 'libelleTypeCritere')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                        {this.state.submitted && !this.state.typeCritere.libelleTypeCritere && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="coefficient">Coefficient</label>
                        <InputText id="coefficient" value={this.state.typeCritere.coefficient || ''}  onChange={(e) => this.onCoefficientChange(e, 'coefficient')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })}   />
                        {this.state.submitted && !this.state.typeCritere.coefficient && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="typeItem">Type item</label>
                        <InputText id="typeItem" value={this.state.typeCritere.typeItem || ''}  onChange={(e) => this.onTypeItemChange(e, 'typeItem')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })}  />
                        {this.state.submitted && !this.state.typeCritere.typeItem && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="statutItem">Statut item</label>
                        <InputText id="statutItem" value={this.state.typeCritere.statutItem || ''}  onChange={(e) => this.onStatutItemChange(e, 'statutItem')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                        {this.state.submitted && !this.state.typeCritere.statutItem && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                </Dialog>
                <Dialog visible={this.state.deleteTypeCritereDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteTypeCritereDialogFooter} onHide={this.hideDeleteTypeCritereDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.typeCritere && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
            </div> 
        )
    }
}

export default TypeCritere
