import React, { Component } from 'react'
import Axios from 'axios'
import { Dropdown } from 'primereact/dropdown';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';
import {Button} from 'primereact/button';
import 'bootstrap/dist/css/bootstrap.css';


export class AnalyseDossier extends Component {

    emptyDossierCritere = {
        idCritere : 0,
        idTypeCritere : 0,
        libelleCritere : "",
        libelleTypeCritere : "",
        nomFichier : "",
        fichier : ""
   }
   constructor(){
       super()
       this.state = {
        dossierEtudiant : {},
        listeDossierCritere : new Array(),
        listeDossierCritereEtudiant : [],
        dossierCritere : this.emptyDossierCritere,
        valeurAnalyse : "",
        file : false,
        fileBase64 : null,
        extension : false,
        longueur : 580,
        largeur : 500,
        listeValeurAnalyse : [
            {idValeurAnalyse : 1, valeurAnalyse : "VALIDE"},
            {idValeurAnalyse : 2, valeurAnalyse : "INVALIDE"},
            {idValeurAnalyse : 3, valeurAnalyse : "INJUSTIFIE"}
        ],
        idCritere : null,
        nomFichier : null,
        fichier : null,
        redirect : false,
        loading : false,
        analyse : false
       }
       this.onFileDecode = this.onFileDecode.bind(this);
        
   }

    componentDidMount = () => {
        
        //let ine = N02770120202
        //let idDossier = 1668
        let idDossier = this.props.match.params.idDossier
        let ine = this.props.match.params.ine
        this.setState({loading : true})
        Axios.get('http://hebergement.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/dossier/depot?ineEtudiant='+ine+'&idDossier='+idDossier)
        .then(res => { 
            let idTypeCritere = null, libelleTypeCritere = "", idCritere = null, libelleCritere = "", nomFichier = "", fichier = null, valeurAnalyse = "", idDossierCritere = null
            
            res.data.listeDossierCritere.map((dossierCritere, i) => {
                if(dossierCritere.idDossierCritere) {
                    idTypeCritere = dossierCritere.idTypeCritere
                    libelleTypeCritere = dossierCritere.libelleTypeCritere
                    idCritere = dossierCritere.idCritere
                    libelleCritere = dossierCritere.libelleCritere
                    nomFichier = dossierCritere.nomFichier
                    fichier = dossierCritere.fichier
                    valeurAnalyse = dossierCritere.valeurAnalyse
                    idDossierCritere = dossierCritere.idDossierCritere
                
                    this.state.listeDossierCritere.push({
                        idTypeCritere : idTypeCritere,
                        idCritere : idCritere,
                        libelleTypeCritere : libelleTypeCritere,
                        libelleCritere : libelleCritere,
                        nomFichier : nomFichier,
                        fichier : fichier,
                        valeurAnalyse : valeurAnalyse,
                        idDossierCritere : idDossierCritere
                    })
                }
            })
            this.setState({
                dossierEtudiant : res.data,
                listeDossierCritereEtudiant : res.data.listeDossierCritere,
                loading : false
            })
           
         
        })
        .catch(error => {
            console.log(error);
            this.setState({loading : false})
        })
          
    }
    AnalyseDossier = () => {
        let dossierCritere = this.state.listeDossierCritere
        let verife = false
        dossierCritere.map(dossier => {
            if(!dossier.valeurAnalyse) verife = true
            dossier.fichier = null
        })
        if(verife){
            this.notify2()
        }
        else {
            const newAnalyse = {
                idDossier : this.state.dossierEtudiant.idDossier,
                statutDossier : this.state.dossierEtudiant.statutDossier,
                idSessionCenou : this.state.dossierEtudiant.idSessionCenou,
                typeDossier : this.state.dossierEtudiant.typeDossier,
                listeDossierCritere : dossierCritere,
                idEtudiant : this.state.dossierEtudiant.idEtudiant,
                ine : this.state.dossierEtudiant.ine, 
                nomEtudiant : this.state.dossierEtudiant.nomEtudiant,
                prenomEtudiant : this.state.dossierEtudiant.prenomEtudiant, 
           }
           console.log(newAnalyse)
           
           this.setState({loading : true})
            const url = 'http://hebergement.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/analyse-dossier'
            Axios.patch(url,newAnalyse).then(res => { 
                this.notify();
                this.setState({redirect : true, loading : false, analyse : true})
                
            })
            .catch(error => {
                console.log(error);
                this.notify3()
                this.setState({ redirect : false, loading : false})
            })
        }
        
    }
    

    onFileDecode (dossierCritere) { 
        let fichier = dossierCritere.fichier
        let nomFichier = dossierCritere.nomFichier
        if(fichier){
            let extension = nomFichier.split('.').pop();
            
            if(extension === "png" || extension === "jpg" || extension === "jpeg" || extension === "gif") 
                this.setState({fileBase64 : "data:application/octet-stream;base64,"+fichier, fileClick : true, extension : true })
            else {
                this.setState({fileBase64 : "data:application/pdf;base64,"+fichier, fileClick : true, extension : false })
            }
            
        }
        
    }
    retour = () => {
        
    }
    loadingSpinner = ( type, color) => (
        <ReactLoading type={type} color={color} height={200} width={50} />
    )
    notify = () => toast.success('Analyse de dossier effectué !', {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    notify2 = () => toast.error('Veuillez analyser tous les critères !', {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    notify3 = () => toast.error('Analyse echoué !', {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    render() {
        //console.log(this.state.dossierEtudiant)
        return (
            <div className=""> 
                <ToastContainer
                    position="top-right"
                    autoClose={10000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <ToastContainer />
                {this.state.loading ? 
                        this.loadingSpinner("spin","black")
                        :
                <div className="p-lg-12">
                    <div className="p-grid">
                        <div className="p-lg-6 card">
                            <div >
                            <table className="table table-striped" >
                                <thead>
                                    <tr>
                                        <th>Type critère</th>
                                        <th>Critère choisi</th>
                                        <th>Nom du fichier</th>
                                        <th>Analyse</th>
                                        <th>Consulter</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.listeDossierCritereEtudiant.map((dossierCritere,i)=>
                                    dossierCritere.idDossierCritere ?
                                    <tr key={i}>
                                        
                                        <td>{dossierCritere.libelleTypeCritere}</td>
                                        <td>{dossierCritere.libelleCritere}</td>
                                        <td>{dossierCritere.nomFichier}</td>
                                        <td>
                                            <Dropdown 
                                                optionLabel="valeurAnalyse" optionValue="valeurAnalyse" value={this.state.listeDossierCritere[i].valeurAnalyse} options={this.state.listeValeurAnalyse} 
                                                onChange={(e) => {
                                                    this.setState({valeurAnalyse :  e.value})
                                                    this.state.listeDossierCritere[i].valeurAnalyse  = e.value
                                                }
                                            }
                                            />
                                        </td>
                                        <td>
                                            {dossierCritere.fichier ? 
                                            <Button icon="pi pi-eye" className="p-button-success"
                                            onClick={ () => {
                                                this.onFileDecode(dossierCritere)
                                            }

                                            }
                                        
                                            />
                                            :
                                            ""
                                        }
                                        </td>
                                
                                    </tr>
                                    :
                                        ""
                                    
                                    )}
                                </tbody>
                            </table> 
                            </div>
                            {this.state.analyse ?
                                <div className="" style={{textAlign : "center", marginTop : 10}}>
                                    <Link to={'/analyse'} style={{fontSize : "20px"}}>
                                        <Button type="button"  label="Retour" icon="pi pi-arrow-left"/>
                                    </Link>
                                </div>
                                :
                                ""
                            } 
                        </div>
                        <div style={{marginLeft : 20}}> </div>
                        <div className="p-col-5 card">
                            {this.state.fileClick ?
                                this.state.extension ?
                                    <img alt="fichier" style={{width : this.state.longueur, height : this.state.largeur}} src={this.state.fileBase64}  />
                                    :
                                    <iframe src={this.state.fileBase64}
                                        style={{width:this.state.longueur, height:this.state.largeur}}>
                                    </iframe>
                                    :
                                    ""
                            }
                        </div>
                        <div className="" style={{marginLeft : 30}}>
                            <Button  type="button"  label="" icon="pi pi-plus"
                                onClick={() => {
                                    let longueur = this.state.longueur + 20
                                    let largeur = this.state.largeur + 20
                                    this.setState({longueur : longueur, largeur : largeur})
                                }}
                            /> <br/>
                            <Button style={{marginTop : 5}}  type="button"  label="" icon="pi pi-minus"
                                onClick={() => {
                                    let longueur = this.state.longueur - 20
                                    let largeur = this.state.largeur - 20
                                    this.setState({longueur : longueur, largeur : largeur})
                                }}
                            />
                        </div>
                        
                    </div>
                    <div className="" style={{textAlign : "center"}}>
                        <Button  type="button" onClick={this.AnalyseDossier} label="Valider" icon="pi pi-check"/>
                    </div> 
                </div> 
                }
                
            </div> 
            
        )
    }
}

export default AnalyseDossier
