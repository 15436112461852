import React, { Component } from 'react'
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext'
import {Button} from 'primereact/button';
//import Axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ConnexionService } from '../service/ConnexionService';



export class Connexion extends Component {
    state = {

        username : '',
        password : '',
        redirect : false,
        verife : false,
        user : {}
    }

    constructor(props){
        super(props)

        this.connexionService = new ConnexionService();

    }
    
    onChangeLogin = e => {
        this.setState({username : e.target.value})
    }
    onChangePassword = e => {
        this.setState({password : e.target.value})
    }
    onSendUser = () =>{
        
        if(this.state.username && this.state.password){
            const newUser = {
                username : this.state.username,
                password : this.state.password
            }
            console.log(newUser)
            this.connexionService.loginUser(newUser)
            .then(res => {
                    this.notify()  
                    //console.log(res.data)
                    localStorage.setItem('user', JSON.stringify(res.data))
                    localStorage.setItem('accessToken', JSON.stringify(res.data.accessToken))
                    this.setState({redirect : true})
                    
            })
            .catch(error => {
                console.log(error);
                this.setState({verife : true})
            })

            /*const dataUser = {
                username : "nimbrata",
                password : "nimbrata",
                nom : "OUEDRAOGO",
                prenom : "Nimbrata",
                roles : [
                    "ROLE_HEBERGEMENT_ETUDIANT"
                ]
            }
            
            localStorage.setItem('user', JSON.stringify(dataUser))
            this.setState({user : dataUser, redirect : true})*/
            

        }
    }
    notify = () => toast.success('Connexion réuçit !', {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    render() {
          //console.log(this.state.verife)
          if(this.state.redirect){
              window.location.assign("/")
          }
        const header = (
            <div style={{textAlign : "center"}}>
                <label  >CONNEXION</label>
            </div>
            
        );
        return (
            
            <div>
              <div className="p-lg-12 p-grid">
                <div className="p-lg-12 contenair " id="slideshow" style={{textAlign : ""}}>
                    <div id="slider" style={{backgroundColor : "#d1ecf1", padding : 5}}>
                        <p style={{fontSize : 16, textAlign : "center"}}>
                        Pour eviter une erreur de mot de passe lors de la connexion, il est préferable de copier-coller le mot de passe qui vous est envoyé par mail !
                        </p>
                    </div>
                </div>
            </div>  
            <div className="p-grid">
                <ToastContainer
                    position="top-right"
                    autoClose={10000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <ToastContainer />
                <div className="p-lg-4">
                
                </div>
                <Panel header={header} className="p-lg-4 p-col-12 p-md-12" style={{marginTop: 10}}>
                    {this.state.verife ?
                        <div style={{textAlign : "center", color : "red", fontSize : "20px"}}>
                            <label>Erreur de connexion réessayer</label>
                        </div>
                        :
                        ""
                    }
                    <div style={{textAlign : ""}}>
                    <div  style={{textAlign:""}} >
                        <label>Identifiant Nationale d'Etudiant (INE)</label><br/>
                        <InputText  type="text" onChange={this.onChangeLogin} value={this.state.username || ""} className="p-col-12 p-xl-12 p-md-12"/>
                    </div>
                    <div  style={{marginTop:"10px"}}>
                        <label>Mot de passe</label><br/>
                        <InputText  onChange={this.onChangePassword} type="password" value={this.state.password || ""} className="p-lg-12 p-col-12 p-xl-12 p-md-12"/>
                    </div>
                    </div>
                    <div style={{textAlign:"center",marginTop:"10px"}}>
                        <Button onClick={this.onSendUser} label="Se connecter" className="p-lg-6"/>
                    </div>
                </Panel>
                <div className="p-lg-4">
                
                </div>
                
            </div>
    
            </div>
            
        )
    }
}

export default Connexion
