import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import { InputText } from 'primereact/inputtext';
import Axios from 'axios'
import ReactLoading from 'react-loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class DepotGenerale extends Component {
    state = {
        listeDeopt : [],
        globalFilter: null,
        loading : false
    }
    componentDidMount = () => {
        this.setState({loading : true})
        Axios.get("http://192.168.43.14:8083/service/api/hebergement-cenou/campus-faso/dossier")
             .then(res => { 
                 this.setState({listeDeopt : res.data, loading : false})
                 console.log(res.data)
                })
             .catch(error => {
                console.log(error);
                this.notify()
                this.setState({ loading : false})
            })
        /*const data = [
            {
                ine : "N000020201" , nomEtudiant : "BAKOUAN", prenomEtudiant : "Jeans jacques",
                recipisse : "88838434", dateDepot : "12/10/2020", libelleDirection : "Direction régionale de Ouagadougou", 
                nomVille : "Ouagadougou"
            }
        ]
        this.setState({listeDeopt : data})*/
    } 
    loadingSpinner = ( type, color) => (
        <ReactLoading type={type} color={color} height={200} width={50} />
    )
    notify = () => toast.error("Problème de connexion, reconnectez-vous !", {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    render() {
        const header = (
            <div className="p-grid p-col-12">
                <ToastContainer
                            position="top-right"
                            autoClose={10000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                    <ToastContainer />
            <div className="table-header p-col-5">
                <span className="p-input-icon-left">
                    <InputText className="p-col-6" type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
                
            </div>
            <div className="table-header p-col-6">
                <span className="p-input-icon-left">
                    <label className="p-col-12">Nombre total de dossiers : {this.state.listeDeopt.length}</label>
                </span>
                
            </div>
            </div>
        );
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        {header}
                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.loadingSpinner("spin","black")}
                            </div>
                        </div>
                                :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeDeopt} selectionMode="single" header="Liste des dossiers" paginator={true} rows={10}
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="ine" header="INE" sortable={true} filter={true} filterPlaceholder="rechercher ine"/>
                            <Column field="nomEtudiant" header="Nom" sortable={true} filter={true} filterPlaceholder="rechercher nom"/>
                            <Column field="prenomEtudiant" header="Prénom" sortable={true} filter={true} filterPlaceholder="rechercher prénom"/>
                            <Column field="sexeEtudiant" header="Sexe" sortable={true} filter={true} filterPlaceholder="rechercher sexe"/>
                            <Column field="telEtudiant" header="Téléphone" sortable={true} filter={true} filterPlaceholder="rechercher telephone"/>
                            <Column style={{ width: '21em'}} field="referenceDossier" header="Recipissé" sortable={true} filter={true} filterPlaceholder="rechercher recipissé"/>
                            <Column field="dateDepot" header="Date depôt" sortable={true} filter={true} filterPlaceholder="rechercher date de depôt"/>
                            <Column field="statutDossier" header="Statut dossier" sortable={true} filter={true} filterPlaceholder="rechercher statut dossier"/>
                            <Column field="libelleDirection" header="Direction" sortable={true} filter={true} filterPlaceholder="rechercher Direction"/>
                                  
                        </DataTable>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default DepotGenerale
