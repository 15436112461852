import React, { Component } from 'react'
import {Button} from 'primereact/button';
//import Axios from 'axios'
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { DossierService } from '../service/DossierService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class TableauBord extends Component {
     emptyDossier = {
          idDossier : 0,
          typeDossier : "",
          statutDossier : "",
          referenceDossier : ""
     }
     constructor() {
          super();
          this.state = {
               dataEtudiant : [],
               idD : null,
               idS : null,
               sS : null,
               dossierDialog: false,
               deleteDossierDialog: false,
               dossier: this.emptyDossier,
               submitted: false,
               redirect : false,
               globalFilter: null,
               verifeDelete : null
          }
          this.dossierService = new DossierService();
          this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
          this.confirmDeleteDossier = this.confirmDeleteDossier.bind(this);
          this.deleteDossier = this.deleteDossier.bind(this);
          this.hideDeleteDossierDialog = this.hideDeleteDossierDialog.bind(this);
     }
     
    componentDidMount = () => {
     const data = localStorage.getItem('user')
     const user = JSON.parse(data)
     this.dossierService.get(user.username).then(data => {
          console.log(data)

          if(data.length) {
               if(data[0].statutSession === "OUVERT") this.setState({verifeDelete : true});
               else if(data[0].statutSession === "FERME") this.setState({verifeDelete : false})
               else this.setState({verifeDelete : null})
               this.setState(
                    {
                         dataEtudiant: data,
                         idD : data[0].idDossier,
                         idS : data[0].idSessionCenou,
                         sS : data[0].statutSession
                    }
               )
          }
          });
          
     /*const dossierEtudiant = [{
          idDossier : 1,
          typeDossier : "ADMISSION",
          statutDossier : "RECEPTIONNER",
          referenceDossier : "ABN2348ION2020",
          dateDepot : "23/10/2020"
     }]
     this.setState({dataEtudiant : dossierEtudiant})*/
    }
    actionTemplate(rowData, column) {
     return <div>
         <Button type="button" icon="pi pi-pencil" className="p-button-warning" style={{marginRight: '.5em'}}/>
         <Button type="button" icon="pi pi-trash" className="p-button-danger" />
     </div>;
 }
 
    hideDeleteDossierDialog() {
     this.setState({ deleteDossierDialog: false });
     }

      deleteDossier(idDossier) {
          this.dossierService.delete(parseInt(idDossier)).then(data => {
               this.setState({
                    deleteDossierDialog: false,
                    dossier: this.emptyDossier,
                    redirect : true
                });
                this.notify();
          })
          .catch(error => {
               console.log(error);
               this.notify()
               this.setState({deleteDossierDialog : false})
           })
          //console.log(idDossier)
      }
     confirmDeleteDossier(dossier) {
          this.setState({
              dossier,
              deleteDossierDialog: true
          });
      }
      
    actionBodyTemplate(rowData) {
         //let idD = 1668
        return (
            <React.Fragment>
               <Link to={"/consult-dossier/"+this.state.idD} style={{marginRight: '.5em'}}><Button icon="pi pi-eye" className="p-button-success"  /></Link> 
               {this.state.verifeDelete ? 
                    <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteDossier(rowData)} />
                    :
                    ""
               } 
            </React.Fragment>
        );
    }
    notify = () => toast.error("Vous ne pouvez pas supprimer votre dossier, la session d'inscription est close !", {
     position: "top-right",
     autoClose: 10000,
     hideProgressBar: false,
     closeOnClick: true,
     pauseOnHover: true,
     draggable: true,
     progress: undefined,
 });
    render() {
         //console.log(this.state.dataEtudiant)
     if(this.state.redirect){
          window.location.reload(false);
      }
        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const deleteDossierDialogFooter = (
          <React.Fragment>
              <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteDossierDialog} />
              <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteDossier(this.state.dossier.idDossier) } />
          </React.Fragment>
      );
        return (
            <div className="p-grid p-lg-12 resolution-center p-fluid">
                 <ToastContainer
                            position="top-right"
                            autoClose={10000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                    <ToastContainer />
                <div className="card p-lg-12">
                    <h1>Tableau de bord</h1>   
                </div> 
                
                    <div className="p-grid p-lg-12">
                        <div className="p-lg-9 card " >
                            <div className="p-lg-12">
                                <DataTable responsive={true} value={this.state.dataEtudiant}>
                                    <Column field="referenceDossier" style={{ width: ''}} header="Num. recipissé"></Column>
                                    <Column field="villeOrientation" header="Ville de dépôt" />
                                    <Column field="dateDepot" style={{ width: ''}} header="Date de depot"></Column>
                                    <Column field="statutDossier" style={{ width: ''}} header="Statut du dossier"></Column>
                                    <Column field="typeDossier" style={{ width: ''}} header="Type de dossier"></Column>
                                    <Column field="admis" header="Résultat" style={{ width: ''}}></Column>
                                    <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                                </DataTable>
                            </div>   
                        </div>
                        <div className=" " style={{width : "20px"}} ></div>
                        <div className="p-lg-2 card "  >
                               <div className="p-grid">
                                   <div className="p-lg-6">
                                        <label>Direction Régionale : </label>
                                   </div>
                                   <div className="p-lg-6">
                                        <label> </label>
                                   </div>
                                </div> 
                                <div className="p-grid">
                                   <div className="p-lg-6">
                                        <label>Ville : </label>
                                   </div>
                                   <div className="p-lg-6">
                                        <label> </label>
                                   </div>
                                </div> 
                                <div className="p-grid">
                                   <div className="p-lg-6">
                                        <label>Cité universitaire : </label>
                                   </div>
                                   <div className="p-lg-6">
                                        <label> </label>
                                   </div>
                                </div> 
                                <div className="p-grid">
                                   <div className="p-lg-6">
                                        <label>Bâtiment : </label>
                                   </div>
                                   <div className="p-lg-6">
                                        <label> </label>
                                   </div>
                                </div> 
                                <div className="p-grid">
                                   <div className="p-lg-6">
                                        <label>Niveau : </label>
                                   </div>
                                   <div className="p-lg-6">
                                        <label> </label>
                                   </div>
                                </div> 
                                <div className="p-grid">
                                   <div className="p-lg-6">
                                        <label>Couloir : </label>
                                   </div>
                                   <div className="p-lg-6">
                                        <label> </label>
                                   </div>
                                </div> 
                                <div className="p-grid">
                                   <div className="p-lg-6">
                                        <label>Chambre : </label>
                                   </div>
                                   <div className="p-lg-6">
                                        <label> </label>
                                   </div>
                                </div>
                                <div className="p-grid">
                                   <div className="p-lg-6">
                                        <label>Lit : </label>
                                   </div>
                                   <div className="p-lg-6">
                                        <label> </label>
                                   </div>
                                </div>     
                        </div>   
                    </div> 
                    <Dialog visible={this.state.deleteDossierDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteDossierDialogFooter} onHide={this.hideDeleteDossierDialog}>
                         <div className="confirmation-content">
                         <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                         {this.state.dossier && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                         </div>
                    </Dialog>   
                    </div> 
        )
    }
}

export default TableauBord
