import axios from 'axios';
import { AdresseIP } from './AdresseIp';

export class UtilisateurService {

    constructor(){
        this.adresse = new AdresseIP()
    }

    get(userName) {
        const url = this.adresse.getAdresseIP() +'/service/api/hebergement-cenou/campus-faso/users/'
        return axios.get(url+userName)
                    .then(res => res.data)
    }
    
    getAll() {
        
        return axios.get(this.adresse.getAdresseIP() +'/service/api/hebergement-cenou/campus-faso/users')
                    .then(res => res.data);
    }
    create(newUser) {
        const url = this.adresse.getAdresseIP() +'/service/api/hebergement-cenou/campus-faso/users'
        return axios.post(url,newUser)
                    .then(res => res.data);
    }
    update(idUser ,newUser) {
        const url = this.adresse.getAdresseIP() +'/service/api/hebergement-cenou/campus-faso/users/'
        return axios.put(url+idUser, newUser)
                    .then(res => res.data);
    }
    delete(idUser) {
        const url = this.adresse.getAdresseIP() +'/service/api/hebergement-cenou/campus-faso/users/'
        return axios.patch(url+idUser)
                    .then(res => res.data)
    }
    recherche (username) {
        const url = this.adresse.getAdresseIP() +"/service/api/hebergement-cenou/campus-faso/users?username="+username
        return axios.get(url)
                    .then(res => res.data)
    }
    reinitialise(idUser, user) {
        const url = this.adresse.getAdresseIP() +'/service/api/hebergement-cenou/campus-faso/users/'+idUser+'/reset'
        return axios.put(url, user)
                    .then(res => res.data);
    }


}