import axios from 'axios';

export class LitService {
    
    getAll() {
        
        return axios.get('http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/lit')
                    .then(res => res.data);
    }
    create(newLit) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/lit'
        return axios.post(url,newLit)
                    .then(res => res.data);
    }
    update(idLit ,newLit) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/lit/'
        return axios.put(url+idLit, newLit)
                    .then(res => res.data);
    }
    delete(idLit) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/lit/'
        return axios.patch(url+idLit)
                    .then(res => res.data)
    }
    get(idChambre) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/lit/?idChambre='+idChambre
        return axios.get(url)
                    .then(res => res.data)
    }

}