import axios from 'axios';

export class TypeCritereService {
    
    /*getAll() {
        
        return axios.get('http://192.168.43.106:8083/service/api/hebergement-cenou/campus-faso/type-critere')
                    .then(res => res.data);
    }
    create(newTypeCritere) {
        const url = 'http://192.168.43.106:8083/service/api/hebergement-cenou/campus-faso/type-critere'
        return axios.post(url,newTypeCritere)
                    .then(res => res.data);
    }
    update(idTypeCritere ,newTypeCritere) {
        const url = 'http://192.168.43.106:8083/service/api/hebergement-cenou/campus-faso/type-critere/'
        return axios.put(url+idTypeCritere, newTypeCritere)
                    .then(res => res.data);
    }
    delete(idTypeCritere) {
        const url = 'http://192.168.43.106:8083/service/api/hebergement-cenou/campus-faso/type-critere/'
        return axios.patch(url+idTypeCritere)
                    .then(res => res.data)
    }*/

    getAll() {
        
        return axios.get('http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/type-critere')
                    .then(res => res.data);
    }
    create(newTypeCritere) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/type-critere'
        return axios.post(url,newTypeCritere)
                    .then(res => res.data);
    }
    update(idTypeCritere ,newTypeCritere) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/type-critere/'
        return axios.put(url+idTypeCritere, newTypeCritere)
                    .then(res => res.data);
    }
    delete(idTypeCritere) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/type-critere/'
        return axios.patch(url+idTypeCritere)
                    .then(res => res.data)
    }

}