import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { QuotaService } from '../../service/QuotaService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import { DirectionService } from '../../service/DirectionService';
import { CritereService } from '../../service/CritereService';
import { SessionService } from '../../service/SessionService';


export class Quota extends Component {

    emptyQuota = {
        idQuota : 0,
        idDirection : 0,
        idSessionCenou : 0,
        idCritere : 0,
        valeurQuota : 0
    };
    constructor() {
        super();
        this.state = {
            
            listeDirection : [],
            listeCritere : [],
            listeSession : [],
            quotaDialog: false,
            deleteQuotaDialog: false,
            quota: this.emptyQuota,
            submitted: false,
            redirect : false,
            loading : false,
            globalFilter: null
        }

        this.quotaService = new QuotaService();
        this.directionService = new DirectionService();
        this.critereService = new CritereService();
        this.sessionService = new SessionService();

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveQuota = this.saveQuota.bind(this);
        this.editQuota = this.editQuota.bind(this);
        this.confirmDeleteQuota = this.confirmDeleteQuota.bind(this);
        this.deleteQuota = this.deleteQuota.bind(this);
        this.onQuotaChange = this.onQuotaChange.bind(this);
        this.onIdDirectionChange = this.onIdDirectionChange.bind(this);
        this.onIdSessionChange = this.onIdSessionChange.bind(this);
        this.onIdCritereChange = this.onIdCritereChange.bind(this);
        this.hideDeleteQuotaDialog = this.hideDeleteQuotaDialog.bind(this);
    }
    componentDidMount() {
        this.setState({loading : true})
        this.quotaService.getAll().then(data => 
            this.setState({listeQuota: data, loading : false})
            )
            .catch(error => {
                this.notify2()  
                console.log(error)
                this.setState({loading : false})
            })

        this.directionService.getAll().then(data => {
            this.setState({listeDirection: data})
        })
        .catch(error => {this.notify2() ; console.log(error)})

        this.critereService.getAll().then(data => {
            this.setState({listeCritere: data})
        })
        .catch(error => {this.notify2() ; console.log(error)})
        
    }
    
    openNew() {
        this.setState({
            quota: this.emptyQuota,
            submitted: false,
            quotaDialog: true
        });
    }
    hideDialog() {
        this.setState({
            submitted: false,
            quotaDialog: false
        });
    }
    hideDeleteQuotaDialog() {
        this.setState({ deleteQuotaDialog: false });
    }
    saveQuota() {
        let state = { submitted: true };

        if (this.state.quota.idSessionCenou && this.state.quota.idCritere && this.state.quota.valeurQuota) {

            const newQuota = {
                idQuota : parseInt(this.state.quota.idQuota) ,
                idSessionCenou : parseInt(this.state.quota.idSessionCenou) ,
                idCritere : parseInt(this.state.quota.idCritere) ,
                valeurQuota : parseInt(this.state.quota.valeurQuota)
            }
            console.log(newQuota)
            if (this.state.quota.idQuota) {
                this.quotaService.update(parseInt(this.state.quota.idQuota
                    ), newQuota)
                                .then(data => {
                                    this.notify();
                                    //console.log(data)
                                    this.setState({redirect : true})
                                })
                                .catch(error => {
                                    this.notify2()
                                    console.log(error);
                                })
            }
            else {
                this.quotaService.create(newQuota).then(data => {
                    this.notify();
                    //console.log(data)
                    this.setState({redirect : true})
                })
                .catch(error => {
                    this.notify2()
                    console.log(error);
                })
                
            }
            state = {
                ...state,
                quotaDialog: false,
                quota: this.emptyQuota
            };
            
        }

        this.setState(state);
    }
    editQuota(quota) {
        this.setState({
            quota,
            quotaDialog: true
        });
    }
    confirmDeleteQuota(quota) {
        this.setState({
            quota,
            deleteQuotaDialog: true
        });
    }
    deleteQuota(idQuota) {
        this.quotaService.delete(parseInt(idQuota)).then(data => {
            this.notify();
            this.setState({
                deleteQuotaDialog: false,
                quota: this.emptyQuota,
                redirect : true
            });
        })
    }
    
    onQuotaChange(e, valeurQuota) {
        const val = (e.target && e.target.value) || '';
        let quota = this.state.quota;
        quota[`${valeurQuota}`] = val;

        this.setState({quota });
    }
    onIdDirectionChange(e, idDirection) {
        const val = (e.target && e.target.value) || '';
        let quota = this.state.quota;
        quota[`${idDirection}`] = val;

        this.setState({ quota });
    }
    onIdSessionChange(e, idSessionCenou) {
        const val = (e.target && e.target.value) || '';
        let quota = this.state.quota;
        quota[`${idSessionCenou}`] = val;

        this.setState({ quota });
    }
    onIdCritereChange(e, idCritere) {
        const val = (e.target && e.target.value) || '';
        let quota = this.state.quota;
        quota[`${idCritere}`] = val;

        this.setState({ quota });
    }
    loadSession = (idDirection) => {
        this.sessionService.getSessionDirection(idDirection).then(data => {
            data.map(session => {
                if(session.etatSession === "DELIBERATION" && session.statutSession === "OUVERT") {
                    let listeSession  = []
                    listeSession.push(session)
                    this.setState({listeSession : listeSession})
                    //console.log(session)
                }
            })
            
            
        })
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editQuota(rowData)} style={{marginRight: '.5em'}}/>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteQuota(rowData)} />
            </React.Fragment>
        );
    }
    notify = () => toast.success('Opération réuçit!', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    notify2 = () => toast.error("Echec de l'opération !", {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
    loadingSpinner = ( type, color) => (
        <ReactLoading type={type} color={color} height={200} width={50} />
    )
    render() {

        /*if(this.state.redirect) {
            //window.location.reload(false);
        }*/
        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        const quotaDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveQuota} />
            </React.Fragment>
        );
        const deleteQuotaDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteQuotaDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteQuota(this.state.quota.idQuota) } />
            </React.Fragment>
        );
        
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-2">
                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                        </div>
                        <div className="p-col-2">
                            <Button label="Rafraichir" icon="pi pi-refresh" className="" onClick={()=>window.location.reload(false)} />
                        </div>
                    </div>
    
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.loadingSpinner("spin","black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeQuota} selectionMode="single" header="Liste des quotas" paginator={true} rows={5}
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="libelleCritere" header="Critere" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher Critère"/>
                            <Column field="valeurQuota" header="Quota" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher quota"/>
                            <Column field="libelleDirection" header="Direction" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher direction"/>
                            <Column field="anneeDebut" header="Année" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher année"/>
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        }
                    </div>
                </div>

                <Dialog visible={this.state.quotaDialog} style={{ width: '450px' }} header="Informations quota" modal className="p-fluid" footer={quotaDialogFooter} onHide={this.hideDialog}>
                    <div className="p-field">
                        <label htmlFor="nomCite">Direction</label>
                        <Dropdown optionLabel="libelleDirection" optionValue="idDirection" value={this.state.quota.idDirection} options={this.state.listeDirection} onChange={(e) => { this.loadSession(e.value); this.onIdDirectionChange(e, 'idDirection')}} autoFocus placeholder="Selectionner une direction"/>
                    </div>
                    <div className="p-field">
                        <label htmlFor="etatSession">Session</label>
                        <Dropdown optionLabel="etatSession" optionValue="idSessionCenou" value={this.state.quota.idSessionCenou} options={this.state.listeSession} onChange={(e) => {this.onIdSessionChange(e, 'idSessionCenou')}} autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner une session"/>
                        {this.state.submitted && !this.state.quota.idSessionCenou && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="libelleCritere">Critère</label>
                        <Dropdown optionLabel="libelleCritere" optionValue="idCritere" value={this.state.quota.idCritere} options={this.state.listeCritere} onChange={(e) => {this.onIdCritereChange(e, 'idCritere')}} autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner une criètre"/>
                        {this.state.submitted && !this.state.quota.idCritere && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="quota">Quota</label>
                        <InputText id="quota" value={this.state.quota.valeurQuota}  onChange={(e) => this.onQuotaChange(e, 'valeurQuota')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                        {this.state.submitted && !this.state.quota.valeurQuota && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                </Dialog>
                <Dialog visible={this.state.deleteQuotaDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteQuotaDialogFooter} onHide={this.hideDeleteQuotaDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.quota && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
            </div> 
        )
    }
}

export default Quota
