import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { SessionService } from '../../service/SessionService';
import { DirectionService} from '../../service/DirectionService'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class Session extends Component {
    emptySession = {
        idSessionCenou: 0,
        etatSession : "",
        idDirection : null,
        libelleCritere: "",
        statutSession : "",
        dateOuverture : null,
        dateFermeture : null
    }
    constructor() {
        super();
        this.state = {
            listeSession:[],
            listeStatutSession : [],
            listeEtatSession : [],
            listeDirection : [],
            sessionDialog: false,
            deleteSessionDialog: false,
            session: this.emptySession,
            submitted: false,
            globalFilter: null,
            redirect : false
        }

        this.sessionService = new SessionService();
        this.directionService = new DirectionService()

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveSession = this.saveSession.bind(this);
        this.editSession = this.editSession.bind(this);
        this.confirmDeleteSession = this.confirmDeleteSession.bind(this);
        this.deleteSession = this.deleteSession.bind(this);
        this.onIdDirectionChange = this.onIdDirectionChange.bind(this);
        this.onDateOuvertureChange = this.onDateOuvertureChange.bind(this);
        this.onDateFermetureChange = this.onDateFermetureChange.bind(this);
        this.onStatutSessionChange = this.onStatutSessionChange.bind(this);
        this.hideDeleteSessionDialog = this.hideDeleteSessionDialog.bind(this);
    }
    componentDidMount() {
        this.sessionService.getAll()
        .then(data => {
            console.log(data)
            this.setState({listeSession: data}
            )})
        .catch(error => {
            console.log(error);
        })
        this.directionService.getAll().then(data => this.setState({listeDirection: data}));
        const listeStatut = [
            {idSatut : 1, statutSession : "OUVERT"}, {idSatut : 2, statutSession : "FERME"},
            {idSatut : 3, statutSession : "SUSPENDU"},
        ]
        const listeEtat = [
            {idEtatSession : 1, etatSession : "INSCRIPTION"}, {idEtatSession : 2, etatSession : "ANALYSE"},
            {idEtatSession : 3, etatSession : "DELIBERATION"}
        ]
        this.setState({listeStatutSession : listeStatut, listeEtatSession : listeEtat})
    }
    actionTemplate(rowData, column) {
        return <div>
            <Button type="button" icon="pi pi-pencil" className="p-button-warning" style={{marginRight: '.5em'}}/>
            <Button type="button" icon="pi pi-trash" className="p-button-danger" />
        </div>;
    }
    openNew() {
        this.setState({
            session: this.emptySession,
            submitted: false,
            sessionDialog: true
        });
    }
    hideDialog() {
        this.setState({
            submitted: false,
            sessionDialog: false
        });
    }
    hideDeleteSessionDialog() {
        this.setState({ deleteSessionDialog: false });
    }
    saveSession() {
        let state = { submitted: true };

        if (this.state.session.idDirection && this.state.session.statutSession && this.state.session.dateOuverture) {

            const newSession = {
                idSessionCenou : parseInt(this.state.session.idSessionCenou) ,
                etatSession : this.state.session.etatSession,
                idDirection : parseInt(this.state.session.idDirection),
                statutSession : this.state.session.statutSession,
                dateOuverture : this.state.session.dateOuverture,
                dateFermeture : this.state.session.dateFermeture
            }
            console.log(newSession)
            if (this.state.session.idSessionCenou) {
                this.sessionService.update(parseInt(this.state.session.idSessionCenou), newSession)
                .then(data => {
                    this.notify();
                    this.setState({redirect : true})
                })
                .catch(error => {
                    console.log(error);
                }) 

            }
            else {
                this.sessionService.create(newSession)
                .then(data => {
                    this.notify();
                    this.setState({redirect : true})
                })
                .catch(error => {
                    console.log(error);
                })
                
            }

            state = {
                ...state,
                sessionDialog: false,
                session: this.emptySession
            };
        }

        this.setState(state);
    }
    editSession(session) {
        this.setState({
            session,
            sessionDialog: true
        });
    }
    deleteSession(idSessionCenou) {
        this.sessionService.delete(parseInt(idSessionCenou))
        .then(data => {
            this.setState({
                deleteSessionDialog: false,
                session: this.emptySession
            });
            this.notify();
            this.setState({redirect : true})
        })
        .catch(error => {
            console.log(error);
        })
        
    }
    confirmDeleteSession(session) {
        this.setState({
            session,
            deleteSessionDialog: true
        });
    }
    onEtatSessionChange(e, etatSession) {
        const val = (e.target && e.target.value) || '';
        let session = this.state.session;
        session[`${etatSession}`] = val;

        this.setState({session });
    }
    onIdDirectionChange(e, idDirection) {
        const val = (e.target && e.target.value) || '';
        let session = this.state.session;
        session[`${idDirection}`] = parseInt(val);

        this.setState({session });
    }
    onStatutSessionChange(e, statutSession) {
        const val = (e.target && e.target.value) || '';
        let session = this.state.session;
        session[`${statutSession}`] = val;

        this.setState({session });
    }
    onDateOuvertureChange(e, dateOuverture) {
        const val = (e.target && e.target.value) || '';
        let session = this.state.session;
        session[`${dateOuverture}`] = val;

        this.setState({session });
    }
    onDateFermetureChange(e, dateFermeture) {
        const val = (e.target && e.target.value) || '';
        let session = this.state.session;
        session[`${dateFermeture}`] = val;

        this.setState({session });
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editSession(rowData)} style={{marginRight: '.5em'}}/>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteSession(rowData)} />
            </React.Fragment>
        );
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                <Button label="Supprimer" icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} />
            </React.Fragment>
        )
    }
    notify = () => toast.success('Opération réuçit!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    render() {
        if(this.state.redirect){
            //window.location.reload(false);
        }
        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        const sessionDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveSession} />
            </React.Fragment>
        );
        const deleteSessionDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteSessionDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteSession(this.state.session.idSessionCenou) } />
            </React.Fragment>
        );
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-2">
                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                        </div>
                        <div className="p-col-2">
                            <Button label="Rafraichir" icon="pi pi-refresh" className="" onClick={()=>window.location.reload(false)} />
                        </div>
                    </div>
                    
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeSession} selectionMode="single" header="Liste des sessions" 
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="libelleDirection" header="Direction" sortable={true} filter={true} filterPlaceholder="rechercher direction"/>
                            <Column field="etatSession" header="Session" sortable={true} filter={true} filterPlaceholder="rechercher session"/>
                            <Column field="statutSession" header="Statut" sortable={true} filter={true} filterPlaceholder="rechercher statut"/>
                            <Column field="dateOuverture" header="Date ouverture" sortable={true} filter={true} filterPlaceholder="rechercher date ouverture"/>
                            <Column field="dateFermeture" header="Date fermeture" sortable={true} filter={true} filterPlaceholder="rechercher date fermeture"/>
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                    </div>
                </div>

                <Dialog visible={this.state.sessionDialog} style={{ width: '500px', height:"400px" }} header="Informations session" modal className="p-fluid" footer={sessionDialogFooter} onHide={this.hideDialog}>
                    <div className="p-field">
                        <label htmlFor="etatSession">Session</label>
                        <Dropdown optionLabel="etatSession" optionValue="etatSession" value={this.state.session.etatSession} options={this.state.listeEtatSession} onChange={(e) => {this.onEtatSessionChange(e, 'etatSession')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner une session"/>
                        {this.state.submitted && !this.state.session.etatSession && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="statut">Statut</label>
                        <Dropdown optionLabel="statutSession" optionValue="statutSession" value={this.state.session.statutSession} options={this.state.listeStatutSession} onChange={(e) => {this.onStatutSessionChange(e, 'statutSession')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un staut"/>
                        {this.state.submitted && !this.state.session.statutSession && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="etatSession">Direction</label>
                        <Dropdown optionLabel="libelleDirection" optionValue="idDirection" value={this.state.session.idDirection} options={this.state.listeDirection} onChange={(e) => {this.onIdDirectionChange(e, 'idDirection')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner une direction"/>
                        {this.state.submitted && !this.state.session.idDirection && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="dateOuverture">Date ouverture</label>
                        <InputText type="date" id="dateOuverture" value={this.state.session.dateOuverture}  onChange={(e) => this.onDateOuvertureChange(e, 'dateOuverture')}  />
                    </div>
                    <div className="p-field">
                        <label htmlFor="dateFermeture">Date fermeture</label>
                        <InputText type="date" id="dateFermeture" value={this.state.session.dateFermeture}  onChange={(e) => this.onDateFermetureChange(e, 'dateFermeture')}  />
                    </div>
                </Dialog>
                <Dialog visible={this.state.deleteSessionDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteSessionDialogFooter} onHide={this.hideDeleteSessionDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.session && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
            </div> 
        )
    }
}

export default Session
