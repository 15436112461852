import React, { Component } from 'react'
import Axios from 'axios'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';
import {Button} from 'primereact/button';

export class EditDossier extends Component {
    state = {
        dossierEtudiant : {},
        listeDossierCritere : new Array(),
        listeDossierCritereEtudiant : [],
        idCritere : null,
        nomFichier : null,
        fichier : null,
        redirect : false,
        loading : false,
    }

    componentDidMount = () => {
        const data = localStorage.getItem('user')
        const user = JSON.parse(data)
        let idDossier = this.props.match.params.idDossier
        
        /*Axios.get("http://hebergement.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/dossier?idDossier="+idDossier+"&ineEtudiant="+user.username)
        .then(res => {
            this.setState({dossierEtudiant : res.data, listeDossierCritere : res.data.listeDossierCritere, listeDossierCritereEtudiant : res.data.listeDossierCritere})
            
        })*/
        const dossierEtudiant = {
            idDossier: 19,
            statutDossier: "RECEPTIONNER",
            typeDossier: "ADMISSION",
            dateDepot : "22/10/2020",
            idTypeCritere : 3,
            listeDossierCritere : [
                {
                    idCritere : 30, 
                    nomFichier : "fichier30.pdf", 
                    fichier:"YZETTEEYUUJHH",
                    libelleCritere : "Burkinabè",
                    idTypeCritere : 2,
                    listeCritere : [
                        {idCritere : 30, libelleCritere : "Burkinabè"},
                        {idCritere : 301, libelleCritere : "Pays de l'UEMOA"},
                        {idCritere : 302, libelleCritere : "Pays AOUF(sans UEMOA)"},
                        {idCritere : 303, libelleCritere : "Autres"},
                    ]
                },
                {idCritere : 31, nomFichier : "fichier31.pdf", fichier:"ATEBENNEJJE?E"},
                {idCritere : 32, nomFichier : "fichier32.pdf", fichier:"BHSHDGEYUJEN"},
                {idCritere : 33, nomFichier : "fichier33.pdf", fichier:"CJSJJGEGDBHE"},
                {idCritere : 13, nomFichier : "fichier13.pdf", fichier:"DHNSYZRSSHSJZJ"},
                {idCritere : 18, nomFichier : null, fichier:null},
                {idCritere : 20, nomFichier : null, fichier:null},
                {idCritere : 15, nomFichier : "fichier15.pdf", fichier:"FSHSHDFDGSHH"},
                {idCritere : 25, nomFichier : null, fichier:null},

            ],
            idEtudiant: 10,
            ine: "N023444899",
            nomEtudiant: "BAKOUAN",
            prenomEtudiant: "Jean jacques"
        }
    }

    onEdit = () => {
        const newDepot = {

        }
        const url = 'http://192.168.43.106:8083/service/api/hebergement-cenou/campus-faso/dossier'
        Axios.put(url,newDepot).then(res => { 
            this.setState({redirect : true, loading : false})
            this.notify();
        })
        .catch(function (error) {
            console.log(error);
        })
    }
    onFileChange = (event, i) => { 
        let file = event.target.files[0]
        if(file){
            
            this.setState({nomFichier : file.name})
            this.state.listeDossierCritere[i].nomFichier = file.name
            const reader = new FileReader()
            reader.onload = readerEvt => {
                let binaryString = readerEvt.target.result
                let fichier = btoa(binaryString)
                this.setState({fichier : fichier})
                this.state.listeDossierCritere[i].fichier = fichier
            }
            
            reader.readAsBinaryString(file)
             
        }
        
    }
    render() {
        return (
            <div className="p-grid p-lg-12 resolution-center p-fluid">
                    {this.state.loading ? 
                        this.loadingSpinner("spin","black")
                        :
                    <div>
                        <div className="card p-lg-12">
                            <h1>Mise à jour de dossier
                                {this.state.redirect ?
                                    <Link style={{marginLeft :"20px"}} to="/tableau-de-bord">Consulter votre tableau de bord</Link>
                                        :
                                        ''
                                }
                            </h1>
                            
                        </div> 
                        <ToastContainer
                            position="top-right"
                            autoClose={10000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                    <ToastContainer />
                        <div className=" " style={{width : "50px"}} ></div>
                            {this.state.verife ?
                            <form className="p-grid ">
                                
                                <div className="p-lg-5" ></div>
                                
                                    <div className="p-lg-5 card " >
                                    {this.state.listeDossierCritere.map((dossierCritere,i) =>
                                        <div key={i} className="p-grid">
                                        <div className="p-lg-4"> 
                                            <label>{dossierCritere.libelleTypeCritere}</label>
                                            <Dropdown optionLabel="libelleCritere" optionValue="idCritere" value={dossierCritere.idCritere}  options={dossierCritere.listeCritere} 
                                                onChange={(e)=>{
                                                    this.setState({idCritere :  e.value})
                                                    this.state.listeDossierCritere[i].idCritere  = e.value
                                                    
                                                }} 
                                            />
                                        </div>
                                        <div className="p-lg-4"> 
                                                <label>Ancienne pièce {dossierCritere.libelleTypeCritere}</label>
                                                <InputText type="text" value={dossierCritere.nomFichier}/>
                                        </div>
                                        <div className="p-lg-4"> 
                                                <label>Nouvelle pièce {dossierCritere.libelleTypeCritere}</label>
                                                <input type="file" 
                                                    onChange={(e)=>{
                                                     this.onFileChange(e,i)
                                                    }
                                                }
                                                />
                                        </div>
                                        </div>
                                        )}
                                    </div>
                                    
                                        
                                <div className="card p-lg-12">
                                    <div className="p-grid"> 
                                        <div className="p-lg-5"> </div>
                                        <div className="p-lg-2 ">
                                            <Button  type="button" onClick={this.DepotDossier} label="Deposer" icon="pi pi-check"/>
                                        </div>
                                    </div>
                                </div>   
                            </form> 
                            :
                            ""
                            }
                            
                    </div> 
                        }
                    </div>  
        )
    }
}

export default EditDossier
