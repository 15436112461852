import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { BatimentService } from '../../service/BatimentService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import { CiteService } from '../../service/CiteService';
import { NiveauService } from '../../service/NiveauService';
import { CouloirService } from '../../service/CouloirService';


export class Couloir extends Component {

    emptyCouloir = {
        idCouloir : 0,
        idNiveau : 0,
        idBatiment  : 0,
        idCite : 0,
        codeCouloir : "",
        libelleCouloir : ""
    };
    constructor() {
        super();
        this.state = {
            listeCite : [],
            listeBatiment : [],
            listeNiveau : [],
            listeCouloir : [],
            couloirDialog: false,
            deleteCouloirDialog: false,
            couloir: this.emptyCouloir,
            submitted: false,
            redirect : false,
            loading : false,
            globalFilter: null
        }

        this.batimentService = new BatimentService();
        this.citeService = new CiteService();
        this.niveauService = new NiveauService();
        this.couloirService = new CouloirService();

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveCouloir = this.saveCouloir.bind(this);
        this.editCouloir = this.editCouloir.bind(this);
        this.confirmDeleteCouloir = this.confirmDeleteCouloir.bind(this);
        this.deleteCouloir = this.deleteCouloir.bind(this);
        this.onLibelleCouloirChange = this.onLibelleCouloirChange.bind(this);
        this.onCodeCouloirChange = this.onCodeCouloirChange.bind(this);
        this.onIdCiteChange = this.onIdCiteChange.bind(this);
        this.onIdBatimentChange = this.onIdBatimentChange.bind(this);
        this.onIdNiveauChange = this.onIdNiveauChange.bind(this);
        this.hideDeleteCouloirDialog = this.hideDeleteCouloirDialog.bind(this);
    }
    componentDidMount() {
        this.setState({loading : true})
        this.couloirService.getAll().then(data => {
            
            this.setState({listeCouloir: data, loading : false})
        })
            .catch(error => {
                this.notify2()  
                console.log(error)
                this.setState({loading : false})
            })

        this.citeService.getAll().then(data => {
            //console.log(data)
            this.setState({listeCite: data})
        })
            .catch(error => {this.notify2() ; console.log(error)})
        
    }
    
    openNew() {
        this.setState({
            couloir: this.emptyCouloir,
            submitted: false,
            couloirDialog: true
        });
    }
    hideDialog() {
        this.setState({
            submitted: false,
            couloirDialog: false
        });
    }
    hideDeleteCouloirDialog() {
        this.setState({ deleteCouloirDialog: false });
    }
    saveCouloir() {
        let state = { submitted: true };

        if (this.state.couloir.idNiveau && this.state.couloir.libelleCouloir.trim()) {
            //alert("yo !")
            const newCouloir = {
                idCouloir : parseInt(this.state.couloir.idCouloir) ,
                idNiveau : parseInt(this.state.couloir.idNiveau) ,
                libelleCouloir : this.state.couloir.libelleCouloir,
                codeCouloir : this.state.couloir.codeCouloir
            }
            console.log(newCouloir)
            if (this.state.couloir.idCouloir) {
                this.couloirService.update(parseInt(this.state.couloir.idCouloir
                    ), newCouloir)
                                .then(data => {
                                    this.notify();
                                    //console.log(data)
                                    this.setState({redirect : true})
                                })
                                .catch(error => {
                                    this.notify2()
                                    console.log(error);
                                })
            }
            else {
                this.couloirService.create(newCouloir).then(data => {
                    this.notify();
                    this.setState({redirect : true})
                })
                .catch(error => {
                    this.notify2()
                    console.log(error);
                })
                
            }

            state = {
                ...state,
                couloirDialog: false,
                couloir: this.emptyCouloir
            };
        }

        this.setState(state);
    }
    editCouloir(couloir) {
        this.loadBatiment(couloir.idCite)
        this.loadNiveau(couloir.idBatiment)
        this.setState({
            couloir,
            couloirDialog: true
        });
    }
    confirmDeleteCouloir(couloir) {
        this.setState({
            couloir,
            deleteCouloirDialog: true
        });
    }
    deleteCouloir(idCouloir) {
        this.couloirService.delete(parseInt(idCouloir)).then(data => {
            this.notify();
            this.setState({
                deleteCouloirDialog: false,
                couloir: this.emptyCouloir,
                redirect : true
            });
        })
    }
    
    onLibelleCouloirChange(e, libelleCouloir) {
        const val = (e.target && e.target.value) || '';
        let couloir = this.state.couloir;
        couloir[`${libelleCouloir}`] = val;

        this.setState({couloir });
    }
    onIdCiteChange(e, idCite) {
        const val = (e.target && e.target.value) || '';
        let couloir = this.state.couloir;
        couloir[`${idCite}`] = val;

        this.setState({ couloir });
    }
    onIdBatimentChange(e, idBatiment) {
        const val = (e.target && e.target.value) || '';
        let couloir = this.state.couloir;
        couloir[`${idBatiment}`] = val;

        this.setState({ couloir });
    }
    onIdNiveauChange(e, idNiveau) {
        const val = (e.target && e.target.value) || '';
        let couloir = this.state.couloir;
        couloir[`${idNiveau}`] = val;
        console.log(val)
        this.setState({ couloir });
    }
    onCodeCouloirChange(e, codeCouloir) {
        const val = (e.target && e.target.value) || '';
        let couloir = this.state.couloir;
        couloir[`${codeCouloir}`] = val;

        this.setState({ couloir });
    }
    loadBatiment = (idCite) => {
        this.batimentService.get(idCite).then(data => {
            this.setState({listeBatiment : data})
        })
    }
    loadNiveau = (idBatiment) => {
        this.niveauService.get(idBatiment).then(data => {
            console.log(data)
            this.setState({listeNiveau : data})
        })
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editCouloir(rowData)} style={{marginRight: '.5em'}}/>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteCouloir(rowData)} />
            </React.Fragment>
        );
    }
    notify = () => toast.success('Opération réuçit!', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    notify2 = () => toast.error("Echec de l'opération !", {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
    loadingSpinner = ( type, color) => (
        <ReactLoading type={type} color={color} height={200} width={50} />
    )
    render() {

        if(this.state.redirect) {
            //window.location.reload(false);
        }
        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        const couloirDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveCouloir} />
            </React.Fragment>
        );
        const deleteCouloirDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteCouloirDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteCouloir(this.state.couloir.idCouloir) } />
            </React.Fragment>
        );
        
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-2">
                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                        </div>
                        <div className="p-col-2">
                            <Button label="Rafraichir" icon="pi pi-refresh" className="" onClick={()=>window.location.reload(false)} />
                        </div>
                    </div>
    
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.loadingSpinner("spin","black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeCouloir} selectionMode="single" header="Liste des couloirs" paginator={true} rows={5}
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="libelleCouloir" header="Couloir" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher couloir"/>       
                            <Column field="libelleNiveau" header="Niveau" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher niveau"/>
                            <Column field="libelleBatiment" header="Bâtiment" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher bâtiment"/>
                            <Column field="nomCite" header="Cité" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher cité"/>
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        }
                    </div>
                </div>

                <Dialog visible={this.state.couloirDialog} style={{ width: '450px' }} header="Informations couloir" modal className="p-fluid" footer={couloirDialogFooter} onHide={this.hideDialog}>
                    <div className="p-field">
                        <label htmlFor="nomCite">Cité</label>
                        <Dropdown optionLabel="nomCite" optionValue="idCite" value={this.state.couloir.idCite} options={this.state.listeCite} onChange={(e) => {this.loadBatiment(e.value); this.onIdCiteChange(e, 'idCite') }}  placeholder="Selectionner une cité"/>
                    </div>
                    <div className="p-field">
                        <label htmlFor="libelleBatiment">Nom du Bâtiment</label>
                        <Dropdown optionLabel="libelleBatiment" optionValue="idBatiment" value={this.state.couloir.idBatiment} options={this.state.listeBatiment} onChange={(e) => {this.loadNiveau(e.value); this.onIdBatimentChange(e, 'idBatiment')}} autoFocus  placeholder="Selectionner un bâtiment"/>
                    </div>
                    <div className="p-field">
                        <label htmlFor="libelleBatiment">Niveau</label>
                        <Dropdown optionLabel="libelleNiveau" optionValue="idNiveau" value={this.state.couloir.idNiveau} options={this.state.listeNiveau} onChange={(e) => { this.onIdNiveauChange(e, 'idNiveau')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })}  placeholder="Selectionner un niveau"/>
                        {this.state.submitted && !this.state.couloir.idCouloir && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="libelleCouloir">Libellé couloir</label>
                        <InputText id="libelleCouloir" value={this.state.couloir.libelleCouloir}  onChange={(e) => this.onLibelleCouloirChange(e, 'libelleCouloir')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })}   />
                        {this.state.submitted && !this.state.couloir.libelleCouloir && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="codeCouloir">Code couloir</label>
                        <InputText id="codeCouloir" value={this.state.couloir.codeCouloir}  onChange={(e) => this.onCodeCouloirChange(e, 'codeCouloir')}   />
                    </div>
                </Dialog>
                <Dialog visible={this.state.deleteCouloirDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteCouloirDialogFooter} onHide={this.hideDeleteCouloirDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.couloir && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
            </div> 
        )
    }
}

export default Couloir
