import React, { Component } from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion';
export class Faq extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIndex: null
        }
    }

    onClick(itemIndex) {
        let activeIndex = this.state.activeIndex ? [...this.state.activeIndex] : [];

        if (activeIndex.length === 0) {
            activeIndex.push(itemIndex);
        }
        else {
            const index = activeIndex.indexOf(itemIndex);
            if (index === -1) {
                activeIndex.push(itemIndex);
            }
            else {
                activeIndex.splice(index, 1);
            }
        }

        this.setState({ activeIndex });
    }
    render() {
        return (
            <div className="accordion-demo p-lg-12 p-grid">
                <div className=" p-lg-2"></div>
                <div className="card p-lg-8">
                    <h1 style={{textAlign : "center"}}>FAQ "Hébergement CENOU"</h1>
                    <h3>Dernière mise à jour 05/10/2021</h3>
                    <Accordion>
                        <AccordionTab header="Qui est éligible pour la demande de l’hébergement universitaire ?">
                            <h3 style={{color : "green"}}>Réponse</h3>
                            <p>Les étudiants inscrits au titre des années académiques 2018-2019 ; 2019-2020 ; 2020-2021; 2021-2022</p>
                            <h3 style={{color : "red"}}>Méssage d'erreur</h3>
                            <p>
                                <ul> 
                                    <li>Votre INE est invalide, réessayez !</li>
                                    <li>Un compte existe dejà avec le même INE</li>
                                </ul>
                                </p>
                        </AccordionTab>
                        <AccordionTab header="Qui est autorisé à déposer son dossier d’hébergement en ligne ?">
                            <h3 style={{color : "green"}}>Réponse</h3>
                            <p>Les étudiants orientés dans les régions où il y’a au moins une cité ou une résidence universitaire</p>
                            <h3 style={{color : "black"}}>Observations</h3>
                            <p>
                                <ul>
                                    <li>Bobo-Dioulasso</li>
                                    <li>Koudougou </li>
                                    <li>Ouagadougou </li>
                                </ul>
                            </p>
                            <h3 style={{color : "red"}}>Méssage d'erreur</h3>
                            <p>Vous n’êtes pas autorisé à faire un depôt car votre ville d'orientation ne dispose pas de cité(s) universitaire pour le moment !</p>
                        </AccordionTab>
                        <AccordionTab header="Quel est le lien de la plateforme de demande de logements universitaire ?">
                            <h3 style={{color : "green"}}>Réponse</h3>
                            <p>
                                <a target="blank" href="http://hebergement.campusfaso.bf">http://hebergement.campusfaso.bf</a> ou sur
                                <a target="blank" href="https://www.campusfaso.bf"> https://www.campusfaso.bf</a>
                                </p>
                            <h3 style={{color : "black"}}>Observations</h3>
                            <p>Choisir ensuite l’onglet « hébergement »</p>
                            <h3 style={{color : "red"}}>Méssage d'erreur</h3>
                            <p>
                                <ul>
                                    <li>Ce site est inaccessible</li>
                                    <li>Verifiez votre connexion internet</li>
                                </ul> 
                            </p>
                        </AccordionTab>
                        <AccordionTab header="Quels sont les informations nécessaires pour pouvoir effectuer l’inscription sur la plateforme ?">
                            <h3 style={{color : "green"}}>Réponse</h3>
                            <p>l’Identifiant National d'Etudiant (INE)</p>
                            <h3 style={{color : "black"}}>Observations</h3>
                            <p>Un mot de passe est envoyé sur la boîte gmail que l’étudiant doit récupérer pour se connecter dans son espace pour le dépôt de son dossier</p>
                            <h3 style={{color : "red"}}>Méssage d'erreur</h3>
                            <p>Votre INE est invalide, réessayez !</p>
                        </AccordionTab>
                        <AccordionTab header="Quels sont les informations nécessaires pour se connecter dans son compte hébergement ?">
                            <h3 style={{color : "green"}}>Réponse</h3>
                            <p>L’INE et le mot de passe récupérés  sur le compte gmail</p>
                            <h3 style={{color : "red"}}>Méssage d'erreur</h3>
                            <p>
                                <ul>
                                    <li>Connexion impossible réessayer !</li>
                                    <li>Compte inexistant</li>
                                </ul>
                            </p>
                        </AccordionTab>
                        <AccordionTab header="Que faire si vous n’avez pas d’INE ?">
                            <h3 style={{color : "green"}}>Réponse</h3>
                            <p>Rendez-vous sur la pateforme <a target="blank" href="https://www.campusfaso.bf">https://www.campusfaso.bf</a> </p>
                        </AccordionTab>
                        <AccordionTab header="Que faire si vous n’arrivez pas à vous connecter sur le lien de la  plateforme ?">
                            <h3 style={{color : "green"}}>Réponse</h3>
                            <p>
                                <ul>
                                    <li>Vérifiez d’abord si le lien est correct</li>
                                    <li>Vérifiez votre connexion et réessayez</li>
                                </ul>
                            </p>
                        </AccordionTab>
                        <AccordionTab header="Vous n’avez pas reçu de mot de passe après votre inscription pour l’hébergement universitaire, que faire ?">
                            <h3 style={{color : "green"}}>Réponse</h3>
                            <p>
                                <ul>
                                    <li>Verifiez votre connexion internet</li>
                                    <li>Actualiser la page de votre mail</li>
                                    <li>Verifiez votre spam</li>
                                    <li>Contacter campusfaso au : (+226) 07 76 76 76 / 52 36 69 71 / 58 50 56 55</li>
                                </ul>
                            </p>
                        </AccordionTab>
                        <AccordionTab header="Vous  voulez  utiliser  une autre adresse gmail différente de celle utilisée à votre inscription sur Campusfaso, que faire ?">
                            <h3 style={{color : "green"}}>Réponse</h3>
                            <p>
                                <ul>
                                    <li>A l'etape de l'inscription vous pouvez changer de mail</li>
                                    <li>Contacter campusfaso au : (+226) 07 76 76 76 / 52 36 69 71 / 58 50 56 55</li>
                                </ul>
                            </p>
                        </AccordionTab>
                        <AccordionTab header="Vous n’avez pas reçu la notification du dépôt de votre dossier  après votre inscription que faire ?">
                            <h3 style={{color : "green"}}>Réponse</h3>
                            <p>
                                <ul>
                                    <li>Verifiez votre connexion internet</li>
                                    <li>Actualiser la page de votre mail</li>
                                    <li>Verifiez votre spam</li>
                                    <li>Contacter campusfaso au : (+226) 07 76 76 76 / 52 36 69 71 / 58 50 56 55</li>
                                </ul>
                            </p>
                        </AccordionTab>
                        <AccordionTab header="Quels sont les types de format et la taille du fichier chargeables sur la plateforme d’hébergement">
                            <h3 style={{color : "green"}}>Réponse</h3>
                            <p>
                                <ul>
                                    <li>Seul les documents en pdf sont autorisés</li>
                                    <li>La taille de chaque document ne doit pas exceder 3Mo</li>
                                </ul>
                            </p>
                            <h3 style={{color : "red"}}>Méssage d'erreur</h3>
                            <p>
                                <ul>
                                    <li>vous devriez renseigner un fichier pdf</li>
                                    <li>la taille du fichier ne doit pas depasser 3Mo</li>
                                </ul>
                            </p>
                        </AccordionTab>
                        <AccordionTab header="Quel justificatif charger pour les PDI(Pdi/simple) ?">
                            <h3 style={{color : "green"}}>Réponse</h3>
                            <p>
                                <ul>
                                    <li>Document delivré par l'Action Social justifiant la situation de PDI</li>
                                    <li>Tout autre acte justifiant la situation de PDI</li>
                                </ul>
                            </p>
                            <h3 style={{color : "black"}}>Observations</h3>
                            <p>Tant que vous n’avez pas joint de justificatif, vous ne pouvez pas déposer</p>
                        </AccordionTab>
                        <AccordionTab header="Quel justificatif charger pour les PDI(Pdi/orphélin) ?">
                            <h3 style={{color : "green"}}>Réponse</h3>
                            <p>
                                <ul>
                                    <li>Certificat de décès du parent en plus d’un document délivré par l’action sociale justifiant la situation de PDI</li>
                                </ul>
                            </p>
                            <h3 style={{color : "black"}}>Observations</h3>
                            <p>Tant que vous n’avez pas joint de justificatif, vous ne pouvez pas déposer</p>
                        </AccordionTab>
                        <AccordionTab header="Quel justificatif charger pour le métier des parents ?">
                            <h3 style={{color : "green"}}>Réponse</h3>
                            <p>
                                <ul>
                                    <li>Actes administratifs</li>
                                    <li>Bulletin de salaire</li>
                                    <li>CNIB</li>
                                    <li>Tout autre acte justifiant le métier</li>
                                </ul>
                            </p>
                            <h3 style={{color : "black"}}>Observations</h3>
                            <p>Tant que vous n’avez pas joint de justificatif, vous ne pouvez pas déposer</p>
                        </AccordionTab>
                        <AccordionTab header="Quel justificatif charger pour  le statut de l’étudiant ?">
                            <h3 style={{color : "green"}}>Réponse</h3>
                            <p>
                                <ul>
                                    <li>Si vous êtes orphélin joindre le certificat de décès du père et/ou de la mère</li>
                                    <li>Si vous êtes non orphélin il n'y a pas de justificatif à fournir</li>
                                </ul>
                            </p>
                            <h3 style={{color : "black"}}>Observations</h3>
                            <p>
                                <ul>
                                    <li>Si vous avez choisi que vous êtes orphélin le justificatif est obligatoire sinon vous ne pouvez pas déposer</li>
                                    <li>Celui qui est non orphélin, peut deposer sans un justificatif</li>
                                </ul>
                            </p>
                        </AccordionTab>
                        <AccordionTab header="Quel justificatif charger pour pupille de la nation ?">
                            <h3 style={{color : "green"}}>Réponse</h3>
                            <p>
                                <ul>
                                  le décret portant statut de pupille de la nation
                                </ul>
                            </p>
                            <h3 style={{color : "black"}}>Observations</h3>
                            <p>
                                <ul>
                                    Si vous avez choisi que vous êtes pupille de la nation, le justificatif est obligatoire sinon vous ne pouvez pas déposer
                                </ul>
                            </p>

                        </AccordionTab>
                        <AccordionTab header="Quel justificatif charger pour les étudiants handicapés ?">
                            <h3 style={{color : "green"}}>Réponse</h3>
                            <p>
                                <ul>
                                le certificat d’invalidité
                                </ul>
                            </p>
                            <h3 style={{color : "black"}}>Observations</h3>
                            <p>
                                <ul>
                                    Si vous avez choisi que vous êtes handicapé, le justificatif est obligatoire sinon vous ne pouvez pas déposer
                                </ul>
                            </p>
                        </AccordionTab>
                        <AccordionTab header="Quel justificatif charger pour les sportifs de haut niveau ?">
                            <h3 style={{color : "green"}}>Réponse</h3>
                            <p>
                                <ul>
                                    la licence de sport
                                </ul>
                            </p>
                            <h3 style={{color : "black"}}>Observations</h3>
                            <p>
                                <ul>
                                    Si vous avez choisi que vous êtes sportif de haut niveau, le justificatif est obligatoire sinon vous ne pouvez pas déposer
                                </ul>
                            </p>
                        </AccordionTab>
                        <AccordionTab header="Quel justificatif charger pour les artistes  de haut niveau ?">
                            <h3 style={{color : "green"}}>Réponse</h3>
                            <p>
                                <ul>
                                l’attestation de statut d’artiste de haut niveau 
                                </ul>
                            </p>
                            <h3 style={{color : "black"}}>Observations</h3>
                            <p>
                                <ul>
                                Si vous avez choisi que vous êtes artiste de haut niveau, le justificatif est obligatoire sinon vous ne pouvez pas déposer
                                </ul>
                            </p>
                        </AccordionTab>
                        <AccordionTab header="Quel justificatif charger pour les étudiants indigents ?">
                            <h3 style={{color : "green"}}>Réponse</h3>
                            <p>
                                <ul>
                                le certificat d’indigence 
                                </ul>
                            </p>
                            <h3 style={{color : "black"}}>Observations</h3>
                            <p>
                                <ul>
                                Si vous avez choisi que vous êtes un etudiant indigent, le justificatif est obligatoire sinon vous ne pouvez pas déposer
                                </ul>
                            </p>
                        </AccordionTab>
                        <AccordionTab header="Quel justificatif charger pour le lieu de résidence ?">
                            <h3 style={{color : "green"}}>Réponse</h3>
                            <p>
                                <ul>
                                il n'y a pas de justificatif !
                                </ul>
                            </p>
                        </AccordionTab>
                        <AccordionTab header="Quel justificatif charger pour la serie du BAC et la mention du BAC ?">
                            <h3 style={{color : "green"}}>Réponse</h3>
                            <p>
                                <ul>
                                    <li>la mention passable peut ne pas être justifiée mais les autres mentions doivent être justifiées sinon vous ne pouvez pas deposer.</li>
                                    <li>La justification pour la serie du BAC n'est pas obligatoire</li>
                                </ul>
                            </p>
                        </AccordionTab>
                        <AccordionTab header="Quel justificatif charger pour le nombre de frère et sœur de l’étudiant en charge ?">
                            <h3 style={{color : "green"}}>Réponse</h3>
                            <p>
                                <ul>
                                pas de justificatif !
                                </ul>
                            </p>
                        </AccordionTab>
                        <AccordionTab header="Quel justificatif charger pour la nationalité ?">
                            <h3 style={{color : "green"}}>Réponse</h3>
                            <p>
                                <ul>
                                le certificat de nationalité ou la CNIB
                                </ul>
                            </p>
                            <h3 style={{color : "black"}}>Observations</h3>
                            <p>
                                <ul>
                                Tant que vous n’avez pas joint de justificatif, vous ne pouvez pas déposer
                                </ul>
                            </p>
                        </AccordionTab>
                        <AccordionTab header="Quel justificatif chargé pour le niveau d’étude ?">
                            <h3 style={{color : "green"}}>Réponse</h3>
                            <p>
                                <ul>
                                    pas de justificatif !
                                </ul>
                            </p>
                        </AccordionTab>
                        <AccordionTab header="Quel justificatif charger pour l’âge ?">
                            <h3 style={{color : "green"}}>Réponse</h3>
                            <p>
                                <ul>
                                    l’acte de naissance
                                </ul>
                            </p>
                            <h3 style={{color : "black"}}>Observations</h3>
                            <p>
                                <ul>
                                Tant que vous n’avez pas joint de justificatif, vous ne pouvez pas déposer
                                </ul>
                            </p>
                        </AccordionTab>
                    </Accordion>
                </div>
            </div>
        )
    }
}

export default Faq
