import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

export class AppTopbar extends Component {

	static defaultProps = {
		onMenuButtonClick: null,
		onTopbarMenuButtonClick: null,
		onTopbarItemClick: null,
		topbarMenuActive: false,
		activeTopbarItem: null,
		profileMode: null,
		horizontal: null
	}

	static propTypes = {
		onMenuButtonClick: PropTypes.func.isRequired,
		onTopbarMenuButtonClick: PropTypes.func.isRequired,
		onTopbarItemClick: PropTypes.func.isRequired,
		topbarMenuActive: PropTypes.bool.isRequired,
		activeTopbarItem: PropTypes.string,
		profileMode: PropTypes.string,
		horizontal: PropTypes.bool
	}

	constructor() {
		super();
		this.state = {user : {}, verife : false};
	}

	onTopbarItemClick(event, item) {
		if (this.props.onTopbarItemClick) {
			this.props.onTopbarItemClick({
				originalEvent: event,
				item: item
			});
		}
	}
	componentDidMount = () => {
		const data = localStorage.getItem('user')
		if(data){
			const user = JSON.parse(data)
			this.setState({user : user, verife : true})
		}
		
	}
	render() {
		let topbarItemsClassName = classNames('topbar-menu fadeInDown', {'topbar-menu-visible': this.props.topbarMenuActive});

		return <div className="layout-topbar clearfix" >
				<button className="layout-topbar-logo p-link">
					<img style={{width:"100px", height:"50px", marginTop : "-5px"}} id="layout-topbar-logo" alt="logo cenou" src="assets/layout/images/cenou.png"/>
				</button>
				<button className="layout-menu-button p-link"  onClick={this.props.onMenuButtonClick}>
					<i className="pi pi-bars"/>
				</button>
			{this.state.verife ?
			<ul className={topbarItemsClassName}  >
				{this.state.user.libelleDirection ?
				<div style={{marginLeft : "-900px", marginTop : "20px"}}>
					<span className="">{this.state.user.nom} {this.state.user.prenom +"(" + this.state.user.libelleDirection  +")"}</span>
				</div>
				:
				<div style={{marginLeft : "-200px", marginTop : "20px"}}>
					<span className="">{this.state.user.nom} {this.state.user.prenom}</span>
				</div>
				}
				{(this.props.profileMode === 'popup' || this.props.horizontal) &&
				<li className={classNames('user-profile', {'active-topmenuitem': this.props.activeTopbarItem === 'profile'})}
					onClick={(e) => this.onTopbarItemClick(e, 'profile')}>
					<button className="p-link" style={{marginTop:"-40px"}}>
						<img alt="logo-cenou" src="assets/layout/images/avatar_profile.jpg"/>
					</button>
					<ul className="fadeInDown">
						<li role="menuitem">
							<button className="p-link">
								<i className="pi pi-user"/>
								<span>Profile</span>
							</button>
						</li>
						<li role="menuitem">
						<Link >
						<button className="p-link"
							 onClick = { ()=>{
								localStorage.clear()
								window.location.assign("/")
								 }
								 
							}
							
						>
								<i className="pi pi-power-off"/>
								<span>Deconnexion</span>
							</button>
						</Link>
						
						</li>
					</ul>
				</li>}
			</ul>
			:
			""
			}
		</div>;
	}
}