import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { BatimentService } from '../../service/BatimentService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import { CiteService } from '../../service/CiteService';
import { NiveauService } from '../../service/NiveauService';


export class Niveau extends Component {

    emptyNiveau = {
        idNiveau : 0,
        idBatiment : 0,
        idCite : 0,
        codeNiveau : "",
        libelleNiveau : ""
    };
    constructor() {
        super();
        this.state = {
            listeCite : [],
            listeBatiment : [],
            listeNiveau : [],
            niveauDialog: false,
            deleteNiveauDialog: false,
            niveau: this.emptyNiveau,
            submitted: false,
            redirect : false,
            loading : false,
            globalFilter: null
        }

        this.batimentService = new BatimentService();
        this.citeService = new CiteService();
        this.niveauService = new NiveauService();

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveNiveau = this.saveNiveau.bind(this);
        this.editNiveau = this.editNiveau.bind(this);
        this.confirmDeleteNiveau = this.confirmDeleteNiveau.bind(this);
        this.deleteNiveau = this.deleteNiveau.bind(this);
        this.onLibelleNiveauChange = this.onLibelleNiveauChange.bind(this);
        this.onIdBatimentChange = this.onIdBatimentChange.bind(this);
        this.onCodeNiveauChange = this.onCodeNiveauChange.bind(this);
        this.hideDeleteNiveauDialog = this.hideDeleteNiveauDialog.bind(this);
    }
    componentDidMount() {
        this.setState({loading : true})
        this.niveauService.getAll().then(data => 
            this.setState({listeNiveau: data, loading : false})
            )
            .catch(error => {
                this.notify2()  
                console.log(error)
                this.setState({loading : false})
            })

        this.citeService.getAll().then(data => {
            //console.log(data)
            this.setState({listeCite: data})
        })
            .catch(error => {this.notify2() ; console.log(error)})
        
    }
    
    openNew() {
        this.setState({
            niveau: this.emptyNiveau,
            submitted: false,
            niveauDialog: true
        });
    }
    hideDialog() {
        this.setState({
            submitted: false,
            niveauDialog: false
        });
    }
    hideDeleteNiveauDialog() {
        this.setState({ deleteNiveauDialog: false });
    }
    saveNiveau() {
        let state = { submitted: true };

        if (this.state.niveau.idBatiment && this.state.niveau.libelleNiveau.trim()) {
            //alert("yo !")
            const newNiveau = {
                idNiveau : parseInt(this.state.niveau.idNiveau) ,
                idBatiment : parseInt(this.state.niveau.idBatiment) ,
                libelleNiveau : this.state.niveau.libelleNiveau,
                codeNiveau : this.state.niveau.codeNiveau
            }
            console.log(newNiveau)
            if (this.state.niveau.idNiveau) {
                this.niveauService.update(parseInt(this.state.niveau.idNiveau
                    ), newNiveau)
                                .then(data => {
                                    this.notify();
                                    console.log(data)
                                    this.setState({redirect : true})
                                })
                                .catch(error => {
                                    this.notify2()
                                    console.log(error);
                                })
            }
            else {
                this.niveauService.create(newNiveau).then(data => {
                    this.notify();
                    this.setState({redirect : true})
                })
                .catch(error => {
                    this.notify2()
                    console.log(error);
                })
                
            }

            state = {
                ...state,
                niveauDialog: false,
                niveau: this.emptyNiveau
            };
        }

        this.setState(state);
    }
    editNiveau(niveau) {
        this.loadBatiment(niveau.idCite)
        this.setState({
            niveau,
            niveauDialog: true
        });
    }
    confirmDeleteNiveau(niveau) {
        this.setState({
            niveau,
            deleteNiveauDialog: true
        });
    }
    deleteNiveau(idNiveau) {
        this.niveauService.delete(parseInt(idNiveau)).then(data => {
            this.notify();
            this.setState({
                deleteNiveauDialog: false,
                niveau: this.emptyNiveau,
                redirect : true
            });
        })
    }
    
    onLibelleNiveauChange(e, libelleNiveau) {
        const val = (e.target && e.target.value) || '';
        let niveau = this.state.batiment;
        niveau[`${libelleNiveau}`] = val;

        this.setState({niveau });
    }
    onIdCiteChange(e, idCite) {
        const val = (e.target && e.target.value) || '';
        let niveau = this.state.niveau;
        niveau[`${idCite}`] = val;

        this.setState({ niveau });
    }
    onIdBatimentChange(e, idBatiment) {
        const val = (e.target && e.target.value) || '';
        let niveau = this.state.niveau;
        niveau[`${idBatiment}`] = val;

        this.setState({ niveau });
    }
    onCodeNiveauChange(e, codeNiveau) {
        const val = (e.target && e.target.value) || '';
        let niveau = this.state.niveau;
        niveau[`${codeNiveau}`] = val;

        this.setState({ niveau });
    }
    onLibelleNiveauChange(e, libelleNiveau) {
        const val = (e.target && e.target.value) || '';
        let niveau = this.state.niveau;
        niveau[`${libelleNiveau}`] = val;

        this.setState({ niveau });
    }
    loadBatiment = (idCite) => {
        this.batimentService.get(idCite).then(data => {
            this.setState({listeBatiment : data})
        })
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editNiveau(rowData)} style={{marginRight: '.5em'}}/>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteNiveau(rowData)} />
            </React.Fragment>
        );
    }
    notify = () => toast.success('Opération réuçit!', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    notify2 = () => toast.error("Echec de l'opération !", {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
    loadingSpinner = ( type, color) => (
        <ReactLoading type={type} color={color} height={200} width={50} />
    )
    render() {

        if(this.state.redirect) {
            //window.location.reload(false);
        }
        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        const niveauDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveNiveau} />
            </React.Fragment>
        );
        const deleteNiveauDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteNiveauDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteNiveau(this.state.niveau.idNiveau) } />
            </React.Fragment>
        );
        
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-2">
                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                        </div>
                        <div className="p-col-2">
                            <Button label="Rafraichir" icon="pi pi-refresh" className="" onClick={()=>window.location.reload(false)} />
                        </div>
                    </div>
    
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.loadingSpinner("spin","black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeNiveau} selectionMode="single" header="Liste des niveaux" paginator={true} rows={5}
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="libelleNiveau" header="Niveau" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher batiment"/>
                            <Column field="codeNiveau" header="code niveau" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher code niveau"/>
                            <Column field="libelleBatiment" header="Bâtiment" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher batiment"/>
                            <Column field="nomCite" header="Cité" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher cité"/>
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        }
                    </div>
                </div>

                <Dialog visible={this.state.niveauDialog} style={{ width: '450px' }} header="Informations niveau" modal className="p-fluid" footer={niveauDialogFooter} onHide={this.hideDialog}>
                    <div className="p-field">
                        <label htmlFor="nomCite">Cité</label>
                        <Dropdown optionLabel="nomCite" optionValue="idCite" value={this.state.niveau.idCite} options={this.state.listeCite} onChange={(e) => {this.loadBatiment(e.value); this.onIdCiteChange(e, 'idCite') }}  placeholder="Selectionner une cité"/>
                    </div>
                    <div className="p-field">
                        <label htmlFor="libelleBatiment">Nom du Bâtiment</label>
                        <Dropdown optionLabel="libelleBatiment" optionValue="idBatiment" value={this.state.niveau.idBatiment} options={this.state.listeBatiment} onChange={(e) => {this.onIdBatimentChange(e, 'idBatiment')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })}  placeholder="Selectionner un bâtiment"/>
                        {this.state.submitted && !this.state.niveau.idBatiment && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="libelleNiveau">Libellé niveau</label>
                        <InputText id="libelleNiveau" value={this.state.niveau.libelleNiveau}  onChange={(e) => this.onLibelleNiveauChange(e, 'libelleNiveau')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })}   />
                        {this.state.submitted && !this.state.niveau.libelleNiveau && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="codeNiveau">Code niveau</label>
                        <InputText id="codeNiveau" value={this.state.niveau.codeNiveau}  onChange={(e) => this.onCodeNiveauChange(e, 'codeNiveau')}   />
                    </div>
                </Dialog>
                <Dialog visible={this.state.deleteNiveauDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteNiveauDialogFooter} onHide={this.hideDeleteNiveauDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.niveau && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
            </div> 
        )
    }
}

export default Niveau
