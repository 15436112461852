import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom';
import {Button} from 'primereact/button';
import Axios from 'axios'
import ReactLoading from 'react-loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { DossierService } from '../service/DossierService';
import { SessionService } from '../service/SessionService';
import { AnalyseService } from '../service/AnalyseService';

export class Analyse extends Component {
    emptyDossierAnalyse = {
        idCritere : 0,
        idTypeCritere : 0,
        libelleCritere : "",
        libelleTypeCritere : "",
        nomFichier : "",
        fichier : "",
        ine : "",
        idDossier : null,
        nomEtudiant : "",
        prenomEtudiant : ""
    }
    constructor(){
        super()
        this.state = {
            ine : "",
            listeDeopt : [],
            dossierEtudiant : {},
            listeDossierCritere : new Array(),
            listeDossierCritereEtudiant : [],
            listeValeurAnalyse : [
                {idValeurAnalyse : 1, valeurAnalyse : "VALIDE"},
                {idValeurAnalyse : 2, valeurAnalyse : "INVALIDE"},
                {idValeurAnalyse : 3, valeurAnalyse : "INJUSTIFIE"}
            ],
            dossier : [],
            verifeAnalyse : false,
            dossierAnalyse : this.emptyDossierAnalyse,
            dossierAnalyseDialog: false,
            globalFilter: null,
            loading : false,
            loading2 : false,
            redirect : false,
            analyse : false,
            submitted: false,
            fileBase64 : null,
            extension : false,
            fileClick : false,
            longueur : 650,
            largeur : 700,
           
        }
        this.dossierService = new DossierService();
        this.sessionService = new SessionService();
        this.analyseService = new AnalyseService();
    
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.onFileDecode = this.onFileDecode.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.showDossierAnalyse = this.showDossierAnalyse.bind(this);
    }
    
    componentDidMount = () => {
        const data = localStorage.getItem('user')
        const user = JSON.parse(data)
        let role = user.roles[0]
        if(role === "ROLE_HEBERGEMENT_GESTIONNAIRE_DR") {
            this.sessionService.getSessionDirection(user.idDirection).then(data => {
                
                let sessionOuverte = data.filter(session => {
                    if(session.statutSession === "OUVERT") return session
                })
                let sessionDr = sessionOuverte[0];
                if(sessionDr.etatSession === "ANALYSE") this.setState({verifeAnalyse : true})
            })
            .catch(error => {
                console.log(error)
            })
        }
        else if(role === "ROLE_HEBERGEMENT_ADMIN" || role === "ROLE_HEBERGEMENT_GESTIONNAIRE_CENOU") {
            this.sessionService.getAll().then(data => {
                let sessionOuverte = data.filter(session => {
                    if(session.statutSession === "OUVERT") return session
                })
                sessionOuverte.filter(session => {
                    if(session.etatSession === "ANALYSE") this.setState({verifeAnalyse : true})
                })
                
            })
        }
    } 

    onIneChange = (e) => {
        const val = (e.target && e.target.value) || '';
        this.setState({ine : val });
    }

    getDossierEtudiant = (ine) => {
        this.dossierService.get(ine).then(data => {
            this.setState({dossier : data});
        }). catch(error => {
            console.log(error);
        })
    }
    
    hideDialog() {
        this.setState({
            submitted: false,
            dossierAnalyseDialog: false,
            dossierEtudiant : {},
            listeDossierCritere : [],
            listeDossierCritereEtudiant : [],
            analyse : false,
            fileBase64 : null,
            extension : false,
            fileClick : false,
            longueur : 580,
            largeur : 500
        });
    }
    showDossierAnalyse(dossierAnalyse) {
        this.setState({
            dossierAnalyse,
            dossierAnalyseDialog: true
        });

        this.setState({loading : true})
        this.dossierService.consulterDossier(dossierAnalyse.ine, dossierAnalyse.idDossier).then(res => {
            let idTypeCritere = null, libelleTypeCritere = "", idCritere = null, libelleCritere = "", nomFichier = "", fichier = null, valeurAnalyse = "", idDossierCritere = null
            
            res.data.listeDossierCritere.map((dossierCritere, i) => {
                if(dossierCritere.idDossierCritere) {
                    idTypeCritere = dossierCritere.idTypeCritere
                    libelleTypeCritere = dossierCritere.libelleTypeCritere
                    idCritere = dossierCritere.idCritere
                    libelleCritere = dossierCritere.libelleCritere
                    nomFichier = dossierCritere.nomFichier
                    fichier = dossierCritere.fichier
                    valeurAnalyse = dossierCritere.valeurAnalyse
                    idDossierCritere = dossierCritere.idDossierCritere
                
                    this.state.listeDossierCritere.push({
                        idTypeCritere : idTypeCritere,
                        idCritere : idCritere,
                        libelleTypeCritere : libelleTypeCritere,
                        libelleCritere : libelleCritere,
                        nomFichier : nomFichier,
                        fichier : fichier,
                        valeurAnalyse : valeurAnalyse,
                        idDossierCritere : idDossierCritere
                    })
                }
            })
            this.setState({
                dossierEtudiant : res.data,
                listeDossierCritereEtudiant : res.data.listeDossierCritere,
                loading : false
            })
        })
        .catch(error => {
            console.log(error);
            this.setState({loading2 : false})
        })
        
    }

    onFileDecode (dossierCritere) { 
        let fichier = dossierCritere.fichier
        let nomFichier = dossierCritere.nomFichier
        if(fichier){
            let extension = nomFichier.split('.').pop();
            
            if(extension === "png" || extension === "jpg" || extension === "jpeg" || extension === "gif") 
                this.setState({fileBase64 : "data:application/octet-stream;base64,"+fichier, fileClick : true, extension : true })
            else {
                this.setState({fileBase64 : "data:application/pdf;base64,"+fichier, fileClick : true, extension : false })
            }
            
        }
        
    }

    saveDossierAnalyse = () => {
        let state = { submitted: true };
        let dossierCritere = this.state.listeDossierCritere
        let verife = false
        dossierCritere.map(dossier => {
            if(!dossier.valeurAnalyse) verife = true
            dossier.fichier = null
        })
        if(verife){
            this.notify2()
        }
        else {
            const newAnalyse = {
                idDossier : this.state.dossierEtudiant.idDossier,
                statutDossier : this.state.dossierEtudiant.statutDossier,
                idSessionCenou : this.state.dossierEtudiant.idSessionCenou,
                typeDossier : this.state.dossierEtudiant.typeDossier,
                listeDossierCritere : dossierCritere,
                idEtudiant : this.state.dossierEtudiant.idEtudiant,
                ine : this.state.dossierEtudiant.ine, 
                nomEtudiant : this.state.dossierEtudiant.nomEtudiant,
                prenomEtudiant : this.state.dossierEtudiant.prenomEtudiant, 
           }
           console.log(newAnalyse)
           
           this.setState({loading : true})
           this.analyseService.analyseDossier(newAnalyse).then(res => {
                this.notify();
                this.setState({loading : false, analyse : true})
           })
           .catch(error => {
                console.log(error);
                this.notify3()
                this.setState({ loading : false})
            })
            
        }
        state = {
            ...state,
            dossierAnalyseDialog: false,
            dossierAnalyse: this.emptyDossierAnalyse,
            dossierEtudiant : {},
            listeDossierCritere : [],
            listeDossierCritereEtudiant : [],
        };
        this.setState(state);
    }
    loadingSpinner = ( type, color) => (
        <ReactLoading type={type} color={color} height={200} width={50} />
    )
    notifyC = () => toast.error("Problème de connexion, reconnectez-vous !", {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    notify = () => toast.success('Analyse de dossier effectué !', {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    notify2 = () => toast.error('Veuillez analyser tous les critères !', {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    notify3 = () => toast.error('Analyse echoué !', {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    actionBodyTemplatessssssssss(rowData) {
        return (
            <React.Fragment>
               <Link to={"/analyse-dossier/"+rowData.idDossier+"/"+rowData.ine} style={{marginRight: '.5em'}}><Button icon="pi pi-eye" className="p-button-success"  /></Link>  
            </React.Fragment>
        );
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-eye" className="p-button-success" onClick={() => this.showDossierAnalyse(rowData)} style={{marginRight: '.5em'}}/>
            </React.Fragment>
        );
    }
    render() {
        console.log(this.state.verifeAnalyse)
        this.state.dossier.map(depot => {
            depot.moyenne = Math.round(depot.moyenne * 100) / 100 
        })
        
        const dossierAnalyseDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveDossierAnalyse} />
            </React.Fragment>
        );
        const header = (
            <div className="p-grid p-col-12">
                <ToastContainer
                            position="top-right"
                            autoClose={10000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                    <ToastContainer />
            <div className="table-header p-col-5">
                <span className="p-input-icon-left">
                    <InputText className="p-col-6" type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
                
            </div>
            <div className="table-header p-col-6">
                <span className="p-input-icon-left">
                    <label className="p-col-12">Nombre total de dossiers : {this.state.dossier.length}</label>
                </span>
                
            </div>
            </div>
        );
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    {this.state.verifeAnalyse ?
                    <>
                    <div className="p-grid" >
                        <div className="p-col-3">
                            <div className="p-field">
                                <InputText style={{width : 300}} id="recherche" value={this.state.ine || ''}  onChange={(e) => this.onIneChange(e)} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Saisir l'INE de l'etudiant" />
                                {this.state.submitted && !this.state.ine && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>
                        </div>
                        <div className="p-col-2">
                            <Button icon="pi pi-search" className="p-button-success p-mr-2" onClick={(e) => {this.getDossierEtudiant(this.state.ine)}} />
                        </div>
                    </div>
                    <div className="card card-w-title datatable-demo">
                        {header}
                        {this.state.loading ?
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.loadingSpinner("spin","black")}
                            </div>
                        </div>
                                :
                                
                            
                        <DataTable  ref={(el) => this.dt = el} value={this.state.dossier} selectionMode="single" header="Dossier etudiant" paginator={true} rows={10}
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="ine" header="INE" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher ine"/>
                            <Column field="nomEtudiant" header="Nom" sortable={true} filter={true}  filterPlaceholder="rechercher nom"/>
                            <Column field="prenomEtudiant" header="Prénom" sortable={true} filter={true}  filterPlaceholder="rechercher prénom"/>
                            <Column field="dateNaissance" header="Date naiss." sortable={true} filter={true}  filterPlaceholder="rechercher date naiss."/>
                            <Column field="telEtudiant" header="Téléphone" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher telephone"/>
                            <Column field="villeOrientation" header="Ville" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher Direction"/>
                            <Column field="statutDossier" header="Statut" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher Statut"/>
                            <Column field="moyenne" header="Moyenne" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher Moyenne"/>
                            <Column header={"Consulter"} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>      
                        </DataTable>
                        
                        }
                        <Dialog visible={this.state.dossierAnalyseDialog} style={{ width: "100%" }} header={"Informations analyse "+this.state.dossierAnalyse.ine+" "+this.state.dossierAnalyse.nomEtudiant+" "+this.state.dossierAnalyse.prenomEtudiant} modal className="p-fluid" footer={dossierAnalyseDialogFooter} onHide={this.hideDialog}>
                            <div className=""> 
                                <ToastContainer
                                    position="top-right"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                />
                                <ToastContainer />
                                {this.state.loading ? 
                                        this.loadingSpinner("spin","black")
                                        :
                                        <div className="p-lg-12">
                                            <div className="p-grid">
                                                <div className="p-lg-5 card">
                                                    <div >
                                                    <table className="table table-striped" >
                                                        <thead>
                                                            <tr>
                                                                <th>Type critère</th>
                                                                <th>Critère choisi</th>
                                                                <th>Analyse</th>
                                                                <th>Consulter</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.listeDossierCritereEtudiant.map((dossierCritere,i)=>
                                                            dossierCritere.idDossierCritere ?
                                                            <tr key={i}>
                                                                
                                                                <td>{dossierCritere.libelleTypeCritere}</td>
                                                                <td>{dossierCritere.libelleCritere}</td>
                                                                <td>
                                                                    <Dropdown 
                                                                        optionLabel="valeurAnalyse" optionValue="valeurAnalyse" value={this.state.listeDossierCritere[i].valeurAnalyse} options={this.state.listeValeurAnalyse} 
                                                                        onChange={(e) => {
                                                                            this.setState({valeurAnalyse :  e.value})
                                                                            this.state.listeDossierCritere[i].valeurAnalyse  = e.value
                                                                        }
                                                                    }
                                                                    />
                                                                </td>
                                                                <td>
                                                                    {dossierCritere.fichier ? 
                                                                    <Button icon="pi pi-eye" className="p-button-success"
                                                                    onClick={ () => {
                                                                        this.onFileDecode(dossierCritere)
                                                                    }

                                                                    }
                                                                
                                                                    />
                                                                    :
                                                                    ""
                                                                }
                                                                </td>
                                                        
                                                            </tr>
                                                            :
                                                                ""
                                                            
                                                            )}
                                                        </tbody>
                                                    </table> 
                                                    </div>
                                                    {this.state.analyse ?
                                                        <div className="" style={{textAlign : "center", marginTop : 10}}>
                                                            <Link to={'/analyse'} style={{fontSize : "20px"}}>
                                                                <Button type="button"  label="Retour" icon="pi pi-arrow-left"/>
                                                            </Link>
                                                        </div>
                                                        :
                                                        ""
                                                    } 
                                                </div>
                                                <div style={{marginLeft : 20}}> </div>
                                                <div className="p-col-6 card">
                                                    {this.state.fileClick ?
                                                        this.state.extension ?
                                                            <img alt="fichier" style={{width : this.state.longueur, height : this.state.largeur}} src={this.state.fileBase64}  />
                                                            :
                                                            <div className="p-col-12">
                                                                <iframe src={this.state.fileBase64}
                                                                    style={{width:this.state.longueur, height:this.state.largeur}}>
                                                                </iframe>
                                                            </div>
                                                            :
                                                            ""
                                                    }
                                                </div>
                                                <div className="" style={{marginLeft : 30}}>
                                                    <Button  type="button"  label="" icon="pi pi-plus"
                                                        onClick={() => {
                                                            let longueur = this.state.longueur + 20
                                                            let largeur = this.state.largeur + 20
                                                            this.setState({longueur : longueur, largeur : largeur})
                                                        }}
                                                    /> <br/>
                                                    <Button style={{marginTop : 5}}  type="button"  label="" icon="pi pi-minus"
                                                        onClick={() => {
                                                            let longueur = this.state.longueur - 20
                                                            let largeur = this.state.largeur - 20
                                                            this.setState({longueur : longueur, largeur : largeur})
                                                        }}
                                                    />
                                                </div>
                                                
                                            </div>
                                        </div> 
                                        }
                
            </div> 
                        </Dialog>
                    </div>
                    </>
                    :
                    <div className="p-lg-12 p-grid">
                        <div className="p-lg-12 contenair " id="slideshow" style={{textAlign : ""}}>
                            <div id="slider" style={{backgroundColor : "#d1ecf1", padding : 5}}>
                                <p style={{fontWeight : "bold", fontStyle : "italic", fontSize : 16, color : "red", textAlign : "center", marginTop : 10}}>
                                    Session d'analyse close
                                </p>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        )
    }
}

export default Analyse
