import axios from 'axios';

export class CritereService {
    
    /*getAll() {
        
        return axios.get('http://192.168.43.106:8083/service/api/hebergement-cenou/campus-faso/critere')
                    .then(res => res.data);
    }
    create(newCritere) {
        const url = 'http://192.168.43.106:8083/service/api/hebergement-cenou/campus-faso/critere'
        return axios.post(url,newCritere)
                    .then(res => res.data);
    }
    update(idCritere ,newCritere) {
        const url = 'http://192.168.43.106:8083/service/api/hebergement-cenou/campus-faso/critere/'
        return axios.put(url+idCritere, newCritere)
                    .then(res => res.data);
    }
    delete(idCritere) {
        const url = 'http://192.168.43.106:8083/service/api/hebergement-cenou/campus-faso/critere/'
        return axios.patch(url+idCritere)
                    .then(res => res.data)
    }*/

    getAll() {
        
        return axios.get('http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/critere')
                    .then(res => res.data);
    }
    create(newCritere) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/critere'
        return axios.post(url,newCritere)
                    .then(res => res.data);
    }
    update(idCritere ,newCritere) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/critere/'
        return axios.put(url+idCritere, newCritere)
                    .then(res => res.data);
    }
    delete(idCritere) {
        const url = 'http://hebergement2.campusfaso.bf:8083/service/api/hebergement-cenou/campus-faso/critere/'
        return axios.patch(url+idCritere)
                    .then(res => res.data)
    }

}