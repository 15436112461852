import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import ReactLoading from 'react-loading';
import { DeliberationService } from '../service/DeliberationService';
import { DirectionService } from '../service/DirectionService';
import { SessionService } from '../service/SessionService';

export class ListeAmissible extends Component {
    constructor(props){
        super(props);
        this.state = {
            listeAdmissible : [],
            listeExcel : [],
            listeDirection : [],
            idDirection : null,
            libelleDirection : '',
            session : null,
            verifeRole : false,
            loading : false,
            globalFilter : null,
            submitted : false
        }
        this.directionService = new DirectionService();
        this.deliberationService = new DeliberationService();
        this.sessionService = new SessionService();
        
    }
    componentDidMount = () => {
        const data = localStorage.getItem('user')
        const user = JSON.parse(data)
        let role = user.roles[0]
       
        if(role === "ROLE_HEBERGEMENT_ADMIN" || role === "ROLE_HEBERGEMENT_GESTIONNAIRE_CENOU" ) {
            this.setState({verifeRole : true})
            this.directionService.getAll().then(data => {
                this.setState({listeDirection : data})
            })
            
        }
        else if(role === "ROLE_HEBERGEMENT_GESTIONNAIRE_DR"){
            
            this.setState({verifeRole : false, idDirection : user.idDirection})
            this.sessionService.getSessionDirection(user.idDirection).then(data => {
                
                let sessionCenou = data.filter(session => {
                    if(session.statutSession === "OUVERT") return session
                })
                let sessionDr = sessionCenou[0];
                if(sessionDr.etatSession === "DELIBERATION") {
                    
                    this.setState({loading : true})
                    this.deliberationService.resultatDeliberation(sessionDr.idSessionCenou, user.idDirection).then(res => {
                        let donneeExcel
                        let listeAdmissible = res.data
                    
                        if(listeAdmissible){
                            listeAdmissible.sort(function(a, b){
                                if(a.moyenne > b.moyenne)
                                    return -1;
                                if(a.moyenne < b.moyenne)
                                    return 1;
                                return 0;
                            });
                            donneeExcel = listeAdmissible.map(d => ({'Ine étudiant' :  d.ine, 'Nom' : d.nomEtudiant, 'Prenom' : d.prenomEtudiant, 'sexe' : d.sexeEtudiant, 'Date naiss.' : d.dateNaissance, 'Lieu naiss.' : d.lieuNaissance, 'annee BAC' : d.anneeBac, 'Moyenne' : d.moyenne, 'ville orientation' : d.villeOrientation}))
                        }
                        
                        this.setState({
                            listeAdmissible : listeAdmissible,
                            listeExcel : donneeExcel,
                            loading : false
                        })
                    })
                    .catch(error => {
                        console.log(error);
                    })
                } 
                
            })
            .catch(error => {
                console.log(error)
            })
        }
        else if(role === "ROLE_HEBERGEMENT_ETUDIANT") {
            this.setState({verifeRole : false, idDirection : user.idDirection})
            this.sessionService.getSessionDirection(user.idDirection).then(data => {
                
                let sessionCenou = data.filter(session => {
                    if(session.statutSession === "OUVERT") return session
                })
                let sessionDr = sessionCenou[0];
                if(sessionDr.etatSession === "DELIBERATION") {
                    
                    this.setState({loading : true})
                    this.deliberationService.resultatDeliberation(sessionDr.idSessionCenou, user.idDirection).then(res => {
                        let donneeExcel = null
                        let listeAdmissible = res.data
                    
                        if(listeAdmissible){
                            listeAdmissible.sort(function(a, b){
                                if(a.moyenne > b.moyenne)
                                    return -1;
                                if(a.moyenne < b.moyenne)
                                    return 1;
                                return 0;
                            });
                            donneeExcel = listeAdmissible.map(d => ({'Ine étudiant' :  d.ine, 'Nom' : d.nomEtudiant, 'Prenom' : d.prenomEtudiant, 'sexe' : d.sexeEtudiant, 'Date naiss.' : d.dateNaissance, 'Lieu naiss.' : d.lieuNaissance, 'annee BAC' : d.anneeBac, 'Moyenne' : d.moyenne, 'ville orientation' : d.villeOrientation}))
                        }
                        
                        this.setState({
                            listeAdmissible : listeAdmissible,
                            listeExcel : donneeExcel,
                            loading : false
                        })
                    })
                    .catch(error => {
                        console.log(error);
                    })
                } 
                
            })
            .catch(error => {
                console.log(error)
            })
        }
    }
    onIdDirectionChange = (e) => {
        const val = parseInt(e.target.value)
        this.setState({idDirection : val})
        
        this.sessionService.getSessionDirection(val).then(data => {
                
            let sessionCenou = data.filter(session => {
                if(session.statutSession === "OUVERT") return session
            })
            let sessionDr = sessionCenou[0];
            
            if(sessionDr.etatSession === "DELIBERATION") {
                
                this.setState({loading : true})
                    this.deliberationService.resultatDeliberation(sessionDr.idSessionCenou, val).then(res => {
                        let donneeExcel = null
                        let listeAdmissible = res.data
                        if(listeAdmissible){
                            listeAdmissible.sort(function(a, b){
                                if(a.moyenne > b.moyenne)
                                    return -1;
                                if(a.moyenne < b.moyenne)
                                    return 1;
                                return 0;
                            });
                            donneeExcel = listeAdmissible.map(d => ({'Ine étudiant' :  d.ine, 'Nom' : d.nomEtudiant, 'Prenom' : d.prenomEtudiant, 'sexe' : d.sexeEtudiant, 'Date naiss.' : d.dateNaissance, 'Lieu naiss.' : d.lieuNaissance, 'annee BAC' : d.anneeBac, 'Moyenne' : d.moyenne, 'ville orientation' : d.villeOrientation}))
                        }
                        
                        this.setState({
                            listeAdmissible : listeAdmissible,
                            listeExcel : donneeExcel,
                            loading : false
                        })
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({loading : false})
                    })
            } 
            
        })
        .catch(error => {
            console.log(error)
        })
    }
    exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.state.listeExcel);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, "liste_admissible");   
        });
    }
    saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }
    loadingSpinner = ( type, color) => (
        <ReactLoading type={type} color={color} height={200} width={50} />
    )
    render() {
        const header = (
            <div className="p-grid p-col-12" style={{marginTop : "10px"}}>
                <div className="table-header p-col-5">
                    <span className="p-input-icon-left">
                        <InputText className="p-col-6" type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                    </span>
                    
                </div>
            </div>
        );
        return (
            <div className="p-grid">
                
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-field p-grid ">
                            <div className="p-col-2"></div>
                            <div className="p-col-10 p-grid">
                                {this.state.verifeRole ?
                               
                                <div className="p-col-12 p-sm-3 p-md-3">
                                    <Dropdown optionLabel="libelleDirection" optionValue="idDirection" value={this.state.idDirection} options={this.state.listeDirection} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner une direction"
                                    onChange={this.onIdDirectionChange} 

                                    />
                                </div>
                              
                                :
                                ""
                                }
                            </div>
                        </div>

                        {header}
                        {this.state.loading ? 
                        <div className="p-grid p-col-12">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.loadingSpinner("spin","black")}
                            </div>
                        </div>
                        :
                        <>
                        <div className="p-d-flex p-ai-center export-buttons" style={{marginLeft : 10}}>
                            <Button type="button" icon="pi pi-file-excel" onClick={this.exportExcel} className="p-button-success p-mr-2" data-pr-tooltip="XLS" />
                        </div>
                        <DataTable style={{marginTop : 10}}  ref={(el) => this.dt = el} value={this.state.listeAdmissible} selectionMode="single" header={"Liste des admissibles : "+this.state.listeAdmissible.length} paginator={true} rows={10}
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="ine" header="INE" sortable={true} filter={true} filterPlaceholder="rechercher ine"/>
                            <Column field="nomEtudiant" header="Nom" sortable={true} filter={true} filterPlaceholder="rechercher nom"/>
                            <Column field="prenomEtudiant" header="Prénom" sortable={true} filter={true} filterPlaceholder="rechercher prénom"/>
                            <Column field="sexeEtudiant" header="Sexe" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher sexe"/>
                            <Column field="dateNaissance" header="Date naiss." sortable={true} filter={true} filterPlaceholder="rechercher date de naiss."/>
                            <Column field="lieuNaissance" header="Lieu de naiss." sortable={true} filter={true} filterPlaceholder="rechercher lieu de naiss."/>
                            <Column field="anneeBac" header="Année BAC" sortable={true} filter={true} filterPlaceholder="rechercher année BAC"/>
                            <Column field="moyenne" header="Moyenne" sortable={true} filter={true} filterPlaceholder="rechercher date de depôt"/>
                            <Column field="villeOrientation" header="Ville" sortable={true} filter={true} filterPlaceholder="rechercher Ville"/>
                        </DataTable>
                        </>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default ListeAmissible
